import * as React from 'react';
// import CssBaseline from '@mui/material/CssBaseline';
// import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
// import Toolbar from '@mui/material/Toolbar';
// import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
// import Button from '@mui/material/Button';
// import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
// import { createTheme } from '@mui/material/styles';
import Review from 'src/components/customer/Review';
import CustomerAccountInfo from 'src/components/customer/CustomerAccountInfo';
import { Helmet } from 'react-helmet';
import CustomerPersonalInfo from 'src/components/customer/CustomerPersonalInfo';
import AddressDetails from 'src/components/customer/AddressDetails';
import Proofs from 'src/components/customer/Proofs';
import IncomeDetails from 'src/components/customer/IncomeDetails';
import BankDetails from 'src/components/customer/BankDetails';
import GuarantorDetails from 'src/components/customer/GuarantorDetails';
import FamilyDetailsInfo from 'src/components/customer/FamilyDetailsInfo';
import { services } from 'src/components/services/services';
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import BreadcrumbsComponent from 'src/components/Breadcrumbs';
// import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
// import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
// import { MobileStepper } from '@mui/material';
import { isMobile } from 'react-device-detect';
// import { More } from '@material-ui/icons';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
const steps = ['Applicant', 'Personal', 'Address', 'Family', 'Bank', 'Reference', 'Documents', 'Review'];
console.log('isMobileisMobile', isMobile);
// const theme = createTheme();

export default function AddEditCustomer() {
  let { id } = useParams();

  const [searchParams] = useSearchParams();
  const [activeStep, setActiveStep] = React.useState(0);
  const [customerInfo, setCustomerInfo] = React.useState({});
  const navigate = useNavigate();
  const [isNextActive, setisNextActive] = React.useState(false);
  //  const [activeStepMobile, setActiveStepMobile] = React.useState(0);
  // const maxSteps = steps.length;

  // const handleNextMobile = () => {
  //   setActiveStepMobile((prevActiveStep) => prevActiveStep + 1);
  // };

  // const handleBackMobile = () => {
  //   setActiveStepMobile((prevActiveStep) => prevActiveStep - 1);
  // };
  React.useEffect(() => {
    if (!localStorage.getItem('token')) {
      navigate('/login')
    }
  }, [])
  React.useEffect(() => {
    id > 0 && services.getService(`customer/details/${id}`)
      .then((res) => {
        let data = res.data;
        // setCustomerInfo({
        //   ...customerInfo, ...data, loan_details: {}
        // })
        services.getService(`loan/list?customer_id=${id}`)
          .then(res => setCustomerInfo({
            ...customerInfo, ...data, loan_details: res.data
          }))
          .catch(err => {
            if (!localStorage.getItem('token')) {
              navigate('/login');
            }
          })
      })
      .catch(err => {
        if (!localStorage.getItem('token')) {
          navigate('/login');
        }
      })
  }, [])

  const buttonContainerStyle = {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '10px'
  }
  const buttonStyle = {
    position: 'relative',
    padding: '5px 15px 5px 15px', // Adjust padding for space on the right
    fontSize: '16px',
    border: 'none', // Optional: for better visibility
    backgroundColor: '#038dd7', // Optional: button background color
    cursor: 'pointer'
  };

  const iconStyle = {
    fontSize: '16px',
    color: 'white'
  };

  const goToStep = (activeStep) => {
    console.log(activeStep, steps.length, "activeStep")
    if (activeStep >= 0 && steps.length) {
      setActiveStep(activeStep);
    } else {
      console.log("Invalid step index")
    }
  }

  const next = () => {
    handleNext(true)
  }

  const previous = () => {
    handleBack(true)
  }

  const handleNext = (val) => {
    setisNextActive(val);
    console.log(val, "value")
    goToStep(activeStep + 1);
  };

  const handleBack = () => {
    goToStep(activeStep - 1);
  };
  function getStepContent(activeStep) {
    let args = {
      goToStep,
      handleBack,
      handleNext,
      activeStep,
      steps,
      setCustomerInfo,
      customerInfo,
      id,
      isNextActive
    }

    switch (activeStep) {
      case 0:
        return <CustomerAccountInfo {...args} />;
      // return <CustomerAccountInfo {...args} />;
      case 1:
        return <CustomerPersonalInfo {...args} />;
      case 2:
        return <AddressDetails {...args} />;
      case 3:
        return <FamilyDetailsInfo {...args} />;

      // case 4:
      //   return <IncomeDetails {...args} />;
      case 4:
        return <BankDetails {...args} />;
      case 5:
        return <GuarantorDetails {...args} />;
      case 6:
        return <Proofs {...args} />;
      case 7:
        return <Review {...args} />;
      default:
        throw new Error('Unknown step');
    }
  }
  let breadCrumbsData = []
  if (searchParams.get('type') === "loan" || searchParams.get('type') === "my_loan") {
    breadCrumbsData = [{ name: searchParams.get('type') === "my_loan" ? 'My Loans' : 'Loans', path: searchParams.get('type') === "my_loan" ? '/app/my-loans' : '/app/loans' }, { name: id ? 'Edit' : 'Add', path: id ? `/app/customers/${id}` : '/app/customers/add' }]

  } else if (searchParams.get('type') === "detail") {
    breadCrumbsData = [{ name: 'Customers', path: '/app/customers' }, { name: 'Details', path: `/app/customers/details/${id}` }, { name: id ? 'Edit' : 'Add', path: id ? `/app/customers/${id}` : '/app/customers/add' }]

  } else {
    breadCrumbsData = [{ name: 'Customers', path: '/app/customers' }, { name: id ? 'Edit' : 'Add', path: id ? `/app/customers/${id}` : '/app/customers/add' }]

  }



  return (
    <>
      <Helmet>
        <title>{id ? 'Edit' : 'Add'} Customer - Thangam Capital </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth="lg">
          {<BreadcrumbsComponent data={breadCrumbsData} />}
          <Box sx={{ pt: 3 }}>
            <div style={buttonContainerStyle}>
              <button style={buttonStyle} onClick={previous}>
                <span style={iconStyle}>{'<'}</span>
              </button>
              <button style={buttonStyle} onClick={next}>
                <span style={iconStyle}>{'>'}</span>
              </button>
            </div>
            {isMobile === true ?

              <div className='container' style={{ display: 'flex', flexDirection: 'row' }}>
                <div ><span className='step-class'>1</span><span className='step-title'>Applicant</span></div>
                <div style={{ marginLeft: '6px' }}>-</div>
                <div style={{ marginLeft: '6px' }}><span className='step-class'>2</span><span className='step-title'>Personal</span></div>
                <div style={{ marginLeft: '6px' }}>-</div>
                <div style={{ marginLeft: '6px' }}><span className='step-class'>3</span><span className='step-title'>Address</span></div>
                <div style={{ marginLeft: '6px' }}>-</div>
                <div style={{ marginTop: '-16px', marginLeft: '6px' }}>

                  <MoreHorizIcon fontSize='large' style={{ color: "#4984BF", fontSize: '44px', marginTop: '4px' }} color='#4984BF' /></div>
              </div>
              : <Stepper activeStep={activeStep} sx={{
                "& .MuiStepLabel-root .Mui-completed": {
                  color: "green"
                },
                "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel": {
                  color: "orange"
                },
                "& .MuiStepLabel-root .Mui-active": {
                  color: "red"
                },
                "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
                  fill: "white"
                },
                pt: 3,
                pb: 5
              }}>
                {steps.map((label, index) => (
                  <Step key={label}>
                    <StepLabel onClick={() => goToStep(index)}>{label}</StepLabel>
                  </Step>

                ))}
                {isMobile && <div><MoreHorizIcon fontSize='large' style={{ color: "#4984BF", fontSize: '44px', marginTop: '4px' }} color='#4984BF' /></div>}
              </Stepper>}
            {/* <MobileStepper
        variant="text"
        steps={maxSteps}
        position="static"
        activeStep={activeStepMobile}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStepMobile ==== maxSteps - 1}
          >
            Next
            {theme.direction ==== 'rtl' ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStepMobile ==== 0}>
            {theme.direction ==== 'rtl' ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      /> */}
            <React.Fragment>
              {activeStep === steps.length ? (
                <React.Fragment>
                  <Typography variant="h5" gutterBottom>
                    Thank you for your order.
                  </Typography>
                  <Typography variant="subtitle1">
                    Your order number is #2001539. We have emailed your order
                    confirmation, and will send you an update when your order has
                    shipped.
                  </Typography>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {getStepContent(activeStep)}
                </React.Fragment>
              )}
            </React.Fragment>
          </Box>
        </Container>
      </Box>
    </>
  );
}