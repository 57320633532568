import { Breadcrumbs } from "@mui/material";
import { Link } from "react-router-dom";
import _ from 'lodash';
const BreadcrumbsComponent = ({ data = [] }) => {
  console.log('datadatadata', data);
  return <Breadcrumbs separator="›" aria-label="breadcrumb">
    {_.map([{ name: 'Home', path: '/' }, ...data], (value, index) => {
      return <Link key={index} to={_.get(value, 'path')}>
        {_.get(value, 'name')}
      </Link>
    })}

  </Breadcrumbs>
}

export default BreadcrumbsComponent