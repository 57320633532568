import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import MuiAlert from '@mui/material/Alert';
import { Button } from 'antd';
import './style.css'
import { useNavigate } from 'react-router';
// import _ from "lodash"
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert  style={{justifyContent:'center',alignItems:'center',marginTop:43}} className="first_class" elevation={6} ref={ref} variant="filled" {...props} />;
});

/*
severity holds only "success", "warning", "error", "info"
*/

export default function SnackBar({ open, message, handleClose, severity,id }) {

  const vertical = 'top';
  const horizontal = 'center';
  const navigate = useNavigate();
  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleClose}
        key={vertical + horizontal}
        autoHideDuration={6000}
        style={{alignItems:'center',justifyContent:'center',textAlign:'center'}}
        action={
          <React.Fragment>
           
            <IconButton
              aria-label="close"
              color="inherit"
              sx={{ p: 0.5 }}
              onClick={handleClose}
            >
               
              <CloseIcon />
            </IconButton>
          </React.Fragment>
        }
      >
        <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }} >
          {message}
          {message==="Validation Error :: Aadhar number & Email already exists." && <Button onClick={()=>navigate(`/app/customers/details/${id}`)} style={{borderRadius:34,marginLeft:20,background:'#1890ff',borderColor:'#1890ff',color:'#fff'}}>View Customer</Button>}
        </Alert>
        {/* {message==="Validation Error :: Aadhar number & Email already exists." && <Button name='knadjfnjadnfj'/>} */}
      </Snackbar>
    </div>

  );
}
