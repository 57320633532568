import { useState } from 'react';
import {
  NavLink as RouterLink,
  matchPath,
  useLocation
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, ListItem, Collapse, List, Box } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

const NavItem = ({ href, icon: Icon, title, children, ...rest }) => {
  const location = useLocation();
  const [open, setOpen] = useState(false);

  const active = href ? !!matchPath({ path: href, end: false }, location.pathname) : false;

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItem
        disableGutters
        sx={{
          display: 'flex',
          py: 0
        }}
        {...rest}
      >
        <Button
          component={children ? 'button' : RouterLink}
          sx={{
            color: 'text.secondary',
            fontWeight: 'medium',
            justifyContent: 'flex-start',
            letterSpacing: 0,
            py: 1.25,
            textTransform: 'none',
            width: '100%',
            ...(active && {
              color: 'primary.main'
            }),
            '& svg': {
              mr: 1
            }
          }}
          to={href}
          onClick={children ? handleClick : null}
        >
          {Icon && (
            <Icon size="20" />
          )}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%'
            }}
          >
            <span>
              {title}
            </span>
            {children && (open ? <ExpandLess /> : <ExpandMore />)}
          </Box>
        </Button>
      </ListItem>
      {children && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((child) => (
              <NavItem
                key={child.title}
                href={child.href}
                icon={child.icon}
                title={child.title}
                sx={{ pl: 4 }}
              />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
};

NavItem.propTypes = {
  href: PropTypes.string,
  icon: PropTypes.elementType,
  title: PropTypes.string,
  children: PropTypes.array
};

export default NavItem;
