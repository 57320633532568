import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import 'antd/dist/reset.css'
import { store,persistor } from "./redux/store";
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react';

const root = createRoot(document.getElementById('root'));
root.render( <Provider store={store}>
  <PersistGate loading={null} persistor={persistor}>
    <Router>
      
      <App />
    </Router></PersistGate></Provider>
);
