import React from "react";
import { FormControl, InputLabel, MenuItem, OutlinedInput, Select } from "@mui/material";
import { Controller } from "react-hook-form";
import FormHelperText from '@mui/material/FormHelperText';

export const FormInputDropdown = ({
  name,
  control,
  label,
  options,
  rules,
  readonly
}) => {
  let fieldRules = rules || {};
  if (rules === undefined) fieldRules.required = false;

  const generateSingleOptions = () => {
    return options.map((option) => {
      return (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      );
    });
  };

  return (
    <Controller
      render={(
        {
          field: { onChange, value },
          fieldState: { error },
        }) => (
        <FormControl  size={"small"} fullWidth error={error} disabled={readonly} >
          <InputLabel 
          style={{fontSize:'1.3rem',lineHeight:'24px'}}
          //  id="custom-select-label"
            shrink={true}>{label}</InputLabel>
          <Select
            //  labelId="custom-select-label"
            label={label}
            onChange={onChange}
            value={value}
            disabled={readonly}
            input={<OutlinedInput notched style={{lineHeight:'24px'}}  label={label} />}
            sx={{
              '& .MuiOutlinedInput-notchedOutline': {
                fontSize:'1.3rem',
                // borderColor: '#3f51b5', // Customize border color
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                // borderColor: '#757de8', // Change color on hover
              },
            }}
          >
            {generateSingleOptions()}
          </Select>
          <FormHelperText sx={{ ml: 2 }} error={error}>{error ? error.message : null}</FormHelperText>
        </FormControl>
      )}
      rules={{
        ...fieldRules
      }}
      control={control}
      name={name}
    />
  );
};