import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container } from '@mui/material';
import CustomerListResults from '../components/customer/CustomerListResults';
import CustomerListToolbar from '../components/customer/CustomerListToolbar';
import { services } from 'src/components/services/services';
import { useLocation, useNavigate } from 'react-router-dom';
import SnackBar from 'src/components/shared/SnackBar';
import _ from "lodash";
import { notification } from 'antd';
import { useDispatch } from 'react-redux';
import { setApiData } from 'src/redux/globalConfigurationSlice';
// import BreadcrumbsComponent from 'src/components/Breadcrumbs';
const CustomerList = () => {
  const location = useLocation();
  const [customers, setCustomers] = useState([]);
  const [customersPage, setCustomersPages] = useState({});
  const [searchedInputs, setSearchedInputs] = useState('');
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();

 
  const handleChange = (e) => {
    setSearchedInputs({
      ...searchedInputs, [e.target.name]: e.target.value
    });
  }
  function removeEmptyValues(obj) {
    Object.keys(obj).forEach(key => {
      if (obj[key] === null || obj[key] === undefined || obj[key] === '') {
        delete obj[key];
      }
    });
    return obj;
  }
  const handleSearch = () => {
    setLoading(true)
    let body = removeEmptyValues(searchedInputs)

    if (!_.isEmpty(body)) {
      services.postService(`customer/search`, body)
        .then(res => {
          setLoading(false)
          setCustomers(res)

        })
        .catch(err => {

          if (_.get(err, 'data.message[0]')) {
            notification.error({
              message: 'Error',
              description: _.startCase(_.get(err, 'data.message[0]'))
            })
          }
          if (!localStorage.getItem('token')) {
            navigate('/login');
          }
        })
    } else {
      customerList()
    }

  }
  useEffect(() => {
    if (!localStorage.getItem('token')) {
      navigate('/login')
    }
  }, [])
  useEffect(() => {
    getConfiguration()
    customerList()
    
  }, [])
  const getConfiguration = () => {
    services.getService(`configuration/global`)
      .then(res => {
        dispatch(setApiData(res?.data));
       
      })
      .catch(err => {
        if (!localStorage.getItem('token')) {
          navigate('/login');
        }
      })
  }

  const customerList = (start = 0, count = 10) => {
    services.postService(`customer/list`, { currentPage: start + 1 })
      .then(res => {
        setLoading(false)
        setCustomers(_.get(res, 'data.content'))
        setCustomersPages({ count: _.get(res, 'data.count'), start: _.get(res, 'data.start'), total: _.get(res, 'data.count') })
      })
      .catch(err => {
        if (!localStorage.getItem('token')) {
          navigate('/login');
        }
      })
  }

  const changePage = (value, count) => {
    customerList(value, count)
  }

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Helmet>
        <title>Customers - Thangam Capital </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        {
          location && location.state && location.state.status && <SnackBar open={open} message={location.state.message} handleClose={handleClose} severity="success" />
        }
        <Container maxWidth={false}>
          <CustomerListToolbar handleChange={handleChange} handleSearch={handleSearch} resetFields={() => customerList()} />

          <Box sx={{ pt: 3 }}>
            <CustomerListResults loading={loading} onChangePage={(value, count) => changePage(value, count)} customers={customers} pagination_details={customersPage} />
          </Box>
        </Container>
      </Box>
    </>
  )
};

export default CustomerList;
