import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import '../../pages/style.css'
import _ from 'lodash'
export default function DialogBox({open, setOpen, onConfirm, text,user_name=''}) {

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div style={{width:'28%',height:'28%'}} className='confirm-class'>
      <Dialog  open={open || false} onClose={handleClose}>
        <DialogTitle style={{fontSize:19}}>Confirm</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {text}
         <div>Are you sure you want to delete the user <span style={{fontWeight:'500',color:'#000'}}>{_.startCase(user_name)}</span> ?</div>
          </DialogContentText>
         
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={onConfirm}>Yes</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
