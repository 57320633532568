import React, { useState } from 'react';
import {
  Box,
  Button,
  Container,
  TextField,
  Checkbox,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Slide,
  Typography,
  Grid,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import { useFormik } from 'formik';
import * as yup from 'yup';

const validationSchema = yup.object({
  name: yup.string().required('List Name is required'),
  code: yup.string().required('List Code is required'),
  description: yup.string().required('List Description is required'),
});

const DatasetList = ({ datasetLists, setDatasetLists }) => {
  const [addDatasetListModal, setAddDatasetListModal] = useState(false);
  const [editDatasetList, setEditDatasetList] = useState(false);
  const [editDatasetListModal, setEditDatasetListModal] = useState(false);
  const [currentDatasetList, setCurrentDatasetList] = useState(null);
  const [searchKey, setSearchKey] = useState('');

  const formik = useFormik({
    initialValues: {
      name: '',
      code: '',
      description: '',
      isactive: false,
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      if (editDatasetList) {
        setDatasetLists(datasetLists.map(dl => dl.code === currentDatasetList.code ? values : dl));
      } else {
        setDatasetLists([...datasetLists, values]);
      }
      resetForm();
      setAddDatasetListModal(false);
      setEditDatasetList(false);
      setEditDatasetListModal(false);
    },
  });

  const handleListCodeChange = (event) => {
    const newValue = event.target.value.toUpperCase().replace(/[^A-Z0-9_]/g, '');
    console.log(newValue,"newvalue");
    formik.setFieldValue('code', newValue, false);
  };

  const handleAddNew = () => {
    formik.resetForm();
    setAddDatasetListModal(true);
  };

  const handleEdit = (datasetList) => {
    setEditDatasetList(true);
    setCurrentDatasetList(datasetList);
    formik.setValues(datasetList);
    setEditDatasetListModal(true);
  };

  const handleDelete = (code) => {
    setDatasetLists(datasetLists.filter(dl => dl.code !== code));
  };

  const filteredDatasetLists = datasetLists.filter(dl => dl.name.includes(searchKey) || dl.code.includes(searchKey));

  return (
    <Container maxWidth="lg">
      <Box>
        <Box mb={2} display="flex" alignItems="center" justifyContent="space-between" component={Paper} style={{ padding: "1rem" }}>
          <Typography variant="h4" component="h1" gutterBottom>
            Dataset List
          </Typography>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleAddNew}
          >
            Add New
          </Button>
        </Box>
        <Slide direction="up" in={addDatasetListModal} mountOnEnter unmountOnExit>
          <Box mt={4} p={2} component={Paper}>
            <form onSubmit={formik.handleSubmit}>
              <TextField
                fullWidth
                margin="normal"
                id="name"
                name="name"
                label="Name"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.listname && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
              <TextField
                fullWidth
                margin="normal"
                id="code"
                name="code"
                label="Code"
                value={formik.values.code}
                onChange={handleListCodeChange}
                error={formik.touched.code && Boolean(formik.errors.code)}
                helperText={formik.touched.code && formik.errors.code}
              />
              <TextField
                fullWidth
                margin="normal"
                id="description"
                name="description"
                label="Description"
                multiline
                rows={4}
                value={formik.values.description}
                onChange={formik.handleChange}
                error={formik.touched.description && Boolean(formik.errors.description)}
                helperText={formik.touched.description && formik.errors.description}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    id="isactive"
                    name="isactive"
                    checked={formik.values.isactive}
                    onChange={formik.handleChange}
                  />
                }
                label="IsActive"
              />
              <Box mt={2} display="flex" justifyContent="space-between">
                <Button color="primary" variant="contained" type="submit" disabled={!formik.isValid}>
                  Save
                </Button>
                <Button color="secondary" variant="contained" onClick={() => setAddDatasetListModal(false)}>
                  Close
                </Button>
              </Box>
            </form>
          </Box>
        </Slide>
        <Slide direction="up" in={editDatasetListModal} mountOnEnter unmountOnExit>
          <Box mt={4} p={2} component={Paper}>
            <form onSubmit={formik.handleSubmit}>
              <TextField
                fullWidth
                margin="normal"
                id="listname"
                name="listname"
                label="List Name"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
              <TextField
                fullWidth
                margin="normal"
                id="code"
                name="code"
                label="Code"
                value={formik.values.code}
                onChange={handleListCodeChange}
                error={formik.touched.code && Boolean(formik.errors.code)}
                helperText={formik.touched.code && formik.errors.code}
              />
              <TextField
                fullWidth
                margin="normal"
                id="description"
                name="description"
                label="Description"
                multiline
                rows={4}
                value={formik.values.description}
                onChange={formik.handleChange}
                error={formik.touched.description && Boolean(formik.errors.description)}
                helperText={formik.touched.description && formik.errors.description}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    id="listisactive"
                    name="listisactive"
                    checked={formik.values.isactive}
                    onChange={formik.handleChange}
                  />
                }
                label="IsActive"
              />
              <Box mt={2} display="flex" justifyContent="space-between">
                <Button color="primary" variant="contained" type="submit" disabled={!formik.isValid}>
                  Save
                </Button>
                <Button color="secondary" variant="contained" onClick={() => setEditDatasetListModal(false)}>
                  Close
                </Button>
              </Box>
            </form>
          </Box>
        </Slide>
        {/* <Box my={4}>
          <TextField
            fullWidth
            margin="normal"
            id="searchKey"
            name="searchKey"
            label="Search"
            value={searchKey}
            onChange={(e) => setSearchKey(e.target.value)}
            InputProps={{
              endAdornment: <SearchIcon />
            }}
          />
        </Box> */}
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>List Name</TableCell>
                <TableCell>List Code</TableCell>
                <TableCell>Is Active</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredDatasetLists.map((datasetList) => (
                <TableRow key={datasetList.code}>
                  <TableCell>{datasetList.name}</TableCell>
                  <TableCell>{datasetList.code}</TableCell>
                  <TableCell>
                    <Checkbox checked={datasetList.isactive} disabled />
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleEdit(datasetList)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => handleDelete(datasetList.code)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Container>
  );
};

export default DatasetList;
