import React, { useEffect, useState } from 'react';

import {
    Box,
    Card,
    CardHeader,
    Divider,
    CardContent,
    Grid,
    Button,
    Stack
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { CustomerRules } from 'src/utils/validationsConfig';
import { FormInputText } from '../components/shared/FormInputText';
import { Fieldset } from 'src/components/shared/Fieldset';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import _ from 'lodash'
import { Typography } from 'antd';
import { useSelector } from 'react-redux';
function FormRow(props) {
    const { control, name, label, rules, inputType, readonly,setValue } = props;
    return (
        <React.Fragment>
            <Grid item
                md={6}
                xs={10}
                lg={4}
            >
                <FormInputText
                    name={name}
                    control={control}
                    label={label}
                    rules={rules}
                    inputType={inputType || "text"}
                    readonly={readonly || false}
                    helperText={props.helperText || null}
                    // setValue={setValue}
                />
            </Grid>
        </React.Fragment>
    );
}

const IncomeDetailsLoan = ({
    handleBack,
    handleNext,
    activeStep,
    steps,
    setloanInfo,
    loanInfo,
    isNextActive
}) => {
    const globalConfiguration = useSelector(
        (state) => state?.globalConfiguration?.data
      );
    const defaultValuesApplicant = {
        "totalIncome":"",
        "regularIncome":"",
        "otherIncome":"",
        "houseRent":"",
        "electricity":"",
        "education":"",
        "medical":"",
        "debtRepayment":"",
        "personal":"",
        "businessDevelopment":"",
        "transportation":"",
        "miscellaneous":"",
        }
            const defaultValuesCoApplicant = {
                "coApplicantTotalIncome":"",
                "coApplicantRegularIncome":"",
                "coApplicantOtherIncome":"",
                "coApplicantHouseRent":"",
                "coApplicantElectricity":"",
                "coApplicantEducation":"",
                "coApplicantMedical":"",
                "coApplicantDebtRepayment":"",
                "coApplicantPersonal":"",
                "coApplicantBusinessDevelopment":"",
                "coApplicantTransportation":"",
                "coApplicantMiscellaneous":""}
    
    const [value, setTabValue] = React.useState(1);
    const { handleSubmit, control,watch,setValue,getValues } = useForm({
        defaultValues:{...defaultValuesApplicant,...defaultValuesCoApplicant, ...loanInfo},
    values:{...defaultValuesApplicant,...defaultValuesCoApplicant,...loanInfo}
    });
     const regularIncome =watch('regularIncome');
  const otherIncome = watch('otherIncome');
    const coApplicantRegularIncome = watch("coApplicantRegularIncome",0);
    const coApplicantOtherIncome = watch("coApplicantOtherIncome",0);
useEffect(() => {
    calculateTotalIncome(regularIncome, otherIncome,'totalIncome');
}, [regularIncome, otherIncome]);
// Function to check both objects
function validateData(onSubmitData) {
    // Check if all keys from applicant are present and non-empty
    const isApplicantValid = _.every(defaultValuesApplicant, (value, key) => {
      return onSubmitData[key] !== undefined && onSubmitData[key] !== "";
    });
  
    // Check if all keys from co-applicant are present and non-empty
    const isCoApplicantValid = _.every(defaultValuesCoApplicant, (value, key) => {
      return onSubmitData[key] !== undefined && onSubmitData[key] !== "";
    });
  
    // Return true if both applicant and co-applicant are valid, otherwise false
    return {defaultValuesApplicant:isApplicantValid ,
        defaultValuesCoApplicant:isCoApplicantValid}
  }

  const filterLoanProduct = (loanType) => {
    let loanTypes = _.map(
        _.get(globalConfiguration, "loanProducts"),
        (value, key) => ({ value: key, label: value })
      );
    let product = _.filter(
        loanTypes,
      (value, index) => value?.value == loanType
    );
    return _.size(product) > 0
      ? _.get(_.head(product), "label")
      : "Loan Product";
  };
    const onSubmit = (data) => {
        console.log("dssssataonsubmisdsdst", JSON.stringify(data));
        setloanInfo({
            ...loanInfo, ...data
        })
        const result = validateData(data);
        if(result.defaultValuesApplicant==true && result.defaultValuesCoApplicant==false){
            setTabValue(2);
        }else if(result.defaultValuesApplicant==true && result.defaultValuesCoApplicant==true){
            handleNext();
        }else if(result.defaultValuesApplicant==false && result.defaultValuesCoApplicant==true){
            setTabValue(1);
        }
        
    }
    const handleChange = (event, newValue) => {
        console.log('sdnknskdnks',newValue,getValues());
        
        setTabValue(newValue);
      };
      
      const calculateTotalIncome = (regularIncome, otherIncome,name) => {
        const regular = parseFloat(regularIncome) || 0;
        const other = parseFloat(otherIncome) || 0;
        const total = regular + other;

        // Set the calculated total income to the totalIncome field
        setValue(name, total.toFixed(2));
    };
     useEffect(() => {
        calculateTotalIncome(coApplicantRegularIncome,coApplicantOtherIncome,'coApplicantTotalIncome')
     }, [coApplicantRegularIncome,coApplicantOtherIncome])
     
 
   const getNetIncome = (type) =>{
    console.log('ksdfgsdkfksdkf',getValues());
    
    return 4343434
   }

    return (
        <form onSubmit={handleSubmit(onSubmit)} >
            <Card>
            <Box display={'flex'} alignItems={'center'}>
            <Box alignItems={'center'} justifyContent={'flex-start'} display={'flex'} marginTop={"15px"} marginLeft={'16px'}>
    <Typography
    
          
         
          style={{ marginBottom: "0px",fontSize:'17px',fontWeight:'500' }}
        >
        Loan Product:  
        </Typography>
        <Typography
    
    
    
    style={{ marginBottom: "0px",fontSize:'20px',fontWeight:'600' }}
    >
   {filterLoanProduct(loanInfo?.loanType)}
    </Typography>
    </Box>
    <Box alignItems={'center'} justifyContent={'flex-start'} display={'flex'} marginTop={"15px"} marginLeft={'16px'}>
    <Typography
    
          
         
          style={{ marginBottom: "0px",fontSize:'17px',fontWeight:'500' }}
        >
        Product Code:  
        </Typography>
        <Typography
    
    
    
    style={{ marginBottom: "0px",fontSize:'20px',fontWeight:'600' }}
    >
   {loanInfo?.loanType}
    </Typography>
    </Box>
            </Box>
            <Tabs
      value={value}
      onChange={(e,v)=>handleChange(e,v)}
      centered
      aria-label="custom styled tabs"
      TabIndicatorProps={{
        style: {
          display: 'none', // hide the default tab indicator
        },
      }}
      sx={{
        minHeight: 'unset', // reduce overall height
        marginTop:'20px'
      }}
    >
      <Tab
      value={1}
      key={1}
        label="Applicant"
        sx={{
          
          
          backgroundColor: value === 1 ? 'lightgray' : 'white',
          color: value === 1 ? 'blue' : 'black',
          
          padding: '10px 16px',
          minHeight: 'unset', // reduce individual tab height

          border: "1px solid var(--Gray-300,#d0d5dd)",
          borderRadius: "8px",
            borderTopRightRadius: "8px",
            borderBottomRightRadius: "8px",
          borderBottomRightRadius: "0px",
          borderTopRightRadius: 0
        }}
      />
      <Tab
       value={2}
       key={2}
        label="Co-Applicant"
        sx={{
            border: "1px solid var(--Gray-300,#d0d5dd)",
            borderRadius: "8px",
    borderTopLeftRadius: "8px",
    borderBottomLeftRadius: "8px",
  borderBottomLeftRadius: 0,
  borderTopLeftRadius: 0,
  backgroundColor: value === 2 ? 'lightgray' : 'white',
  color: value === 2 ? 'blue' : 'black',
          padding: '10px 16px',
          minHeight: 'unset', // reduce individual tab height
          marginLeft: '-1px', // remove gap between tabs
        }}
      />
    </Tabs>
   {value==1? 
   <Box key={'tb-2'} padding={2}>
            
            <Fieldset
            margin="0px"
             marginTop="0px"
        title={
          <Stack direction="row" alignItems="center" gap={1}>
            Applicant Income
          </Stack>
        }
        color="#4984BF"
        titleSize="1.2rem"
        borderWidth={2}
        borderRadius={1}
        >
        
            <Grid container spacing={2} mt={1}>
                <Grid
                    container
                    item
                    spacing={2}
                    
                >
                   
                    <FormRow control={control} 
                    inputType="number" 
                    name="regularIncome" label="Regular Income" 
                    rules={{ required: CustomerRules.commonValidation("Regular Income") }} 
                     />
                    <FormRow control={control} inputType="number" name="otherIncome" label="Other Income"
                     rules={{ required: CustomerRules.commonValidation("Other Income") }}  />
                    <FormRow control={control}  
                    name="totalIncome" label="Total Income"  readonly={true} />
                </Grid>
               
               
                
            </Grid>
        
        </Fieldset>
        <Fieldset
         margin="0px"
        marginTop="25px"
        title={
          <Stack direction="row" alignItems="center" gap={1}>
            Applicant Expenses
          </Stack>
        }
        color="#4984BF"
        titleSize="1.2rem"
        borderWidth={2}
        borderRadius={1}
        >
        
            <Grid container spacing={2} mt={1}>
                <Grid
                    container
                    item
                    spacing={2}
                   
                >
                    <FormRow control={control} inputType="number" name="houseRent" label="House Rent" 
                    rules={{ required: CustomerRules.commonValidation("House Rent") }}  />
                    <FormRow control={control} inputType="number" name="electricity" 
                    label="Electricity" rules={{ required: CustomerRules.commonValidation("Electricity") }}  />
                    <FormRow  control={control} inputType="number" name="education" label="Education" 
                    rules={{ required: CustomerRules.commonValidation("Education") }}  />
                </Grid>
                <Grid
                    container
                    item
                    spacing={2}
                    mb={1} mt={0}
                >
                    <FormRow control={control} inputType="number" 
                    name="medical" label="Medical" rules={{ required: CustomerRules.commonValidation("Medical") }}  />
                    <FormRow control={control} inputType="number" name="debtRepayment" 
                    label="Debt Repayment" rules={{ required: CustomerRules.commonValidation("Debt Repayment") }}  />
                    <FormRow control={control} inputType="number" name="personal" 
                    label="Personal" rules={{ required: CustomerRules.commonValidation("Personal") }}  />
                </Grid>
                <Grid
                    container
                    item
                    spacing={2}
                >
                    <FormRow control={control} inputType="number" name="businessDevelopment" label="Business Development"
                     rules={{ required: CustomerRules.commonValidation("Business Development") }}  />
                    <FormRow control={control} inputType="number" name="transportation" label="Transportation" 
                    rules={{ required: CustomerRules.commonValidation("Transportation") }}  />
                    <FormRow control={control} inputType="number" name="miscellaneous" label="Miscellaneous" 
                    rules={{ required: CustomerRules.commonValidation("Miscellaneous") }}  />
                </Grid>
                
            </Grid>
        
        </Fieldset>
        
        <Box alignItems={'center'} justifyContent={'flex-end'} display={'flex'} marginTop={"15px"}>
        <Typography
        
                  
                 
                  style={{ marginBottom: "0px",fontSize:'16px',fontWeight:'600' }}
                >
                  Net Income:  
                </Typography>
                <Typography
        
        
        
        style={{ marginBottom: "0px",fontSize:'20px',fontWeight:'bold' }}
        >
        20000000
        </Typography>
        </Box>
         </Box>:
    <Box key={'tb-3'} padding={2}>
    <Fieldset
    margin="0px"
     marginTop="0px"
    title={
    <Stack direction="row" alignItems="center" gap={1}>
    Co-Applicant Income
    </Stack>
    }
    color="#4984BF"
    titleSize="1.2rem"
    borderWidth={2}
    borderRadius={1}
    >
    
    <Grid container spacing={2} mt={1}>
        <Grid
            container
            item
            spacing={2}
            
        >
            <FormRow control={control} inputType="number" name="coApplicantRegularIncome" 
            label="Regular Income" 
            rules={{ required: CustomerRules.commonValidation("Regular Income") }}  />
            <FormRow control={control} inputType="number" name="coApplicantOtherIncome" label="Other Income" rules={{ required: CustomerRules.commonValidation("Other Income") }}  />
            <FormRow control={control}  name="coApplicantTotalIncome" label="Total Income"  readonly={true} />
        </Grid>
       
       
        
    </Grid>
    
    </Fieldset>
    <Fieldset
    margin="0px"
    marginTop="25px"
    title={
    <Stack direction="row" alignItems="center" gap={1}>
    Co-Applicant Expenses
    </Stack>
    }
    color="#4984BF"
    titleSize="1.2rem"
    borderWidth={2}
    borderRadius={1}
    >
    
    <Grid container spacing={2} mt={1}>
        <Grid
            container
            item
            spacing={2}
           
        >
            <FormRow control={control} inputType="number" name="coApplicantHouseRent" label="House Rent" 
            rules={{ required: CustomerRules.commonValidation("House Rent") }}  />
            <FormRow control={control} inputType="number" name="coApplicantElectricity" 
            label="Electricity" rules={{ required: CustomerRules.commonValidation("Electricity") }}  />
            <FormRow control={control} inputType="number" name="coApplicantEducation" label="Education" 
            rules={{ required: CustomerRules.commonValidation("Education") }}  />
        </Grid>
        <Grid
            container
            item
            spacing={2}
            mb={1} mt={0}
        >
            <FormRow control={control} inputType="number" 
            name="coApplicantMedical" label="Medical" rules={{ required: CustomerRules.commonValidation("Medical") }}  />
            <FormRow control={control} inputType="number" name="coApplicantDebtRepayment" 
            label="Debt Repayment" rules={{ required: CustomerRules.commonValidation("Debt Repayment") }}  />
            <FormRow control={control} inputType="number" name="coApplicantPersonal" 
            label="Personal" rules={{ required: CustomerRules.commonValidation("Personal") }}  />
        </Grid>
        <Grid
            container
            item
            spacing={2}
        >
            <FormRow control={control} inputType="number" name="coApplicantBusinessDevelopment" label="Business Development" 
            rules={{ required: CustomerRules.commonValidation("Business Development") }}  />
            <FormRow control={control} inputType="number" name="coApplicantTransportation" label="Transportation" rules={{ 
                required: CustomerRules.commonValidation("Transportation") }}  />
            <FormRow control={control} inputType="number" name="coApplicantMiscellaneous" label="Miscellaneous"
             rules={{ required: CustomerRules.commonValidation("Miscellaneous") }}  />
        </Grid>
        
    </Grid>
    
    </Fieldset>
    
    <Box alignItems={'center'} justifyContent={'flex-end'} display={'flex'} marginTop={"15px"}>
    <Typography
    
          
         
          style={{ marginBottom: "0px",fontSize:'16px',fontWeight:'600' }}
        >
          Net Income:  
        </Typography>
        <Typography
    
    
    
    style={{ marginBottom: "0px",fontSize:'20px',fontWeight:'bold' }}
    >
    {value==1?getNetIncome(value):getNetIncome(value)}
    </Typography>
    </Box>
    </Box>}
      
                <Divider />
                <Box sx={{ display: 'flex', justifyContent: 'space-between', px: 2, pb: 2 }}>
                    <Button
                        variant="contained"
                        sx={{ mt: 2, ml: 1 }}
                        href="/app/customers"
                        style={{ height: '36px' }}
                    >
                        Cancel
                    </Button>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', px: 2, pb: 2 }}>  {activeStep !== 0 && (
                        <Button onClick={()=>handleBack()} sx={{ mt: 2, ml: 1 }}>
                            Previous
                        </Button>
                    )}
                        <Button
                            variant="contained"
                            sx={{ mt: 2, ml: 1 }}
                            type="submit"
                        >
                            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                        </Button>
                    </Box>
                </Box>
            </Card>
        </form>
    );
};


export default IncomeDetailsLoan;
