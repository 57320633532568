import React, { useEffect, useState } from "react";
import { FormInputDropdown } from "../shared/FormInputDropdown";
import { FormInputText } from "../shared/FormInputText";
import {
  Button,
  Box,
  Card,
  CardHeader,
  Divider,
  CardContent,
  Grid,
  Stack,
} from "@mui/material";

import { useForm } from "react-hook-form";
import { Typography } from "antd";
import { useSelector } from "react-redux";
import _ from "lodash";
import { LoanRules } from "src/utils/validationsConfig";
import { FormAutoCompleteDropdown } from "../shared/FormAutoCompleteDropdown";
import { Fieldset } from "../shared/Fieldset";
function FormRow(props) {
  const {
    control,
    name,
    label,
    rules,
    inputType,
    readonly,
    multiline = false,
  } = props;
  return (
    <React.Fragment>
      <Grid item md={6} xs={10} lg={4}>
        <FormInputText
          name={name}
          control={control}
          label={label}
          rules={rules}
          inputType={inputType || "text"}
          readonly={readonly || false}
          helperText={props.helperText || null}
          multiline={multiline}
        />
      </Grid>
    </React.Fragment>
  );
}
function FormRowRadio(props) {
  const { control, name, label, options, rules, readonly } = props;
  return (
    <Grid item md={6} xs={10} lg={4}>
      <FormInputDropdown
        name={name}
        control={control}
        label={label}
        options={options}
        readonly={readonly || false}
        rules={rules}
      />
    </Grid>
  );
}

function FormRowAutoComplete(props) {
  const { control, name, label, options, rules, readonly } = props;
  return (
    <Grid item md={6} xs={10} lg={4}>
      <FormAutoCompleteDropdown
        name={name}
        control={control}
        label={label}
        options={options}
        readonly={readonly || false}
        rules={rules}
      />
    </Grid>
  );
}

const genderList = [
  {
    label: "Male",
    value: "male",
  },
  {
    label: "Female",
    value: "female",
  },
  {
    label: "Transgender",
    value: "transgender",
  },
];

const businessPremises = [
  {
    label: "Rented",
    value: "rented",
  },
  {
    label: "Owned",
    value: "owned",
  },
  {
    label: "Leased",
    value: "leased",
  },
];

const companyCategory = [
  {
    label: "State Government",
    value: "State Government",
  },
  {
    label: "Central Government",
    value: "Central Government",
  },
  {
    label: "Private Limited",
    value: "Private Limited",
  },
];




const LoanType = (props) => {
  
  
  const globalConfiguration = useSelector(
    (state) => state?.globalConfiguration?.data
  );
  const { handleSubmit, control, watch, reset } = useForm({
    defaultValues:{...props?.loanInfo},
    values:{...props?.loanInfo}
  });
  const selectedLoanType = watch("loanType");
  const selectTypeOfIndustry = watch("typeOfIndustry");
  const yearsOfOperation = watch("yearsOfOperation");

  const [loanProduct, setloanProduct] = useState([]);
  const [typeOfIndustry, settypeOfIndustry] = useState([]);
  useEffect(() => {
    if (_.get(globalConfiguration, "loanProducts")) {
      let loanType = _.map(
        _.get(globalConfiguration, "loanProducts"),
        (value, key) => ({ value: key, label: value })
      );
      setloanProduct(loanType);
    }
    if (_.get(globalConfiguration, "loanProducts")) {
      let list = _.map(
        _.get(globalConfiguration, "industryTypes"),
        (value, key) => ({ value: key, label: value })
      );
      settypeOfIndustry(list);
    }
    
  }, []);

  const onSubmit = (data) => {
    
    props?.setloanInfo({...props?.loanInfo,...data})
    props?.handleNext(true)
  };

  const getSubCategory = (type) => {
        let list = _.map(
            _.get(globalConfiguration, `industrySubCategory[${type}]`,[]),
            (value, key) => ({ value: key, label: value })
          );
         
      return _.size(list)>0? list:[];
    
  };


  useEffect(() => {
    
    
  }, [yearsOfOperation])
  

  const getPurposeLoan = (type) => {
        let list = _.map(
            _.get(globalConfiguration, `loanPurposes[${type}]`,[]),
            (value, key) => ({ value: key, label: value })
          );
         
      return _.size(list)>0? list:[];
    
  };
    
  

  useEffect(() => {
    if (selectedLoanType) {
      reset(
        { loanType: selectedLoanType }, // Keep the current value of loanType
        {
          keepErrors: false,
          keepDirty: false,
          keepValues: false,
          keepDefaultValues: false,
        } // Keeps errors but resets other field values
      );
    }
  }, [selectedLoanType]);

  const filterLoanProduct = (loanType) => {
    let product = _.filter(
      loanProduct,
      (value, index) => value?.value == loanType
    );
    return _.size(product) > 0
      ? _.get(_.head(product), "label")
      : "Loan Product";
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card>
        <Divider />
        <CardContent>
          <Grid container spacing={2} mt={1}>
            <Grid
              container
              item
              spacing={2}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <FormRowRadio
                name="loanType"
                control={control}
                label="Loan Product"
                options={loanProduct}
                rules={LoanRules.loanType}
              />

              <Box marginLeft={5}>
                <Typography
                  variant="h4"
                  component="h4"
                  style={{ marginBottom: "0px" }}
                >
                  Product Code: {selectedLoanType}
                </Typography>
              </Box>
            </Grid>
            {selectedLoanType && (
              <Fieldset
                title={
                  <Stack direction="row" alignItems="center" gap={1}>
                    {filterLoanProduct(selectedLoanType)}
                  </Stack>
                }
                color="#4984BF"
                titleSize="1.2rem"
                borderWidth={2}
                borderRadius={1}
              >
                {selectedLoanType === "SEL" && (
                  <>
                    <Grid container item spacing={2} mb={3} mt={0}>
                      <FormRowAutoComplete
                        name="typeOfIndustry"
                        control={control}
                        label="Type of Industry"
                        options={typeOfIndustry}
                        rules={LoanRules.typeOfIndustry}
                      />
                      {selectTypeOfIndustry == "others" ? (
                        <FormRow
                          rules={LoanRules.subCategory}
                          control={control}
                          name="subCategory"
                          label="Sub Category"
                        />
                      ) : (
                        _.size(getSubCategory(selectTypeOfIndustry)) > 0 && (
                          <FormRowAutoComplete
                            name="subCategory"
                            control={control}
                            label="Sub Category"
                            rules={LoanRules.subCategory}
                            options={getSubCategory(selectTypeOfIndustry)}
                          />
                        )
                      )}
                    </Grid>
                    <Grid container item spacing={2} mb={3}>
                      <FormRow
                        control={control}
                        name="businessName"
                        rules={LoanRules.tradeName}
                        label="Business Name"
                      />
                      <FormRow
                        control={control}
                        name="businessExperience"
                        rules={LoanRules.businesExperience}
                        label="Busines experience"
                      />

                      <FormRow
                        control={control}
                        name="LocationOfOperation"
                        rules={LoanRules.LocationOfOperation}
                        label="Location of operation"
                      />
                    </Grid>
                    <Grid container item spacing={2} mb={3}>
                      <FormRow
                      inputType="number"
                        control={control}
                        name="yearsOfOperation"
                        label="Years of operation"
                        rules={LoanRules.yearsOfOperation}
                      />

                      <FormRow
                        control={control}
                        name="totalInvestment"
                        label="Total Investment"
                        rules={LoanRules.totalInvestment}
                      />
                      <FormRowRadio
                        name="businessPremises"
                        control={control}
                        label="Business Premises"
                        options={businessPremises}
                        rules={LoanRules.businessPremises}
                      />
                    </Grid>
                    <Grid container item spacing={2} mb={3}>
                      <FormRow
                        control={control}
                        name="noOfWomenEmployee"
                        label="No of women employee"
                        rules={LoanRules.noOfWomenEmployee}
                      />
                      <FormRow
                        control={control}
                        name="totalEmployee"
                        label="Total employee"
                        rules={LoanRules.totalEmployee}
                      />
                      <FormRow
                        control={control}
                        name="panCardNo"
                        label="Pan card No"
                        rules={LoanRules.panCardNo}
                      />
                    </Grid>
                    <Grid container item spacing={2} mb={3}>
                      <FormRow
                        control={control}
                        name="annualIncome"
                        label="Annual Income"
                        rules={LoanRules.annualIncome}
                      />
                      <FormRow
                        control={control}
                        name="licenseNo"
                        label="License No"
                        rules={LoanRules.licenseNo}
                      />
                      <FormRow
                        control={control}
                        name="gstNo"
                        label="GST No"
                        rules={LoanRules.gstNo}
                      />
                    </Grid>
                    <Grid container item spacing={2} mb={3}>
                      <FormRow
                        control={control}
                        name="email"
                        label="Email"
                        rules={LoanRules.email}
                      />
                      <FormRow
                        control={control}
                        name="contactNo"
                        label="Contact No"
                        rules={LoanRules.phoneNo}
                      />
                      <FormRow
                        control={control}
                        name="phoneNo"
                        label="Phone No"
                        rules={LoanRules.phoneNo}
                      />
                    </Grid>
                    <Grid container item spacing={2} mb={3}>
                      <FormRow
                        control={control}
                        name="address"
                        multiline={true}
                        label="Address"
                        rules={LoanRules.address}
                      />

                      <FormRow
                        control={control}
                        name="pinCode"
                        label="Pincode"
                        rules={LoanRules.pinCode}
                      />
                    </Grid>
                  </>
                )}
                {selectedLoanType === "WCL" && (
                  <>
                    <Grid container item spacing={2} paddingTop={"16px"} mb={3}>
                      <FormRowAutoComplete
                        name="typeOfIndustry"
                        control={control}
                        label="Type of Industry"
                        options={typeOfIndustry}
                        rules={LoanRules.typeOfIndustry}
                      />
                      {selectTypeOfIndustry == "others" ? (
                        <FormRow
                          rules={LoanRules.subCategory}
                          control={control}
                          name="subCategory"
                          label="Sub Category"
                        />
                      ) : (
                        <FormRowAutoComplete
                          name="subCategory"
                          control={control}
                          label="Sub Category"
                          rules={LoanRules.subCategory}
                          options={getSubCategory(selectTypeOfIndustry)}
                        />
                      )}
                    </Grid>
                    <Grid container item spacing={2} mb={3}>
                      <FormRow
                        control={control}
                        name="businessName"
                        rules={LoanRules.tradeName}
                        label="Business Name"
                      />
                      <FormRow
                        control={control}
                        name="businessExperience"
                        rules={LoanRules.businesExperience}
                        label="Busines experience"
                      />

                      <FormRow
                        control={control}
                        name="LocationOfOperation"
                        label="Location of operation"
                        rules={LoanRules.LocationOfOperation}
                      />
                    </Grid>
                    <Grid container item spacing={2} mb={3}>
                      <FormRow
                        control={control}
                        name="yearsOfOperation"
                        label="Years of operation"
                        rules={LoanRules.yearsOfOperation}
                      />
                      <FormRow
                        control={control}
                        name="totalInvestment"
                        label="Total Investment"
                        rules={LoanRules.totalInvestment}
                      />
                      <FormRowRadio
                        name="businessPremises"
                        control={control}
                        label="Business Premises"
                        options={businessPremises}
                        rules={LoanRules.businessPremises}
                      />
                    </Grid>
                    <Grid container item spacing={2} mb={3}>
                      <FormRow
                        control={control}
                        name="noOfWomenEmployee"
                        label="No of women employee"
                        rules={LoanRules.noOfWomenEmployee}
                      />
                      <FormRow
                        control={control}
                        name="totalEmployee"
                        label="Total employee"
                        rules={LoanRules.totalEmployee}
                      />
                      <FormRow
                        control={control}
                        name="panCardNo"
                        label="Pan card No"
                        rules={LoanRules.panCardNo}
                      />
                    </Grid>
                    <Grid container item spacing={2} mb={3}>
                      <FormRow
                        control={control}
                        name="annualIncome"
                        label="Annual Income"
                        rules={LoanRules.annualIncome}
                      />
                      <FormRow
                        control={control}
                        name="licenseNo"
                        label="License No"
                        rules={LoanRules.licenseNo}
                      />
                      <FormRow
                        control={control}
                        name="gstNo"
                        label="GST No"
                        rules={LoanRules.gstNo}
                      />
                    </Grid>
                    <Grid container item spacing={2} mb={3}>
                   
                      <FormRow
                        control={control}
                        name="email"
                        label="Email"
                        rules={LoanRules.email}
                      />
                      <FormRow
                        control={control}
                        name="contactNo"
                        label="Contact No"
                        rules={LoanRules.phoneNo}
                      />
                      <FormRow
                        control={control}
                        name="phoneNo"
                        label="Phone No"
                        rules={LoanRules.phoneNo}
                      />
                    </Grid>
                    <Grid container item spacing={2} mb={3}>
                      <FormRow
                        control={control}
                        name="address"
                        multiline={true}
                        label="Address"
                        rules={LoanRules.address}
                      />

                      <FormRow
                        control={control}
                        name="pinCode"
                        label="Pincode"
                        rules={LoanRules.pinCode}
                      />
                    </Grid>
                  </>
                )}
                {selectedLoanType === "GL" && (
                  <>
                    <Grid container item spacing={2} mb={3} paddingTop={"16px"}>
                      <FormRowAutoComplete
                        name="purposeOfLoan"
                        control={control}
                        label="Purpose of loan"
                        options={getPurposeLoan(selectedLoanType)}
                        rules={LoanRules.purposeOfLoan}
                      />
                      <FormRow
                        control={control}
                        name="acres"
                        label="Acres"
                        rules={LoanRules.acres}
                      />
                      <FormRow
                        control={control}
                        name="landValue"
                        label="Land Value"
                        rules={LoanRules.landValue}
                      />
                    </Grid>
                    <Grid container item spacing={2} mb={3}>
                      <FormRow
                        control={control}
                        name="location"
                        label="Location of operation"
                        rules={LoanRules.location}
                      />
                      <FormRow
                        control={control}
                        name="sureveyNo"
                        label="Surevey No"
                        rules={LoanRules.sureveyNo}
                      />
                      <FormRow
                        control={control}
                        name="subDivision"
                        label="Sub Division"
                        rules={LoanRules.subDivision}
                      />
                    </Grid>
                    <Grid container item spacing={2} mb={3}>
                      {
                        <FormRowRadio
                          name="ownerShip"
                          control={control}
                          label="Ownership"
                          options={getSubCategory(typeOfIndustry)}
                          rules={LoanRules.ownerShip}
                        />
                      }
                      <FormRow
                        control={control}
                        name="annualIncome"
                        label="Annual Income"
                        rules={LoanRules.annualIncome}
                      />
                    </Grid>
                  </>
                )}
                {selectedLoanType === "LF" && (
                  <>
                    <Grid container item spacing={2} mb={3} paddingTop={"16px"}>
                      <FormRowAutoComplete
                        name="purposeOfLoan"
                        control={control}
                        label="Purpose of loan"
                        options={getPurposeLoan(selectedLoanType)}
                        rules={LoanRules.purposeOfLoan}
                      />
                      <FormRow
                        control={control}
                        name="LocationOfOperation"
                        label="Location of operation"
                        rules={LoanRules.LocationOfOperation}
                      />
                      <FormRowRadio
                        name="businessPremises"
                        control={control}
                        label="Business Premises"
                        options={businessPremises}
                        rules={LoanRules.businessPremises}
                      />
                    </Grid>
                    <Grid container item spacing={2} mb={3}>
                      <FormRow
                        control={control}
                        name="totalInvestment"
                        label="Total Investment"
                        rules={LoanRules.totalInvestment}
                      />
                      <FormRowRadio
                        name="typeOfLivestock"
                        control={control}
                        label="Type of Livestock"
                        options={genderList}
                        rules={LoanRules.typeOfLivestock}
                      />
                      <FormRow
                        control={control}
                        name="noOfLivestock"
                        label="No of Livestock"
                        rules={LoanRules.noOfLivestock}
                      />
                    </Grid>

                    <Grid container item spacing={2} mb={3}>
                      <FormRow
                        control={control}
                        name="liveStockValue"
                        label="Live Stock Value"
                        rules={LoanRules.liveStockValue}
                      />
                      <FormRow
                        control={control}
                        name="annualIncome"
                        label="Annual Income"
                        rules={LoanRules.annualIncome}
                      />
                      <FormRow
                        control={control}
                        name="otherIncome"
                        label="Other Income"
                        rules={LoanRules.otherIncome}
                      />
                    </Grid>
                    <Grid container item spacing={2} mb={3}>
                      <FormRow
                        control={control}
                        name="panCardNo"
                        label="Pan card No"
                        rules={LoanRules.panCardNo}
                      />
                      <FormRow
                        control={control}
                        name="licenseNo"
                        label="License No"
                        rules={LoanRules.licenseNo}
                      />
                      <FormRow
                        control={control}
                        name="gstNo"
                        label="GST No"
                        rules={LoanRules.gstNo}
                      />
                    </Grid>
                    <Grid container item spacing={2} mb={3}>
                      <FormRow
                        control={control}
                        name="address"
                        multiline={true}
                        label="Address"
                        rules={LoanRules.address}
                      />
                      <FormRow
                        control={control}
                        name="phoneNo"
                        label="Phone No"
                        rules={LoanRules.phoneNo}
                      />
                      <FormRow
                        control={control}
                        name="email"
                        label="Email"
                        rules={LoanRules.email}
                      />
                    </Grid>
                  </>
                )}
                {selectedLoanType === "PL" && (
                  <>
                    <Grid container item spacing={2} mb={3} paddingTop={"16px"}>
                      <FormRowAutoComplete
                        name="purposeOfLoan"
                        control={control}
                        label="Purpose of loan"
                        options={getPurposeLoan(selectedLoanType)}
                        rules={LoanRules.purposeOfLoan}
                      />
                      <FormRowRadio
                        name="companyCategory"
                        control={control}
                        label="Company Category"
                        options={companyCategory}
                        rules={LoanRules.companyCategory}
                      />
                      <FormRow
                        control={control}
                        name="companyName"
                        label="Comapny Name"
                        rules={LoanRules.companyName}
                      />
                    </Grid>
                    <Grid container item spacing={2} mb={3}>
                      <FormRow
                        control={control}
                        name="designation"
                        label="Designation"
                        rules={LoanRules.designation}
                      />
                      <FormRow
                        control={control}
                        name="yearOfExperience"
                        label="Year Of Experience"
                        rules={LoanRules.yearOfExperience}
                      />
                      <FormRow
                        control={control}
                        name="annualSalary"
                        label="Annual Salary"
                        rules={LoanRules.annualSalary}
                      />
                    </Grid>
                    <Grid container item spacing={2} mb={3}>
                      <FormRow
                        control={control}
                        name="contactNo"
                        label="Contact No"
                        rules={LoanRules.phoneNo}
                      />
                      <FormRow
                        control={control}
                        name="phoneNo"
                        label="Phone No"
                        rules={LoanRules.phoneNo}
                      />
                      <FormRow
                        control={control}
                        name="email"
                        label="Email"
                        rules={LoanRules.email}
                      />
                    </Grid>
                    <Grid container item spacing={2} mb={3}>
                      <FormRow
                        control={control}
                        name="address"
                        label="Address"
                        rules={LoanRules.address}
                      />
                    </Grid>
                  </>
                )}
                {selectedLoanType === "CDL" && (
                  <>
                    <Grid container item spacing={2} mb={3} paddingTop={"16px"}>
                      <FormRowAutoComplete
                        name="purposeOfLoan"
                        control={control}
                        label="Purpose of loan"
                        options={getPurposeLoan(selectedLoanType)}
                        rules={LoanRules.purposeOfLoan}
                      />
                    </Grid>
                    <Grid container item spacing={2} mb={3}>
                      <FormRow
                        control={control}
                        name="loanAmount"
                        label="Loan Amount"
                        rules={LoanRules.loanAmount}
                      />
                      <FormRow
                        control={control}
                        name="loanTenure"
                        label="Loan Tenure"
                        rules={LoanRules.loanTenure}
                      />
                      <FormRow
                        control={control}
                        name="phoneNo"
                        label="Phone No"
                        rules={LoanRules.phoneNo}
                      />
                    </Grid>
                  </>
                )}
              </Fieldset>
            )}
          </Grid>
        </CardContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            px: 2,
            pb: 2,
          }}
        >
          <Button
            variant="contained"
            sx={{ mt: 2, ml: 1 }}
            href="/app/customers"
            style={{ height: "36px" }}
          >
            Cancel
          </Button>
          <Box
            sx={{ display: "flex", justifyContent: "flex-end", px: 2, pb: 2 }}
          >
            {/* {activeStep !== 0 && ( */}
            {/* <Button sx={{ mt: 2, ml: 1 }}>
                            Previous
                        </Button> */}
            {/* )} */}

            <Button variant="contained" sx={{ mt: 2, ml: 1 }} type="submit">
              save
              {/* {activeStep === steps.length - 1 ? 'Finish' : 'Save & Next'} */}
            </Button>
          </Box>
        </Box>
      </Card>
    </form>
  );
};

export default LoanType;
