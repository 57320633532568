import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container } from '@mui/material';
import { services } from 'src/components/services/services';
import { useLocation } from 'react-router-dom';
import SnackBar from 'src/components/shared/SnackBar';
import LoansListToolbar from 'src/components/loans/LoansListToolbar';
import LoansListResults from 'src/components/loans/LoansListResults';
import _ from 'lodash'
import { useNavigate } from 'react-router-dom';

export const LoanList = () => {
  const location = useLocation();
  const [loans, setLoans] = useState([]);
  const [searchedInputs, setSearchedInputs] = useState('');
  // const [searchedAadhar, setSearchedAadhar] = useState('');
  // const [searchedCustomerName, setSearchedCustomerName] = useState('');
  // const [searchedCustomerStatus, setSearchedStatus] = useState('');
  const [open, setOpen] = useState(true);
  const loanDelayedQuery = _.debounce(q => handleSearch(q), 1200);
  const [pageDetails, setPageDetails] = useState({});

  const navigate = useNavigate();

  {/* pending_review|approved|rejected|sent_back|disbursed|closed|overdue */ }
  const status = { Pending: 'pending_review', Approved: 'approved', Disbursed: 'disbursed', Rejected: 'rejected', 'Sent back': 'sent_back', 'Closed': 'closed' }

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      navigate('/login')
    }
  }, [])
  const handleChange = (e) => {
    console.log('ee', e);
    setSearchedInputs({
      ...searchedInputs, [e.target.name]: e.target.name === 'status' ? _.get(status, e.target.value) : e.target.value
    });
    loanDelayedQuery({
      ...searchedInputs, [e.target.name]: e.target.name === 'status' ? _.get(status, e.target.value) : e.target.value
    })
  }

  const handleSearch = (q) => {
    let queryValues = new URLSearchParams(q).toString();
    console.log('queryValues', queryValues);
    services.postService(`loan/list`, { pageCount: 5, start: 1 })
      .then(res => setLoans(res.data))
      .catch(err => {
        if (!localStorage.getItem('token')) {
          navigate('/login');
        }
      })
  }

  useEffect(() => {
    getLoanList();
  }, [])
  const getLoanList = (start = 0, count = 10) => {
    services.postService(`loan/list`, { currentPage: start + 1 })
      .then(res => {
        setLoans(_.get(res, 'data.content'))
        console.log('redfdfdfreffe', res);
        setPageDetails({ count: _.get(res, 'data.count'), start: _.get(res, 'data.start'), total: _.get(res, 'data.count') })

      })
      .catch(err => {
        if (!localStorage.getItem('token')) {
          navigate('/login');
        }
      })
  }

  const changePage = (value, count) => {
    getLoanList(value, count)
  }

  // useEffect(() => {
  //   services.postService(`loan/list`, { pageCount: 10, start: 1 })
  //     .then(res => {
  //       setLoans(_.get(res, 'data.content'))
  //       setPageDetails({ count: _.get(res.data, 'count'), start: _.get(res.data, 'start'), total: _.get(res.data, 'total') })
  //     }
  //     )
  //     .catch(err => {
  //       if (!localStorage.getItem('token')) {
  //         navigate('/login');
  //       }
  //     })
  // }, [])

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Helmet>
        <title>Loans - Thangam Capital </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        {
          location && location.state && location.state.status && <SnackBar open={open} message={location.state.message} handleClose={handleClose} severity="success" />
        }
        <Container maxWidth={false}>
          <LoansListToolbar handleChange={handleChange} handleSearch={handleSearch} />
          <Box sx={{ pt: 3 }}>
            <LoansListResults onChangePage={(value, count) => changePage(value, count)} pagination_details={pageDetails} loans={loans} />
          </Box>
        </Container>
      </Box>
    </>
  )
}
