import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container } from '@mui/material';
import UserListToolbar from 'src/components/users/UserListToolbar';
import UserListResults from 'src/components/users/UserListResults';
import { services } from 'src/components/services/services';
import _ from "lodash";
// import BreadcrumbsComponent from 'src/components/Breadcrumbs';
import { useNavigate } from 'react-router-dom';

const UsersList = () => {
  const [users, setUsers] = useState([]);
  const [pageDetails, setPageDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const history = useNavigate()
  useEffect(() => {
    if (!localStorage.getItem('token')) {
      history('/login')
    }
  }, [])
  useEffect(() => {
    (async () => {
      const users = await services.postService(`user/list`, { pageCount: 5, start: 1 });
      setLoading(false)
      setPageDetails({ count: _.get(users, 'data.count'), start: _.get(users, 'data.start'), total: _.get(users, 'data.count') })
      setUsers(_.get(users, 'data.content'));
    })();
  }, [])
  const getUserList = (start = 0, count = 10) => {
    (async () => {
      setLoading(true)
      const users = await services.postService(`user/list`, { pageCount: count, start: start });
      setLoading(false)
      setPageDetails({ count: _.get(users, 'data.count'), start: _.get(users, 'data.start'), total: _.get(users, 'data.count') })
      setUsers(_.get(users, 'data.content'));
    })();
  }

  const changePage = (value, count) => {
    getUserList(value, count)
  }
  return (
    <>
      <Helmet>
        <title>Users - Thangam Capital </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <UserListToolbar />
          <Box sx={{ pt: 3 }}>
            <UserListResults loading={loading} onChangePage={(value, count) => changePage(value, count)} pagination_details={pageDetails} users={users} />
          </Box>
        </Container>
      </Box>
    </>
  )
};

export default UsersList;
