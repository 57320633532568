import React from "react";
import {
  FormControlLabel,
  Checkbox,
  FormGroup,
  FormLabel,
} from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import { Controller } from "react-hook-form";

export const FormInputCheckboxGroup = ({
  name,
  control,
  label,
  options,
  rules,
  readOnly = false,
  onChangeHandler
}) => {
  let fieldRules = rules || {};
  if (rules === undefined) fieldRules.required = false;

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { onChange, value = [] },
        fieldState: { error },
      }) => (
        <>
          <FormLabel component="legend">{label}</FormLabel>
          <FormGroup>
            {options.map((option) => (
              <FormControlLabel
                key={option.value}
                control={
                  <Checkbox
                    checked={value.includes(option.value)}
                    onChange={(e) => {
                      const newValue = e.target.checked
                        ? [...value, option.value] 
                        : value.filter((val) => val !== option.value);  
                      
                      if (onChangeHandler) {
                        onChangeHandler(newValue);
                      }
                      
                      onChange(newValue);
                    }}
                    name={option.value}
                    disabled={readOnly}
                  />
                }
                label={option.label}
              />
            ))}
            <FormHelperText sx={{ ml: 2 }} error={error}>
              {error ? error.message : null}
            </FormHelperText>
          </FormGroup>
        </>
      )}
      rules={{
        ...fieldRules,
      }}
    />
  );
};
