
import React, { useState } from 'react'
import { Stack, Button, Modal, TextField } from '@mui/material';
import { Box } from '@mui/material';
import _ from "lodash";
// import styled from '@emotion/styled';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
const headerTitle = {
  color: '#038dd7',
  fontWeight: '600',
  textDecoration: 'underline',
  fontSize: '23px',
  textAlign: 'center'
}
const PendingScreen = (props) => {
  const [comments, setComments] = useState('')
  const [modalTitle, setModalTitle] = useState('approved')


  //  const StyledCardHeader = styled(CardHeader)(({ theme }) => ({
  //   backgroundColor: '#038dd7',
  //   color: '#fffff',

  // }))

  return <>
    <Stack style={{ margin: 20 }} direction="row"
      justifyContent="space-between"
      alignItems="center"
    // spacing={{ xs: 5, sm: 2, md: 4 }} 
    >
      <Button
        style={{ cursor: 'pointer', borderRadius: '30px' }}
        variant="contained"
        color="success"
        // sx={{ mt: 2, ml: 1 }}
        onClick={() => { setModalTitle('approved'); setComments(''); props.handleOpen() }}

      >
        Approve
      </Button>
      <Button
        color="error"
        variant="contained"
        style={{ cursor: 'pointer', borderRadius: '30px' }}
        // sx={{ mt: 2, ml: 1 }}
        onClick={() => { setModalTitle('rejected'); setComments(''); props.handleOpen() }}
      >
        Reject
      </Button>
      <Button
        variant="contained"
        // sx={{ mt: 2, ml: 1 }}
        style={{ cursor: 'pointer', borderRadius: '30px' }}
        onClick={() => { setModalTitle('sent_back'); setComments(''); props.handleOpen() }}

      >
        Send Back
      </Button>
    </Stack>
    <Modal

      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >

      <Box sx={{ ...style, width: 750, height: 350, borderRadius: '25px', borderColor: '#038dd7', borderWidth: '5px' }}>
        <div ><h2 id="parent-modal-title" style={headerTitle}>{modalTitle === 'approved' ? "Approve" : modalTitle === 'rejected' ? "Reject" : modalTitle === 'sent_back' ? "Send Back" : _.startCase(modalTitle)}</h2></div>
        {/* <StyledCardHeader title= /> */}
        <Stack style={{ marginTop: 23 }} direction="column"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <TextField
            fullWidth="78"
            id="outlined-multiline-static"

            label="Comments"
            multiline

            rows={5}
            value={comments}
            onChange={(e) => setComments(e.target.value)}
          />
          <Stack direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={61} >
            <Button
              style={{ cursor: 'pointer', width: '36%' }}
              variant="contained"
              color="success"
              // sx={{ mt: 2, ml: 1 }}
              onClick={() => { comments && props.onChangeLoanStatus({ comments: [comments], loan_status: modalTitle }); props.handleClose() }}

            >
              {modalTitle === 'approved' ? "Approve" : modalTitle === 'rejected' ? "Reject" : modalTitle === 'sent_back' ? "Send Back" : _.startCase(modalTitle)}
            </Button>
            <Button
              style={{ cursor: 'pointer' }}
              variant="contained"
              // color="success"
              // sx={{ mt: 2, ml: 1 }}
              onClick={props.handleClose}

            >
              Cancel
            </Button>
            {/* {modalTitle==="approved" && <Button
                    color="error"
            variant="contained"
            style={{cursor:'pointer'}}
            // sx={{ mt: 2, ml: 1 }}
            onClick={()=>{setModalTitle('rejected'); props.handleOpen()}}
          >
            Reject
          </Button>} */}
          </Stack>
        </Stack>
      </Box>
    </Modal>
  </>
}

export default PendingScreen;