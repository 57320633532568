import { useState, useEffect } from 'react';
import { Box, CardHeader, Container } from '@mui/material';
import { services } from 'src/components/services/services';
import { useLocation } from 'react-router-dom';
import SnackBar from 'src/components/shared/SnackBar';
import LoansListResults from 'src/components/loan-ledger/LoansListResults';
// import _ from 'lodash'
import { jwtDecode } from "jwt-decode";
import { useNavigate } from 'react-router-dom';
import _ from "lodash";
import styled from '@emotion/styled';
// import { Card } from 'antd';

const MyLoanList = ({ loan_details, loan_id }) => {
  // const [comments, setComments] = useState('')

  const location = useLocation();
  const [loans, setLoans] = useState([]);
  // const [searchedInputs, setSearchedInputs] = useState('');
  // const [searchedAadhar, setSearchedAadhar] = useState('');
  // const [searchedCustomerName, setSearchedCustomerName] = useState('');
  // const [searchedCustomerStatus, setSearchedStatus] = useState('');
  const [open, setOpen] = useState(true);
  // const loanDelayedQuery = _.debounce(q => handleSearch(q), 1200);
  const [pageDetails, setPageDetails] = useState({});

  const navigate = useNavigate();

  {/* pending_review|approved|rejected|sent_back|disbursed|closed|overdue */ }
  //  const status ={Pending:'pending_review',Approved:'approved',Disbursed:'disbursed',Rejected:'rejected','Sent back':'sent_back','Closed':'closed'}

  // const handleChange = (e) => {
  //   console.log('ee',e);
  //   setSearchedInputs({
  //     ...searchedInputs, [e.target.name]: e.target.name=='status'? _.get(status,e.target.value):e.target.value
  //   });
  //   loanDelayedQuery({
  //     ...searchedInputs, [e.target.name]:e.target.name=='status'? _.get(status,e.target.value):e.target.value
  //   })
  // }

  //   const handleSearch = (q) => {
  //     let userDetails =jwtDecode(localStorage.getItem('token'))
  //       let query_details ={...q,addedBy:_.get(userDetails,'user.user_id')}
  //     let queryValues = new URLSearchParams(query_details).toString();
  //     console.log('queryValues',queryValues);
  //     services.getService(`loan/list?${queryValues}`)
  //       .then(res => setLoans(res.data))
  //       .catch(err => {
  // if(!localStorage.getItem('token')){
  //  navigate('/login');
  // }
  // })
  //   }
  const getLoanList = (start = 0) => {
    console.log('tokes', jwtDecode(localStorage.getItem('token')));
    // let userDetails =jwtDecode(localStorage.getItem('token'))
    services.getService(`loan/ledger/${loan_id}`)
      .then(res => {
        console.log('resres', res);
        setLoans(res.data.content)
        setPageDetails({ count: _.get(res.data, 'count'), start: _.get(res.data, 'start') })

        console.log(loans);
        console.log(pageDetails);

      })
      .catch(err => {
        if (!localStorage.getItem('token')) {
          navigate('/login');
        }
      })
  }

  const changePage = (value) => {
    getLoanList(value)
  }

  useEffect(() => {
    getLoanList()
  }, [])


  const handleClose = () => {
    setOpen(false);
  };
  // const StyledCard = styled(Card)(({ theme }) => ({
  //   backgroundColor: 'rgb(255, 255, 255)',
  //   color: 'rgb(97, 97, 97)',
  //   transition: 'none',
  //   backgroundImage: 'none',
  //   borderRadius: '8px',
  //   overflow: 'hidden',
  //   border: '1px solid rgb(227, 242, 253)',
  //   // transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  //   boxShadow: "none",
  //   '&:hover': {
  //     // border: '2px solid rgb(227, 242, 253)',
  //     // boxShadow: 'rgb(32 40 45 / 8%) 0px 2px 14px 0px'
  //   }
  // }))

  const StyledCardHeader = styled(CardHeader)(({ theme }) => ({
    backgroundColor: '#038dd7',
    color: '#fffff',
    marginBottom: '23px'

  }))
  return (
    <div>

      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        {
          location && location.state && location.state.status && <SnackBar open={open} message={location.state.message} handleClose={handleClose} severity="success" />
        }
        <Container maxWidth={false}>
          <Box sx={{ pt: 3 }}>
            {/*  <StyledCardHeader title="Loan Ledger" /> */}
            <LoansListResults onChangePage={(value, count) => changePage(value, count)} pagination_details={pageDetails} loans={loans} />
          </Box>
        </Container>
      </Box>
    </div>
  )
}
export default MyLoanList;
