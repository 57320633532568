import globalConfig from 'src/utils/config';
import axios from 'axios';
import _ from 'lodash'
const { url: {
  baseDomain,
  apiVersion
} } = globalConfig;

export const services = {
  baseDomain: baseDomain,
  apiVersion: apiVersion,
  getService: async (service) => {
    let url = `${baseDomain}${apiVersion}${service}`;
    try {
      const res = await axios.get(url, { headers: {
        'Authorization':  `${localStorage.getItem('token')}`,
      } });
      return res.data;
    } catch (err) {
      console.log('err', err.response);
      if(`${_.get(err,'response.data.message')}`==="Invalid token"){
        localStorage.clear()
        // window.location.reload();

      }
      throw err && err.response ? err.response : err;
    }
  },
  postService: async (service, payload) => {
    let url = `${baseDomain}${apiVersion}${service}`;
    try {
      const res = await axios.post(url, payload, { headers: {
        'Authorization': `${localStorage.getItem('token')}`
      } });
      return res.data;
    } catch (err) {
      console.log('err', err);
      if(`${_.get(err,'response.data.message')}`==="Invalid token"){
        localStorage.clear()
        // window.location.reload();

      }
      throw err && err.response ? err.response : err;
    }
  },
  putService: async (service, payload) => {
    let url = `${baseDomain}${apiVersion}${service}`;
   //console.log("update user",url)
    try {
      const res = await axios.put(url, payload, { headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      } });
      return res.data;
    } catch (err) {
      console.log(err);
      if(`${_.get(err,'response.data.message')}`==="Invalid token"){
        localStorage.clear()
        // window.location.reload();

      }
      throw err && err.response ? err.response : err;
    }
  }
}