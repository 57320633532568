import React from "react";
import {
  FormControlLabel,
  Checkbox,
  FormGroup,
} from "@mui/material";
import FormHelperText from '@mui/material/FormHelperText';
import { Controller } from "react-hook-form";

export const FormInputCheckbox = ({
  name,
  control,
  label,
  rules,
  readOnly=false,
  onChangeHandler
}) => {
  let fieldRules = rules || {};
  if (rules === undefined) fieldRules.required = false;

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { onChange, value },
        fieldState: { error },
      }) => (
        <>
          <FormGroup>
            <FormControlLabel control={<Checkbox checked={value} name={name} onChange={e => {
              onChangeHandler(e.target.checked)
              onChange(e.target.checked);
            }
            } disabled={readOnly} />} label={label} />
            <FormHelperText sx={{ ml: 2 }} error={error}>{error ? error.message : null}</FormHelperText>
          </FormGroup>
        </>
      )}
      rules={{
        ...fieldRules
      }}
    />
  );
};