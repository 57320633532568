import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container } from '@mui/material';
import { services } from 'src/components/services/services';
import { useLocation } from 'react-router-dom';
import SnackBar from 'src/components/shared/SnackBar';
import LoansListToolbar from 'src/components/loans/LoansListToolbar';
import LoansListResults from 'src/components/loans/LoansListResults';
// import _ from 'lodash'
import {jwtDecode} from "jwt-decode";
import { useNavigate } from 'react-router-dom';
import _ from "lodash";

export const MyLoanList = () => {
  const location = useLocation();
  const [loans, setLoans] = useState([]);
  const [searchedInputs, setSearchedInputs] = useState('');
  // const [searchedAadhar, setSearchedAadhar] = useState('');
  // const [searchedCustomerName, setSearchedCustomerName] = useState('');
  // const [searchedCustomerStatus, setSearchedStatus] = useState('');
  const [open, setOpen] = useState(true);
  const loanDelayedQuery = _.debounce(q => handleSearch(q), 1200);
  const [pageDetails, setPageDetails] = useState({});

  const navigate = useNavigate();
  useEffect(()=>{
if(!localStorage.getItem('token')){
  navigate('/login')
}
  },[])
 {/* pending_review|approved|rejected|sent_back|disbursed|closed|overdue */}
 const status ={Pending:'pending_review',Approved:'approved',Disbursed:'disbursed',Rejected:'rejected','Sent back':'sent_back','Closed':'closed'}

  const handleChange = (e) => {
    console.log('ee',e);
    setSearchedInputs({
      ...searchedInputs, [e.target.name]: e.target.name==='status'? _.get(status,e.target.value):e.target.value
    });
    loanDelayedQuery({
      ...searchedInputs, [e.target.name]:e.target.name==='status'? _.get(status,e.target.value):e.target.value
    })
  }

  const handleSearch = (q) => {
    let userDetails =jwtDecode(localStorage.getItem('token'))
      let query_details ={...q,addedBy:_.get(userDetails,'user.user_id')}
    let queryValues = new URLSearchParams(query_details).toString();
    console.log('queryValues',queryValues);
    services.getService(`loan/list?${queryValues}`)
      .then(res => setLoans(res.data))
      .catch(err => {
if(!localStorage.getItem('token')){
 navigate('/login');
}
})
  }
  const getLoanList =(start=0,count=10)=>{
    console.log('tokes',jwtDecode(localStorage.getItem('token')));
      let userDetails =jwtDecode(localStorage.getItem('token'))
    services.getService(`loan/list?addedBy=${_.get(userDetails,'user.user_id')}&start=${start}&count=${count}`)
      .then(res => {
        console.log('resresresres',res);
        setLoans(res.data)
        setPageDetails({count:_.get(res,'count'),start:_.get(res,'start'),total:_.get(res,'total')})

      })
      .catch(err => {
if(!localStorage.getItem('token')){
 navigate('/login');
}
})
  }

  const changePage =(value,count)=>{
    getLoanList(value,count) 
   }

  useEffect(() => {
    getLoanList()
  }, [])


  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Helmet>
        <title>My Loans - Thangam Capital </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        {
          location && location.state && location.state.status && <SnackBar open={open} message={location.state.message} handleClose={handleClose} severity="success" />
        }
        <Container maxWidth={false}>
          <LoansListToolbar handleChange={handleChange} handleSearch={handleSearch} type_loan="my_loan" />
          <Box sx={{ pt: 3 }}>
            <LoansListResults onChangePage={(value,count)=>changePage(value,count)} pagination_details={pageDetails} loans={loans} type_loan="my_loan"  />
          </Box>
        </Container>
      </Box>
    </>
  )
}
