import React, { useState } from 'react';
import {
  Box,
  Button,
  Container,
  TextField,
  Checkbox,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Slide,
  Typography,
  Grid,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Pagination from '@mui/material/Pagination';
import DatasetList from './DatasetList';

const validationSchema = yup.object({
  name: yup.string().required('Name is required'),
  code: yup.string().required('Code is required'),
  description: yup.string().required('Description is required'),
});

const Dataset = () => {
  const [addDatasetModal, setAddDatasetModal] = useState(false);
  const [editDataset, setEditDataset] = useState(false);
  const [editDatasetModal, setEditDatasetModal] = useState(false);
  const [datasets, setDatasets] = useState([]);
  const [currentDataset, setCurrentDataset] = useState(null);
  const [searchKey, setSearchKey] = useState('');
  const [isDatasetListVisible, setIsDatasetListVisible] = useState(false);
  const [datasetLists, setDatasetLists] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const formik = useFormik({
    initialValues: {
      name: '',
      code: '',
      description: '',
      isactive: false,
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      if (editDataset) {
        setDatasets(datasets.map(ds => ds.code === currentDataset.code ? values : ds));
      } else {
        setDatasets([...datasets, values]);
      }
      resetForm();
      setAddDatasetModal(false);
      setEditDataset(false);
      setEditDatasetModal(false);
    },
  });

  const handleCodeChange = (event) => {
    const newValue = event.target.value.toUpperCase().replace(/[^A-Z0-9_]/g, '');
    formik.setFieldValue('code', newValue, false);
  };

  const handleAddNew = () => {
    formik.resetForm();
    setAddDatasetModal(true);
  };

  const handleEdit = (dataset) => {
    setEditDataset(true);
    setCurrentDataset(dataset);
    formik.setValues(dataset);
    setEditDatasetModal(true);
  };

  const handleDelete = (code) => {
    setDatasets(datasets.filter(ds => ds.code !== code));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1); 
  };

  const filteredDatasets = datasets.filter(ds => ds.name.includes(searchKey) || ds.code.includes(searchKey));

  const startIndex = (page - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const paginatedDatasets = filteredDatasets.slice(startIndex, endIndex);

  return (
    <Container maxWidth="lg">
      <Box my={4}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={isDatasetListVisible ? 6 : 12}>
            <Box mb={2} display="flex" alignItems="center" justifyContent="space-between" component={Paper} style={{ padding: "1rem" }}>
              <Typography variant="h4" component="h1" gutterBottom>
                Dataset
              </Typography>
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={handleAddNew}
              >
                Add New
              </Button>
              </Box>
             <Slide direction="up" in={addDatasetModal} mountOnEnter unmountOnExit>
              <Box mt={4} p={2} component={Paper}>
                <form onSubmit={formik.handleSubmit}>
                  <TextField
                    fullWidth
                    margin="normal"
                    id="name"
                    name="name"
                    label="Name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />
                  <TextField
                    fullWidth
                    margin="normal"
                    id="code"
                    name="code"
                    label="Code"
                    value={formik.values.code}
                    onChange={handleCodeChange}
                    error={formik.touched.code && Boolean(formik.errors.code)}
                    helperText={formik.touched.code && formik.errors.code}
                  />
                  <TextField
                    fullWidth
                    margin="normal"
                    id="description"
                    name="description"
                    label="Description"
                    multiline
                    rows={4}
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    error={formik.touched.description && Boolean(formik.errors.description)}
                    helperText={formik.touched.description && formik.errors.description}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="isactive"
                        name="isactive"
                        checked={formik.values.isactive}
                        onChange={formik.handleChange}
                      />
                    }
                    label="IsActive"
                  />
                  <Box mt={2} display="flex" justifyContent="space-between">
                    <Button color="primary" variant="contained" type="submit" disabled={!formik.isValid}>
                      Save
                    </Button>
                    <Button color="secondary" variant="contained" onClick={() => setAddDatasetModal(false)}>
                      Close
                    </Button>
                  </Box>
                </form>
              </Box>
            </Slide>
            <Slide direction="up" in={editDatasetModal} mountOnEnter unmountOnExit>
              <Box mt={4} p={2} component={Paper}>
                <form onSubmit={formik.handleSubmit}>
                  <TextField
                    fullWidth
                    margin="normal"
                    id="name"
                    name="name"
                    label="Name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />
                  <TextField
                    fullWidth
                    margin="normal"
                    id="code"
                    name="code"
                    label="Code"
                    value={formik.values.code}
                    onChange={handleCodeChange}
                    error={formik.touched.code && Boolean(formik.errors.code)}
                    helperText={formik.touched.code && formik.errors.code}
                  />
                  <TextField
                    fullWidth
                    margin="normal"
                    id="description"
                    name="description"
                    label="Description"
                    multiline
                    rows={4}
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    error={formik.touched.description && Boolean(formik.errors.description)}
                    helperText={formik.touched.description && formik.errors.description}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="isactive"
                        name="isactive"
                        checked={formik.values.isactive}
                        onChange={formik.handleChange}
                      />
                    }
                    label="IsActive"
                  />
                  <Box mt={2} display="flex" justifyContent="space-between">
                    <Button color="primary" variant="contained" type="submit" disabled={!formik.isValid}>
                      Save
                    </Button>
                    <Button color="secondary" variant="contained" onClick={() => setEditDatasetModal(false)}>
                      Close
                    </Button>
                  </Box>
                </form>
              </Box>
            </Slide>
            <Box my={4}>
              <TextField
                fullWidth
                margin="normal"
                id="searchKey"
                name="searchKey"
                label="Search"
                value={searchKey}
                onChange={(e) => setSearchKey(e.target.value)}
                InputProps={{
                  endAdornment: <SearchIcon />
                }}
              />
            </Box>
            <div style={{display:'flex', flexDirection:'column',alignItems:'flex-end'}}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Code</TableCell>
                    <TableCell>Is Active</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginatedDatasets.map((dataset) => (
                    <TableRow key={dataset.code}>
                      <TableCell>{dataset.name}</TableCell>
                      <TableCell>{dataset.code}</TableCell>
                      <TableCell>
                        <Checkbox checked={dataset.isactive} disabled />
                      </TableCell>
                      <TableCell>
                        <IconButton onClick={() => setIsDatasetListVisible(true)}>
                          <AddIcon />
                        </IconButton>
                        <IconButton onClick={() => handleEdit(dataset)}>
                          <EditIcon />
                        </IconButton>
                        <IconButton onClick={() => handleDelete(dataset.code)}>
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Pagination
              style={{marginTop:'10px'}}
              count={Math.ceil(filteredDatasets.length / rowsPerPage)}
              page={page}
              onChange={handleChangePage}
              rowsPerPageOptions={[5, 10, 25]} 
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              showFirstButton showLastButton
            />
            </div>
          </Grid>
          
          {isDatasetListVisible && (
            <Grid item xs={12} md={6}>
              <DatasetList
                datasetLists={datasetLists}
                setDatasetLists={setDatasetLists}
              />
            </Grid>
          )}
        </Grid>
      </Box>
    </Container>
  );
};

export default Dataset;
