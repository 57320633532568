import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate, useSearchParams } from "react-router-dom";
import { Helmet } from 'react-helmet';
import {
    Box,
    Card,
    CardHeader,
    Divider,
    CardContent,
    Grid,
    Container,
    Button
} from '@mui/material';

import { useForm } from 'react-hook-form';
import { FormInputText } from '../components/shared/FormInputText';
import { FutureDateInput } from '../components/shared/FormInputDate';
import { FormInputDropdown } from '../components/shared/FormInputDropdown';
import { UserRules } from '../utils/validationsConfig';
import { CustomerRules } from '../utils/validationsConfig';
import { ImageUpload } from '../components/shared/ImageUpload';
import { services } from 'src/components/services/services';
import SnackBar from 'src/components/shared/SnackBar';
// import AddCircleIcon from '@mui/icons-material/AddCircle';
import _ from 'lodash'
import axios from "axios"
import { notification, Spin } from 'antd'
import moment from 'moment';
import BreadcrumbsComponent from 'src/components/Breadcrumbs';

function FormRow(props) {
    const { control, name, label, rules, inputType, readonly, multiline = false } = props;

    return (
        <React.Fragment>
            <Grid item
                md={6}
                xs={10}
                lg={6}
            >
                <FormInputText
                    name={name}
                    control={control}
                    label={label}
                    rules={rules}
                    inputType={inputType || "text"}
                    readonly={readonly || false}
                    multiline={multiline}
                />
            </Grid>
        </React.Fragment>
    );
}

const LoanDetailsLoan = () => {
    let { id } = useParams();
    const [searchParams] = useSearchParams();

    const loanInitialState = {
        loan_amount: '',
        expected_date: '',
        tenure: '',
        interest_rate: '',
        comments: [],
        field_inspection_comments: '',
        field_inspection_pictures: [],
    };
    const initialErrorState = {
        severity: '',
        message: '',
        error: false
    }

    const loan_purpose = [
        { value: "Green Loan", label: "Green Loan" },
        { value: "Sakthi Entrepreneurship Loan", label: "Sakthi Entrepreneurship Loan" },
        { value: "Working Capital Loan", label: "Working Capital Loan" },
        { value: "Live Stock Farming Loan", label: "Live Stock Farming Loan" },
        { value: "Personal loan", label: "Personal loan" },
        { value: "Customer Durable Loan", label: "Customer Durable Loan" },
    ]
    const loanTenure = [
        // {
        //   label: '1 month',
        //   value: 1
        // },
        // {
        //   label: '3 months',
        //   value: 3
        // }, 
        {
            label: '6 months',
            value: 6
        }, {
            label: '12 months',
            value: 12
        }, {
            label: '24 months',
            value: 24
        }, {
            label: '36 months',
            value: 36
        },
    ];
    let [loan, setLoan] = useState(loanInitialState);
    let [apiError, setApiError] = useState(initialErrorState);
    const [isNewLoan] = useState(false);
    // const [isUserAdded, setIsUserAdded] = useState(false);
    const [open, setOpen] = useState(false);
    // const [userDetails, setUserDetails] = useState({});
    const [imageLoading, setImageLoading] = useState(false)
    const { handleSubmit, control, setValue } = useForm({
        defaultValues: { ...loan, interest_rate: 27 },
    });
    console.log('apiError', apiError);
    const history = useNavigate()
    useEffect(() => {
        if (!localStorage.getItem('token')) {
            history('/login')
        }
        if (searchParams.get('loan_id')) {
            getLoanView(searchParams.get('loan_id'))
        }
    }, [])
    const getLoanView = (id) => {
        services.getService(`loan/details/${id}`)
            .then(res => {
                setValue('loan_amount', parseFloat(_.get(res, 'data.loan_amount')))
                setValue('expected_date', _.get(res, 'data.expected_date'))
                setValue('tenure', parseInt(_.get(res, 'data.tenure')))
                setValue('interest_rate', parseFloat(_.get(res, 'data.interest_rate')))
                setValue('comments', _.get(res, 'data.comments'))
                setValue('field_inspection_comments', _.get(res, 'data.field_inspection_comments'))
                setValue('field_inspection_pictures', _.get(res, 'data.field_inspection_pictures'))
                setValue('loan_products', _.get(res, 'data.loan_products'))
                setLoan({
                    loan_amount: parseFloat(_.get(res, 'data.loan_amount')),

                    // expected_date: _.get(res,'data.expected_date'),
                    // tenure: parseInt(_.get(res,'data.tenure')),
                    // interest_rate: parseFloat(_.get(res,'data.interest_rate')),
                    // comments: _.get(res,'data.comments'),
                    // field_inspection_comments: _.get(res,'data.field_inspection_comments'),
                    // field_inspection_pictures: _.get(res,'data.field_inspection_pictures'),
                })
            }).catch((error) => {

            })
    }
    // const applyNewLoan = async (data) => {
    //     console.log('datadata', data);
    //     try {
    //         data.loan_status = "pending_review";
    //         let response = await services.postService(`loan/add`, data);
    //         // history('/app/loans')
    //         console.log('responseresponse', response);
    //         if (_.get(response, 'status') === true) {
    //             history(`/app/loan/details/${_.get(response, 'data.loan_id')}?type=loan`)
    //         }

    //         setImageLoading(false)
    //         throw response
    //     } catch (err) {
    //         let errors = err && err.data && err.data.results;
    //         errors && setApiError({
    //             severity: "error",
    //             message: `Validation Error :: ${Object.values(errors).flat(2).join(" ")}`,
    //             error: true
    //         })
    //         setImageLoading(false)
    //         throw err;
    //     }
    // }

    // const updateAppliedLoan = async (data) => {
    //     try {
    //         let response = await services.putService(`loan/edit/${searchParams.get('loan_id')}`, data)
    //         if (_.get(response, 'status') === true) {
    //             history(`app/loan/details/${_.get(response, 'data.loan_id')}?type=loan`)
    //         }
    //         setImageLoading(false)
    //         throw response
    //     } catch (err) {
    //         console.log('errerr', err);
    //         setApiError({
    //             severity: "error",
    //             message: `Error :: Please try again!`,
    //             error: true
    //         })
    //         setImageLoading(false)
    //         throw err;

    //     }
    // }
    const onSubmit = (data) => {
        console.log('datadataonsubmit', data);
        if (_.size(_.get(data, 'field_inspection_pictures')) > 0) {
            onProcessImage(_.get(data, 'field_inspection_pictures'), data)
        } else {
            let a = { ...data, customerId: id }
            a.comments = _.size(_.get(data, 'comments', [])) > 0 ? [_.get(data, 'comments')] : []
            a.expected_date = moment(_.get(data, 'expected_date')).format('YYYY-MM-DD')
            a.dueStartDate = moment(_.get(data, 'expected_date')).format('YYYY-MM-DD')
            console.log('aaaa', a);
            //  setImageLoading(true)
            // onFinishData(a)
        }


    };

    // const onFinishData = (details) => {
    //     let final = { ...details }
    //     if (_.get(details, 'field_inspection_pictures[0]')) {
    //         final.field_inspection_pictures = _.uniq(_.get(details, 'field_inspection_pictures'))
    //     }
    //     if (searchParams.get('loan_id')) {
    //         updateAppliedLoan(final)
    //             .then((res) => {

    //                 notification.success({
    //                     message: "Success",
    //                     description: _.get(res, 'message'),
    //                     placement: 'bottomRight'
    //                 })

    //                 // history('/app/loans')
    //             })
    //             .catch(err => {
    //                 setImageLoading(false)
    //                 if (_.get(err, 'data.results') && _.size(_.get(err, 'data.results')) > 0) {
    //                     notification.error({
    //                         message: "Error",
    //                         description: _.get(err, `data.results.${Object.keys(_.get(err, 'data.results'))[0]}[0]`),
    //                         placement: 'bottomRight'
    //                     })
    //                 }
    //                 err && setOpen(true)
    //             })
    //     } else {
    //         delete final.password;
    //         applyNewLoan(final)
    //             .then((res) => {
    //                 console.log('resres---we', res);
    //                 notification.success({
    //                     message: "Success",
    //                     description: _.get(res, 'message'),
    //                     placement: 'bottomRight'
    //                 })
    //                 res && setOpen(true)
    //                 // history('/app/loans')
    //                 setImageLoading(false)
    //             })
    //             .catch(err => {
    //                 setImageLoading(false)
    //                 if (_.get(err, 'data.results') && _.size(_.get(err, 'data.results')) > 0) {
    //                     notification.error({
    //                         message: "Error",
    //                         description: _.get(err, `data.results.${Object.keys(_.get(err, 'data.results'))[0]}[0]`),
    //                         placement: 'bottomRight'
    //                     })
    //                 }
    //                 err && setOpen(true)
    //             })
    //     }
    // }

    const handleClose = () => {
        setOpen(false);
        setApiError(initialErrorState)
    };

    const onProcessImage = (data, details) => {
        if (_.size(data) > 0) {
            _.map(data, async (value, index) => {
                if (value) {


                    // postService(value, `${index}`, details, index + 1)
                }
            })
        } else {
            // log
            let a = { ...details, customerId: id, field_inspection_pictures: [] }
            a.comments = _.size(_.get(details, 'comments', [])) > 0 ? [_.get(details, 'comments')] : []
            a.expected_date = moment(_.get(details, 'expected_date')).format('YYYY-MM-DD')
            a.dueStartDate = moment(_.get(details, 'expected_date')).format('YYYY-MM-DD')
            console.log('asdasdsa', a);
            // onFinishData(a)


        }

    }

    // const postFiles = async (f, url, k, details, imgIndex) => {

    //     const formData = new FormData();
    //     formData.append('file', f);
    //     axios({
    //         method: 'put',
    //         url: url,
    //         data: f,
    //         // params:{url:url}
    //     }).then(async (res) => {
    //         let getData;
    //         if (localStorage.getItem('filesData')) {
    //             getData = [...JSON.parse(localStorage.getItem('filesData')), k]
    //         } else {
    //             getData = [k]
    //         }
    //         console.log('getDatagetData', getData);
    //         await localStorage.setItem('filesData', JSON.stringify(getData))
    //         if (_.size(_.get(details, 'field_inspection_pictures')) === imgIndex) {
    //             let a = { ...details, customerId: id, field_inspection_pictures: JSON.parse(localStorage.getItem('filesData')) }
    //             a.comments = _.size(_.get(details, 'comments', [])) > 0 ? [_.get(details, 'comments')] : []
    //             a.expected_date = moment(_.get(details, 'expected_date')).format('YYYY-MM-DD')
    //             a.dueStartDate = moment(_.get(details, 'expected_date')).format('YYYY-MM-DD')
    //             console.log('aaaa', a);
    //             setImageLoading(true)
    //             // onFinishData(a)
    //         }




    //     }).catch((err) => {
    //         console.log('errerr', err, _.get(err, 'data.results'));

    //     })



    // }

    // const postService = async (service, key, details, imgIndex) => {
    //     console.log('serviceservice', service);
    //     let split = _.get(service, 'name') ? _.split(_.get(service, 'name'), '.')[1] : _.split(service, '.')[1]
    //     setImageLoading(true)
    //     axios({
    //         method: 'post',
    //         url: `https://1sjtwuxmcl.execute-api.ap-south-1.amazonaws.com/qa/upload-file`,
    //         data: { customerId: id, fileName: `${key}.${split}` },
    //         headers: {
    //             Accept: "application/json",
    //             "Content-Type": "application/json",
    //             Authorization: `Bearer ${localStorage.getItem('token')}`
    //         }
    //     }).then((res) => {
    //         let k = `${id}/${key}.${split}`
    //         postFiles(service, _.get(res, 'data.presignedUrl'), k, details, imgIndex)
    //         setImageLoading(false)

    //     }).catch((err) => {
    //         setImageLoading(false)

    //     })

    // }
    const breadCrumbsData = [{ name: 'Customers', path: '/app/customers' }, { name: 'Apply', path: `/app/customers/loan/add/${id}` }]

    return (<Spin tip="Loading..." spinning={imageLoading}>

        {
            isNewLoan && <SnackBar open={open} message="test" handleClose={handleClose} severity="success" />
        }
        <Helmet>
            <title>Apply Loan - Thangam Capital </title>
        </Helmet>
        <Box
            sx={{
                backgroundColor: 'background.default',
                minHeight: '100%',
                py: 3
            }}
        >
            <Container maxWidth="lg" style={{ maxWidth: 'none' }}>
                {/* <BreadcrumbsComponent data={breadCrumbsData} /> */}
                <Box sx={{ pt: 3 }}>
                    <form onSubmit={handleSubmit(onSubmit)} >
                        <Card>
                            <CardHeader
                                title="Loan Details"
                            />
                            <Divider />
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid container item spacing={2}>
                                        <FormRow control={control} name="loan_amount" label="Loan Amount" rules={UserRules.fullName} inputType="number" />
                                        <Grid item md={6} xs={10} lg={6} >
                                            <FutureDateInput name="expected_date" label="Expected Date" control={control} rules={CustomerRules.date_expected} />
                                        </Grid>
                                    </Grid>
                                    <Grid container item spacing={2}>
                                        <Grid item md={6} xs={10} lg={6} >
                                            <FormInputDropdown
                                                name="tenure"
                                                control={control}
                                                label="Tenure"
                                                options={loanTenure}
                                                rules={CustomerRules.guarantor_relationship}
                                            />
                                        </Grid>
                                        <FormRow control={control} name="interest_rate" label="Interest Rate" rules={UserRules.interset_rate} inputType="number" />
                                    </Grid>
                                </Grid>
                                <Grid container style={{ marginTop: '1px' }} spacing={2}>
                                    <Grid container item spacing={2}>
                                        <Grid item md={6} xs={10} lg={6} >
                                            <FormInputDropdown
                                                name="loan_products"
                                                control={control}
                                                label="Loan Type"
                                                options={loan_purpose}
                                                rules={CustomerRules.guarantor_relationship}
                                            /></Grid>
                                        {/* <FormRow control={control} name="purpose" label="Purpose" rules={UserRules.fullName} /> */}
                                        {/* <FormRow multiline={true} control={control} name="fieldInspectionComments" label="Field Inspection Comments" rules={UserRules.fullName} /> */}
                                    </Grid></Grid>
                                {/* <Divider variant="middle" sx={{ m: 2 }}>COMMENTS SECTION</Divider> */}
                                <Grid container spacing={2} style={{ marginTop: '1px' }}>
                                    <Grid container item spacing={2}>
                                        <Grid item
                                            md={12}
                                            xs={12}
                                            lg={12}
                                        >  <FormInputText
                                                name={`field_inspection_comments`}
                                                control={control}
                                                label="Field Inspection Comments"
                                                multiline={true}
                                                rows={5}
                                            />
                                            {/* <FormRow multiline={true} control={control} name="fieldInspectionComments" label="Field Inspection Comments" rules={UserRules.fullName} /> */}
                                        </Grid>
                                        <Grid item
                                            md={12}
                                            xs={12}
                                            lg={12}
                                        >
                                            <FormInputText
                                                name={`comments`}
                                                control={control}
                                                label="Comments"
                                                multiline={true}
                                                rows={5}
                                            />
                                        </Grid>
                                        {/* <Grid item
                                md={1}
                                xs={1}
                                lg={1}
                              >
                                <IconButton aria-label="delete" className="family-delete-button">
                                  <AddCircleIcon/>
                                </IconButton>
                              </Grid> */}
                                        {/* {
                        loan && loan.comments.map((l, index) => {
                          return (
                            <>
                              <Grid item
                                md={11}
                                xs={11}
                                lg={11}
                              >
                                <FormInputText
                                  name={`comments[${index}]`}
                                  control={control}
                                  label="Comments"
                                  multiline={true}
                                  rows={5}
                                />
                              </Grid>
                              <Grid item
                                md={1}
                                xs={1}
                                lg={1}
                              >
                                <IconButton aria-label="delete" className="family-delete-button">
                                  <AddCircleIcon />
                                </IconButton>
                              </Grid>
                            </>
                          )
                        })
                      } */}

                                    </Grid>
                                    {/*  <Grid container item spacing={2}>
                                        <Grid item md={12} xs={12} lg={12}>
                                            <ImageUpload multiple={true} name="field_inspection_pictures" label="Field Inspection Pictures" control={control} />
                                        </Grid>
                                    </Grid> */}
                                </Grid>
                            </CardContent>
                            <Divider />
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    p: 2
                                }}
                            >
                                <Button
                                    color="primary"
                                    variant="contained"
                                    component={Link}
                                    to={`/app/customers`}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    type="submit"
                                >
                                    Apply
                                </Button>
                            </Box>
                        </Card>
                    </form>
                </Box>
            </Container>
        </Box>
    </Spin>
    )
}


export default LoanDetailsLoan;