import React from "react";
import { Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import {IconButton, InputAdornment } from "@mui/material";
  import Visibility from "@material-ui/icons/Visibility";
  import VisibilityOff from "@material-ui/icons/VisibilityOff";
  import _ from 'lodash'

export const FormInputText = (props) => {
  
  const { name, control, label,setValue, rules,is_password=false,show_password,handleClickShowPassword ,onChangeText  } = props;
  let fieldRules = rules || {};
  if (rules === undefined) fieldRules.required = false;
  return (
    <Controller
      name={name}
      
      control={control}
      render={({
        field: { onChange, value },
        fieldState: { error },
      }) => (
       <TextField
        
        id={props.id}
        helperText={error ? error.message : props.helperText || null}
        size="small"
        error={!!error}
        onChange={(e)=>{
          console.log('asjhdashdjahjsdasadsa',e.target.value);
          onChange(e.target.value);
          
           props.setAddressInfo && props.setAddressInfo({ ...props.addressInfo, [name]: e.target.value}); 
        name==="password"&& _.isFunction(onChangeText)&&onChangeText(e.target.value);
        // _.isFunction(onChangeText)&&onChangeText(e.target.value)
      }}
        value={value}
        type={is_password===true ? show_password===true?'text':'password':props.inputType}
        fullWidth
        InputLabelProps={{
          shrink: true, // Keep the label at the top
          style: {
            fontSize: '1.3rem', // Adjust the label font size
            lineHeight: '24px', // Adjust line height
            // transform: 'translate(14px, -6px) scale(1)', // Ensure the label is properly positioned
          },
        }}
        label={label}
        // sx={{fontSize:'1.3rem'}}
        variant="outlined"
        disabled={props.readonly}
        multiline={props.multiline || false}
        rows={props.rows || 0}
        InputProps={{ // <-- This is where the toggle button is added.
          endAdornment:is_password===true&& (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleClickShowPassword}
              >
                {show_password ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
          sx: {
            '& .MuiOutlinedInput-notchedOutline': {
              top: '-6px', // Adjust the position of the notch to fit the label size
              legend: {
                fontSize: '1rem', // Match the font size of the notch to the label
              },
            },
          },
        }}
        onInput={e => {
           e.target.value = name==="aadharId"? e.target.value ? Math.max(0, parseInt(e.target.value)).toString().slice(0, 12):'':
           
           name==="mobileNumber"? e.target.value ? Math.max(0, parseInt(e.target.value)).toString().slice(0, 10):'': e.target.value;
        }}
      />
      )}
     
      rules={{
        ...fieldRules,
        // maxLength: 10,
      }}
      

    />
  );
};
