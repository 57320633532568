import React from "react";
import { FormControl, FormLabel, FormControlLabel, Switch } from "@mui/material";
import { Controller } from "react-hook-form";
import FormHelperText from '@mui/material/FormHelperText';

export const FormInputSwitch = ({
  name,
  control,
  label,
  rules
}) => {
  let fieldRules = rules || {};
  if (rules === undefined) fieldRules.required = false;

  return (
    <Controller
      render={(
        {
          field: { onChange, value },
          fieldState: { error },
        }) => (
        <FormControl size={"small"} fullWidth >
          <FormLabel component="legend">{label}</FormLabel>
          <FormControlLabel
            control={
              <Switch checked={value} onChange={onChange} name={name} />
            }
            label=""
          />
          <FormHelperText sx={{ ml: 2 }} error={error}>{error ? error.message : null}</FormHelperText>
        </FormControl>
      )}
      rules={{
        ...fieldRules
      }}
      control={control}
      name={name}
    />
  );
};