import { useState } from 'react';
// import PropTypes from 'prop-types';
// import moment from 'moment';
// import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TableContainer
} from '@mui/material';
// import getInitials from '../../utils/getInitials';
// import { Link } from 'react-router-dom';
// import globalConfig from '../../utils/config';

import _ from 'lodash'

// import DeleteIcon from '@mui/icons-material/Delete';
// import EditIcon from '@mui/icons-material/Edit';
// import VisibilityIcon from '@mui/icons-material/Visibility';
// import MoneyIcon from '@mui/icons-material/Money';
// import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import DialogBox from '../shared/DialogBox';
import { services } from '../services/services';
// import AddCircleTwoToneIcon from '@mui/icons-material/AddCircleTwoTone';
// import DetailsTwoToneIcon from '@mui/icons-material/DetailsTwoTone';

const EmiListResults = ({ emis, onChangePage, pagination_details, ...rest }) => {
  // const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
  const [limit] = useState(100);
  // const [page, setPage] = useState(0);
  const [open, setOpen] = useState(false);
  const [customerId, setCustomerId] = useState(0);
  // const [openAlert, setOpenAlert] = useState(false);
  console.log('loansloans', emis);
  // const handleLimitChange = (event) => {
  //   setLimit(event.target.value);
  // };

  // const handlePageChange = (event, newPage) => {
  //   setPage(newPage);
  //   onChangePage(newPage)
  // };
  // const handleDeleteCustomer = (customer_id) => {
  //   setOpen(true);
  //   setCustomerId(customer_id);
  // }
  const onConfirm = () => {
    setCustomerId('')
    setOpen(false);
    services.putService(`customer/delete/${customerId}`)
      .then(res => {
        console.log(res);
        // setOpenAlert(true);
      })
      .catch(err => err);
  }

  return (
    <Card {...rest}>
      <Box >
        <TableContainer>
          <Table stickyHeader >
            <TableHead>
              <TableRow>
                <TableCell>
                  SI.No
                </TableCell>
                <TableCell>
                  Instalment due date
                </TableCell>
                <TableCell>
                  Amount to be paid
                </TableCell>
                <TableCell>
                  Principal amount
                </TableCell>
                <TableCell>
                  Interest charges
                </TableCell>
                <TableCell>
                  Outstanding balance
                </TableCell>

              </TableRow>
            </TableHead>
            <TableBody>
              {_.size(emis) > 0 ? emis.slice(0, limit).map((emi)=>  (
                <> <TableRow
                  hover
                  key={emi.number}
                >
                  <TableCell>
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex'
                      }}
                    >

                      <Typography
                        color="textPrimary"
                        variant="body1"
                      >
                        {_.get(emi, 'number')}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    {_.get(emi, 'paymentDate')}
                  </TableCell>
                  <TableCell>
                    {_.get(emi, 'emi')}
                  </TableCell>
                  <TableCell>
                    {_.get(emi, 'principal')}
                  </TableCell>
                  <TableCell>
                    {_.get(emi, 'interest')}
                  </TableCell>
                  <TableCell>
                    {_.get(emi, 'balance')}
                  </TableCell>


                </TableRow>
                  {_.size(_.get(emis, 'list', [])) > 0 && _.size(_.get(emis, 'list', [])) === _.get(emi, 'index') && <TableRow>
                    <TableCell align="center" style={{ fontWeight: 'bold' }} colSpan={2}>Total</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>{_.get(emis, 'emi')}</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }} >{_.get(emis, 'principal')}</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }} >{_.get(emis, 'interest')}</TableCell>
                    <TableCell ></TableCell>
                  </TableRow>}</>
              )) : <TableRow ><TableCell style={{ alignItems: 'center', padding: '70px', justifyContent: 'center', textAlign: 'center', fontSize: 18, fontWeight: 'bolder', marginBottom: '44%' }} colSpan={9}>No Loans</TableCell></TableRow>}
            </TableBody>
          </Table></TableContainer>
      </Box>

      <DialogBox open={open} setOpen={setOpen} onConfirm={onConfirm} text="Delete the Customer, Are you sure?" />
    </Card>
  );
};

export default EmiListResults;
