import { useState } from 'react';
// import PropTypes from 'prop-types';
// import moment from 'moment';
// import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Chip,
  Stack,
  TableContainer,
} from '@mui/material';
import getInitials from '../../utils/getInitials';
// import { Link } from 'react-router-dom';
import globalConfig from '../../utils/config';

import _ from 'lodash'
import PaymentIcon from '@mui/icons-material/Payment';
// import DeleteIcon from '@mui/icons-material/Delete';
// import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
// import MoneyIcon from '@mui/icons-material/Money';
// import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import DialogBox from '../shared/DialogBox';
import { services } from '../services/services';
// import AddCircleTwoToneIcon from '@mui/icons-material/AddCircleTwoTone';
// import DetailsTwoToneIcon from '@mui/icons-material/DetailsTwoTone';
// import { Helmet } from 'react-helmet';

const LoansListResults = ({ loans, onChangePage, pagination_details, type_loan = "loan", ...rest }) => {

  console.log('pagination_detailspagination_details >>>', pagination_details);
  // const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [open, setOpen] = useState(false);
  const [customerId, setCustomerId] = useState(0);
  // const [openAlert, setOpenAlert] = useState(false);
  const [page1, setPage1] = useState(0);
  console.log('loansloans', loans);
  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    console.log('event.target.valueevent.target.value', event.target.value);
    onChangePage(page1, event.target.value)
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    setPage1(page < newPage ? newPage + 10 : newPage - 10)
    onChangePage(page < newPage ? newPage : newPage, limit)
  };
  // const handleDeleteCustomer = (customerId) => {
  //   setOpen(true);
  //   setCustomerId(customerId);
  // }
  const onConfirm = () => {
    setCustomerId(0)
    setOpen(false);
    services.putService(`customer/delete/${customerId}`)
      .then(res => {
        console.log(res);
        // setOpenAlert(true);
      })
      .catch(err => err);
  }

  return (
    <Card {...rest}>

      <Box>


        <TableContainer style={{ maxHeight: "550px", overflowX: 'auto' }}>
          <Table stickyHeader >
            <TableHead >
              <TableRow>
                <TableCell>
                  Name
                </TableCell>
                <TableCell>
                  Aadhar
                </TableCell>
                <TableCell>
                  Customer Id
                </TableCell>
                {/* <TableCell>
                  Email
                </TableCell> */}
                <TableCell>
                  Loan Id
                </TableCell>
                <TableCell>
                  Loan Amount
                </TableCell>
                <TableCell>
                  Status
                </TableCell>

                {/* <TableCell>
                  Expected Date
                </TableCell> */}
                <TableCell>
                  Tenure
                </TableCell>
                {/* <TableCell>
                  Interest Rate
                </TableCell> */}
                <TableCell>
                  Disbursed On
                </TableCell>
                <TableCell>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {_.size(loans) > 0 ? loans.slice(0, limit).map((loan) => (
                <TableRow
                  hover
                  key={loan.id}
                >
                  <TableCell>
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex'
                      }}
                    >
                      <Avatar
                        src={loan.avatarUrl}
                        sx={{ mr: 2 }}
                      >
                        {getInitials(loan.name)}
                      </Avatar>
                      <Typography
                        color="textPrimary"
                        variant="body1"
                      >
                        {loan.name}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    {loan.aadharId}
                  </TableCell>
                  <TableCell>
                    {loan.customerId}
                  </TableCell>
                  <TableCell>
                    {loan.loan_id}
                  </TableCell>
                  <TableCell>
                    {_.get(loan, 'loanAmount', 0)}
                  </TableCell>
                  <TableCell>
                    {globalConfig.loanAttributes[loan.status]}
                  </TableCell>

                  <TableCell>
                    {loan.tenure}
                  </TableCell>
                  <TableCell>
                    {loan.disbursed_date}
                  </TableCell>
                  <TableCell>
                    <Stack spacing={1} >
                      <Chip label="Details" size="small" component="a" href={`loan/details/${loan.loan_id}?type=${type_loan}`} clickable icon={<VisibilityIcon />} color="secondary" />
                      <Chip label="EMI" size="small" component="a" href={`emi/${loan.loan_id}?type=${type_loan}`} clickable icon={<VisibilityIcon />} color="secondary" />
                      <Chip label="PAY" size="small" component="a" href={`payment/${loan.loan_id}?type=${type_loan}`} clickable icon={<PaymentIcon />} color="secondary" />
                    </Stack>
                  </TableCell>
                </TableRow>
              )) : <TableRow ><TableCell style={{ alignItems: 'center', padding: '70px', justifyContent: 'center', textAlign: 'center', fontSize: 18, fontWeight: 'bolder', marginBottom: '44%' }} colSpan={9}>No Loans</TableCell></TableRow>}

            </TableBody>
          </Table></TableContainer>
      </Box>
      {_.size(loans) > 0 &&
        <TablePagination
          component="div"
          count={_.get(pagination_details, 'total', 10)}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={10}
          rowsPerPageOptions={[5, 10, 25]}
        />}
      <DialogBox open={open} setOpen={setOpen} onConfirm={onConfirm} text="Delete the Customer, Are you sure?" />
    </Card>
  );
};

export default LoansListResults;
