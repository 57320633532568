import React, { useEffect, useState } from 'react';
import {
  Button,
  Box,
  Card,
  CardHeader,
  Divider,
  CardContent,
  Grid
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { CustomerRules } from 'src/utils/validationsConfig';
import { FormInputText } from '../shared/FormInputText';
import { PastInputDate } from '../shared/FormInputDate';
import { services } from '../services/services';
import SnackBar from '../shared/SnackBar';
import _ from 'lodash'
import './style.css'
import { useNavigate } from 'react-router';
import { FormInputDropdown } from '../shared/FormInputDropdown';
function FormRow(props) {
  const { control, name, label, rules, inputType, readonly } = props;
  return (
    <React.Fragment>
      <Grid item
        md={6}
        xs={10}
        lg={4}
      >
        <FormInputText
          name={name}
          control={control}
          label={label}
          rules={rules}
          inputType={inputType || "text"}
          readonly={readonly || false}
          helperText={props.helperText || null}
        />
      </Grid>
    </React.Fragment>
  );
}

const CustomerAccountInfo = ({
  handleBack,
  handleNext,
  activeStep,
  steps,
  setCustomerInfo,
  customerInfo,
  isNextActive
}) => {

  let asdasda = {
    "firstName": "Iyyappan",
    "lastName": "Sivakumar",
    "mobileNumber": "7010053680",
    "email": "test@gmail.com",
    "aadharId": "787878787878",
    "dob": "1997-07-25",
    "gender": "male",
    "spouseName": "Test",
    "fathersName": "Test",
    "mothersName": "TEst",
    "education": "TEst",
    "maritialStatus": "single",
    "religion": "hindu",
    "caste": "oc",
    "physicallyChallenged": "yes",
    "occupation": "government",
    "residenceType": "own",
    "permenant_number": "12",
    "permenant_address1": "Test",
    "permenant_address2": "Test",
    "permenant_city_village": "Test",
    "permenant_district": "Test",
    "permenant_state": "puduchery",
    "address_same": true,
    "present_number": "12",
    "present_address1": "Test",
    "present_address2": "Test",
    "present_city_village": "Test",
    "present_district": "Test",
    "present_state": "puduchery",
    "aadhaar_proof_url": "234234234234/aadhaar.jpg",
    "pan_proof_url": "234234234234/pan.jpg",
    "driving_license_proof_url": "234234234234/driving_license.jpg",
    "passport_proof_url": "234234234234/passport.jpg",
    "voters_id_proof_url": "234234234234/voters_id.jpg",
    "family_card_proof_url": "234234234234/family_card.jpg",
    "bank_book_proof_url": "234234234234/bank_book.jpg",
    "others_proof_url": "234234234234/others.jpg",
    "net_income": "10000",
    "expenses": "10000",
    "bankName": "Test",
    "branchName": "Test",
    "accountNumber": "1234567",
    "ifscCode": "43434dfd",
    "guarantor_name": "Test",
    "guarantor_fathers_spouseName": "Test",
    "guarantor_dob": "1987-12-12",
    "guarantor_gender": "male",
    "guarantor_relationship": "husband",
    "familyDetails": [
      { "name": "test", "age": "12", "relationship": "mother", "income": "1000", "loan_company": "tesdty" }]
  }
  useEffect(() => {
    let details = []
    // setCustomerInfo({...asdasda})
    _.map(asdasda, (v, i) => {
      details.push(i)
    })
    let a = [
      "aadharId",
      "accountNumber",
      "address_same",
      "branchName",
      "bankName",
      "caste",
      "dob",
      "education",
      "email",
      "expenses",
      "fathersName",
      "firstName",
      "gender",
      "guarantor_dob",
      "guarantor_fathers_spouseName",
      "guarantor_gender",
      "guarantor_name",
      "guarantor_relationship",
      "ifscCode",
      "lastName",
      "maritialStatus",
      "mobileNumber",
      "mothersName",
      "net_income",
      "occupation",
      "aadhaar_proof_url",
      "pan_proof_url",
      "dirving_license_proof_url",
      "passport_proof_url",
      "voters_id_proof_url",
      "family_card_proof_url",
      "bank_book_proof_url",
      "others_proof_url",
      "permenant_address1",
      "permenant_address2",
      "permenant_city_village",
      "permenant_district",
      "permenant_number",
      "permenant_state",
      "physicallyChallenged",
      "present_address1",
      "present_address2",
      "present_city_village",
      "present_district",
      "present_number",
      "present_state",
      "religion",
      "residenceType",
      "spouseName",
      "familyDetails"
    ]
    let b = [
      "firstName",
      "lastName",
      "mobileNumber",
      "email",
      "aadharId",
      "dob",
      "gender",
      "spouseName",
      "fathersName",
      "mothersName",
      "education",
      "maritialStatus",
      "religion",
      "caste",
      "physicallyChallenged",
      "occupation",
      "residenceType",
      "permenant_number",
      "permenant_address1",
      "permenant_address2",
      "permenant_city_village",
      "permenant_district",
      "permenant_state",
      "address_same",
      "present_number",
      "present_address1",
      "present_address2",
      "present_city_village",
      "present_district",
      "present_state",
      "aadhaar_proof_url",
      "pan_proof_url",
      "driving_license_proof_url",
      "passport_proof_url",
      "voters_id_proof_url",
      "family_card_proof_url",
      "bank_book_proof_url",
      "others_proof_url",
      "net_income",
      "expenses",
      "bankName",
      "branchName",
      "accountNumber",
      "ifscCode",
      "guarantor_name",
      "guarantor_fathers_spouseName",
      "guarantor_dob",
      "guarantor_gender",
      "guarantor_relationship",
      "familyDetails"
    ]
    console.log('deferent', _.difference(b, a));

  }, [])


  const initialErrorState = {
    severity: '',
    message: '',
    error: false
  }
  const customerAccountInitialState = {
    firstName: customerInfo && customerInfo.firstName ? customerInfo.firstName : '',
    lastName: customerInfo && customerInfo.lastName ? customerInfo.lastName : '',
    mobileNumber: customerInfo && customerInfo.mobileNumber ? customerInfo.mobileNumber : '',
    email: customerInfo && customerInfo.email ? customerInfo.email : '',
    dob: customerInfo && customerInfo.dob ? customerInfo.dob : '',
    gender: customerInfo && customerInfo.gender ? customerInfo.gender : '',
    aadharId: customerInfo && customerInfo.aadharId ? customerInfo.aadharId : ''
  };

  const genderList = [
    {
      label: "Male",
      value: "male"
    },
    {
      label: "Female",
      value: "female"
    },
    {
      label: "Transgender",
      value: "transgender"
    }
  ]
  const [accountInfo] = useState(customerAccountInitialState);
  const [apiError, setApiError] = useState(initialErrorState);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const { handleSubmit, control, reset } = useForm({
    defaultValues: { ...accountInfo }
  });

  const onSubmit = (data) => {
    console.log('datadatadata', data);
    console.log(customerInfo)
    if (Object.keys(customerInfo).length === 0) {
      // let queryParams = `aadharId=${data.aadharId}`
      services.postService(`customer/search`, { aadharId: data.aadharId })
        .then(res => {
          console.log('datsdsa', res)
          if (res?.length === 0 || res.total === 0) {
            let date = data?.dob?.toISOString().split("T")[0];
            data.dob = date;
            console.log('datacomvert', data);
            setCustomerInfo({
              ...customerInfo, ...data
            });
            handleNext(false);
          } else {
            setOpen(true);
            setApiError({
              severity: "error",
              message: `Validation Error :: Aadhar number & Email already exists.`,
              error: true,
              id: _.get(res, 'data[0].id')
            })
          }
        })
        .catch(err => {
          if (!localStorage.getItem('token')) {
            navigate('/login');
          }
        })
    } else {
      setCustomerInfo({
        ...customerInfo, ...data
      });
      handleNext(false);
    }
  }

  const handleClose = () => {
    setOpen(false);
    setApiError(initialErrorState)
  };

  React.useEffect(() => {
    !apiError.error && reset(customerInfo)
  }, [customerInfo])


  return (
    <>
      {
        apiError.error && <div>
          <SnackBar open={open} id={_.get(apiError, 'id')} message={apiError.message} handleClose={handleClose} severity={apiError.severity} />
        </div>
      }
      <form onSubmit={handleSubmit(onSubmit)} >
        <Card>
          <CardHeader
            title="Account Information"
          />
          <Divider />
          <CardContent>
            <Grid container spacing={2}>
              <Grid container item spacing={2} >
                <FormRow control={control} name="firstName" label="First Name" rules={CustomerRules.firstName} />
                <FormRow control={control} name="lastName" label="Last Name" rules={CustomerRules.lastName} />
              </Grid>
              <Grid container item spacing={2} >
                <FormRow control={control} inputType="number" name="mobileNumber" label="Mobile number" rules={CustomerRules.mobileNumber} />
                <FormRow control={control} name="email" label="Email" rules={CustomerRules.email} inputType="email" />
              </Grid>
              <Grid container item spacing={2} >
                <FormRow control={control} name="aadharId" label="Aadhar ID" inputType="number" rules={CustomerRules.aadharId} />

                <Grid item
                  md={6}
                  xs={10}
                  lg={4}
                >

                  <PastInputDate control={control} name="dob" label="DOB" rules={CustomerRules.dob} required={true} />    </Grid>
              </Grid>

              <Grid container item spacing={2} >
                <Grid item
                  md={6}
                  xs={10}
                  lg={4}
                >
                  <FormInputDropdown name="gender" control={control} label="Gender" options={genderList} rules={CustomerRules.gender} />
                  {/* <FormInputRadio
                    name="gender"
                    control={control}
                    label="Gender"
                    options={genderList}
                    rules={CustomerRules.gender}
                  /> */}
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', px: 2, pb: 2 }}>
            <Button
              variant="contained"
              sx={{ mt: 2, ml: 1 }}
              href="/app/customers"
              style={{ height: '36px' }}
            >
              Cancel
            </Button>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', px: 2, pb: 2 }}>
              {activeStep !== 0 && (
                <Button onClick={handleBack} sx={{ mt: 2, ml: 1 }}>
                  Previous
                </Button>
              )}

              <Button
                variant="contained"
                sx={{ mt: 2, ml: 1 }}
                type="submit"
              >
                {activeStep === steps.length - 1 ? 'Finish' : 'Save & Next'}
              </Button>
            </Box>
          </Box>
        </Card>
      </form>
    </>
  );
};


export default CustomerAccountInfo;
