import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography
} from '@mui/material';
// import FacebookIcon from '../icons/Facebook';
// import GoogleIcon from '../icons/Google';
import { services } from 'src/components/services/services';
// import bcrypt from 'bcryptjs'
import { notification } from 'antd';

const Login = () => {
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>Login - Thangam Capital</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              username: '',
              password: ''
            }}
            validationSchema={Yup.object().shape({
              username: Yup.string().max(255).required('Username is required'),
              password: Yup.string().max(255).required('Password is required')
            })}
            onSubmit={(values) => {
              // const salt = bcrypt.genSaltSync(10);
              // values = {
              //   ...values, password: bcrypt.hashSync(values.password, salt)
              // }
              console.log(values);
              services.postService(`user/login`, values)
                .then(res => {
                  console.log('resres', res);
                  localStorage.setItem('token', res.token);
                  if (res.status) {
                    navigate('/app/customers', { replace: true });
                  }
                })
                .catch(err => {
                  console.log('err', err);
                  if (err?.response?.status === 400) {
                    notification.error({
                      message: 'Error',
                      description: 'Invalid username or password',
                      placement: 'topRight'
                    });
                  } else {
                    notification.error({
                      message: 'Error',
                      description: err?.data?.error || 'Invalid username or password',
                      placement: 'topRight'
                    });
                  }
                  if (!localStorage.getItem('token')) {
                    navigate('/login');
                  }
                  if (err?.data?.error) {
                    notification.error({
                      message: 'Error',
                      description: err?.data?.error,
                      placement: 'topRight'
                    })
                  }
                });
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box sx={{ mb: 3 }}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    Sign in
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.username && errors.username)}
                  fullWidth
                  helperText={touched.username && errors.username}
                  label="Username"
                  margin="normal"
                  name="username"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.username}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Box sx={{ py: 2 }}>
                  <Button
                    color="primary"
                    // disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Sign in
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  );
};

export default Login;
