import React from "react";
import { Autocomplete, FormControl, InputLabel, MenuItem, OutlinedInput, Select, TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import FormHelperText from '@mui/material/FormHelperText';

/*
Sample for calling FormInputDropdown
<FormInputDropdown
  name="test"
  control={control}
  label="test"
  options={options}
  rules={rules.test}
/>
https://github.com/Mohammad-Faisal/react-hook-form-material-ui/blob/master/src/form-components/FormInputMultiCheckbox.tsx
https://blog.logrocket.com/using-material-ui-with-react-hook-form/
*/

export const FormAutoCompleteDropdown = ({
  name,
  control,
  label,
  options,
  rules,
  readonly
}) => {
  let fieldRules = rules || {};
  if (rules === undefined) fieldRules.required = false;

  const generateSingleOptions = () => {
    return options.map((option) => {
      return (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      );
    });
  };

  return (
    <Controller
      render={(
        {
          field: { onChange, value },
          fieldState: { error },
        }) => (
        <FormControl  size={"small"} fullWidth error={error} disabled={readonly} >
             <Autocomplete
             style={{height:'40px'}}
      options={options}
      getOptionLabel={(option) => option.label}
disableCloseOnSelect={false}
disableClearable={false}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          error={!!error}
       //    helperText={error ? error.message : null}
         
            inputProps={{
              ...params.inputProps,
              style: {
                height: '40px', // Set height of the select box
              //   padding: '10px', // Adjust padding to match the height
                boxSizing: 'border-box',
                
              },
            }}
            sx={{
              '& .MuiInputBase-root': {
                height: '40px', // Set the total height of the input
                paddingTop:'2px',
                paddingRight: '0px',
              },
            }}
            InputLabelProps={{
              shrink: true, // Keeps label shrunk
              style: {
                     fontSize: '1.3rem',
                     lineHeight: '24px',
                     transform: 'translate(14px, -6px) scale(0.75)', // Adjust position and size
                     backgroundColor: '#fff', // Background color to avoid label overlap
                     padding: '0 4px', // Padding around label
                   },
            }}
       //    style={{lineHeight:'24px'}}
         
        />
      )}
//       label={label}
value={options.find((option) => option.value === value) || null}
      onChange={(event, newValue) => {
       if(newValue?.value){
              onChange(newValue?.value)
       }else{
              onChange(null)  
       }
       
      }}
           
           
    />
         
          <FormHelperText sx={{ ml: 2 }} error={error}>{error ? error.message : null}</FormHelperText>
        </FormControl>
      )}
      rules={{
        ...fieldRules
      }}
      control={control}
      name={name}
    />
  );
};