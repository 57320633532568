import React from 'react';
import { Controller } from "react-hook-form";
import { useState } from 'react';
import _ from 'lodash'
import { useEffect } from 'react';

export const ProofImageUpload = (props) => {
  const hiddenFileInput = React.useRef(null);
  const [uploads, setUploads] = useState({}); // State to track multiple uploads

  const { name, control, rules, disabled = false, id } = props;
  let fieldRules = rules || {};
  if (rules === undefined) fieldRules.required = false;

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  useEffect(() => {
    if (_.get(props, 'value')) {
      setUploads(prevState => ({
        ...prevState,
        [id]: _.get(props, 'value')
      }));
    }
  }, [id, props.value]);

  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({
          field: { onChange },
          fieldState: { error },
        }) => (
          <div style={{ marginLeft: '23px' }}>
            {uploads[id]?.file?.name && uploads[id]?.type_name === name && id === uploads[id]?.id ? (
              <div className="row">
                <div>{uploads[id]?.file?.name}</div>
                <i className="fas fa-edit" onClick={handleClick} />
              </div>
            ) : (
              <button
                type="button"
                onClick={handleClick}
                style={{
                  height: '39px',
                  border: "1px solid #999999",
                  backgroundColor: disabled ? "#cccccc" : null,
                  color: disabled ? "#666666" : null,
                  borderRadius: '7px',
                  width: '60%'
                }}
              >
                <i className="fas fa-file-upload" />
                <span> Upload</span>
              </button>
            )}
            <input
              ref={hiddenFileInput}
              accept="image/*"
              style={{ display: 'none' }}
              type={'file'}
              name="Select Image"
              onChange={(event) => {
                if (!disabled) {
                  const file = event.target.files[0];
                  setUploads(prevState => ({
                    ...prevState,
                    [id]: { file, type_name: name, id }
                  }));
                  onChange(file);
                  props.newFieldTru(file);
                }
              }}
            />
          </div>
        )}
        rules={fieldRules}
      />
    </>
  );
};
