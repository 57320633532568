const Logo = (props) => (
  <>
  <img
    alt="Logo"
    src="/static/logo.svg"
    {...props}
  />
  <div className="logoText">Thangam Capital</div>
  </>
);

export default Logo;
