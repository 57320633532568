import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from 'react-helmet';
import {
  Box,
  Card,
  CardHeader,
  Divider,
  CardContent,
  Grid,
  Container,
  Button
} from '@mui/material';
// import { saveAs } from 'file-saver';

import { useForm } from 'react-hook-form';
import { FormInputText } from '../components/shared/FormInputText';
import { UserRules } from '../utils/validationsConfig';
// import bcrypt from 'bcryptjs'
import SnackBar from 'src/components/shared/SnackBar';
import { UserSuccessMessage } from 'src/utils/ErrorMessages';
import { services } from 'src/components/services/services';
import { Link } from 'react-router-dom';
import BreadcrumbsComponent from 'src/components/Breadcrumbs';
import _ from 'lodash'
import styled from '@emotion/styled';
import moment from 'moment';


function FormRow(props) {
  const { control, name, label, rules, inputType, readonly, is_password = false, show_password = false, handleClickShowPassword, onChange } = props;
  return (
    <React.Fragment>
      <Grid item
        md={6}
        xs={10}
        lg={4}
      >
        <FormInputText
          name={name}
          control={control}
          label={label}
          rules={rules}
          inputType={inputType || "text"}
          readonly={readonly || false}
          show_password={show_password}
          is_password={is_password}
          handleClickShowPassword={handleClickShowPassword}
          onChangeText={(value) => onChange(value, name)}
        />
      </Grid>
    </React.Fragment>
  );
}



const AddEditUser = () => {
  // const {watch} =useForm()
  let { id } = useParams();
  // const[pwd,setPwd]=useState('')
  const userInitialState = {
    "principal": '',
    "interest": '',
    "penalty": ''
  };
  const initialErrorState = {
    severity: '',
    message: '',
    error: false
  }
  let [userDetails, setUserDetails] = useState(userInitialState);
  // let [rolesList, setRolesList] = useState({});
  let [apiError, setApiError] = useState(initialErrorState);
  const [isUserAdded, setIsUserAdded] = useState(false);
  const [open, setOpen] = useState(false);
  // const [showPassword, setShowPassword] = useState(false);
  // const handleClickShowPassword = () => setShowPassword(!showPassword);
  // const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const history = useNavigate()
  useEffect(() => {
    if (!localStorage.getItem('token')) {
      history('/login')
    }
  }, [])
  const { setValue, handleSubmit, control } = useForm({
    defaultValues: { ...userDetails },

  });

  useEffect(() => {
    getUser()
  }, []);

  // const getRoles = async () => {
  //   try {
  //     let roles = await services.getService(`loan/current-due/${id}`);
  //     console.log('rolesroles',roles);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }

  const getUser = async () => {
    try {
      let loan_due = await services.getService(`loan/current-due/${id}`);
      console.log('rolesroles', _.get(loan_due, 'data'));
      if (_.get(loan_due, 'data')) {
        console.log('mfbdnfbsnbfns');
        setValue('penalty', _.get(loan_due, 'data.penalty'))
        setValue('interest', _.get(loan_due, 'data.interest'))
        setValue('principal', _.get(loan_due, 'data.principal'))
      }
    } catch (err) {
      console.log(err);
    }
  }

  // const createNewUser = async (data) => {
  //   const salt = bcrypt.genSaltSync(10);
  //   data = {
  //     ...data
  //   }
  //   // bcrypt.hashSync(data.password, salt)
  //   try {
  //     let response = await services.postService(`user/add`, data);
  //     response.status && setIsUserAdded(true) && setUserDetails(userInitialState);
  //     return response.status;
  //   } catch (err) {
  //     let errors = err && err.data && err.data.results;
  //     errors && setApiError({
  //       severity: "error",
  //       message: `Validation Error :: ${Object.values(errors).flat(2).join(" ")}`,
  //       error: true
  //     })
  //     throw err;
  //   }
  // }

  const loanDuePay = async (data) => {

    let details = {
      loanID: id,
      dateofPayment: moment().format('DD/MM/YYYY'), // If not passed use current date
      items: [ // more than one is optional
        {
          "type": "repay_principal",
          "amount": _.get(data, 'principal'),
        },
        {
          "type": "interest",
          "amount": _.get(data, 'interest')
        },
        {
          "type": "penalty",
          "amount": _.get(data, 'penalty')
        }
      ]
    }
    try {
      let response = await services.postService(`/load/payment/${id}`, details);
      console.log('responseresponse', response);
      response.status && setIsUserAdded(true) && setUserDetails(userInitialState);
      return response.status;
    } catch (err) {
      setApiError({
        severity: "error",
        message: `Error :: Please try again!`,
        error: true
      })
    }
  }





  const onSubmit = (data) => {
    if (id) {
      loanDuePay(data)
        .then(res => {

          history('/app/loans')
        }

        )
        .catch(err => err && setOpen(true))
    }
  };
  // const props = {
  //   name: 'file',

  //   onChange(info) {
  //     if (info.file.status !== 'uploading') {
  //       console.log(info.file, info.fileList);
  //       let photo =''
  //       const reader = new FileReader();
  //                       reader.readAsDataURL(_.get(info,'file.originFileObj'));
  //                       reader.addEventListener("load", () => {
  //                         if (reader.result) {

  //                           photo = reader.result;
  //                           console.log('photophoto',reader.result);
  //                           // saveAs(reader.result)
  //                         }
  //                       });

  //     }
  //     if (info.file.status === 'done') {
  //       message.success(`${info.file.name} file uploaded successfully`);
  //     } else if (info.file.status === 'error') {
  //       message.error(`${info.file.name} file upload failed.`);
  //     }
  //   },
  //   showUploadList: {
  //     showRemoveIcon:false,
  //     showDownloadIcon: true,
  //     downloadIcon: (value)=>console.log('valuevalue',value),


  //   }
  // };
  const handleClose = () => {
    setOpen(false);
    setApiError(initialErrorState)
  };
  const breadCrumbsData = [{ name: 'Users', path: '/app/users' }, { name: 'Payment', path: `/app/payment/${id}` }]
  //   const dummyRequest = ({ file, onSuccess }) => {
  //     setTimeout(() => {
  //         onSuccess("ok");
  //     }, 50);
  // };

  // const downlodFile =(file)=>{
  //   let photo =''
  //         const reader = new FileReader();
  //                         reader.readAsDataURL(_.get(file,'originFileObj'));
  //                         reader.addEventListener("load", () => {
  //                           if (reader.result) {

  //                             photo = reader.result;
  //                             console.log('photophoto',reader.result);
  //                             saveAs(reader.result)
  //                           }
  //                         });
  // }

  // const onChangeHandler =(value)=>{
  //     console.log('checkox',value);
  // }
  const StyledCardHeader = styled(CardHeader)(({ theme }) => ({
    backgroundColor: '#038dd7',
    color: '#fffff',
    marginBottom: '23px'

  }))
  return <>
    {
      isUserAdded && <SnackBar open={open} message={UserSuccessMessage.ADD} handleClose={handleClose} severity="success" />
    }
    {
      apiError.error && <SnackBar open={open} message={apiError.message} handleClose={handleClose} severity={apiError.severity} />
    }
    {
      <>
        <Helmet>
          <title>{id ? 'Edit' : 'Add'} User - Thangam Capital </title>
        </Helmet>
        <Box
          sx={{
            backgroundColor: 'background.default',
            minHeight: '100%',
            py: 3
          }}
        >
          <Container maxWidth="lg">
            <BreadcrumbsComponent data={breadCrumbsData} />
            {/* <Upload  onDownload={(fie)=>downlodFile(fie)} {...props} customRequest={dummyRequest}>
    <Button icon={<UploadOutlined />}>Click to Upload</Button>
  </Upload> */}
            <Box sx={{ pt: 3 }}>

              <form onSubmit={handleSubmit(onSubmit)} >
                <Card>
                  <StyledCardHeader title="Loan Ledger" />
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid container item spacing={1}>
                        <Grid item md={12} xs={12} lg={12} >
                          <FormRow control={control} name="principal" rules={UserRules.username} label="Principal Amount" readonly={true} />

                        </Grid>
                      </Grid>
                      <Grid container item spacing={1}>
                        <Grid item md={12} xs={12} lg={12} >
                          <FormRow control={control} name="interest" rules={UserRules.username} label="Interest" readonly={true} />

                        </Grid>
                      </Grid>
                      <Grid container item spacing={2}>
                        <Grid container item spacing={1}>
                          <Grid item md={12} xs={12} lg={12} >
                            <FormRow control={control} name="penalty" rules={UserRules.username} label="Penalty" readonly={true} />

                          </Grid>
                        </Grid>
                      </Grid>

                    </Grid>
                  </CardContent>
                  <Divider />
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      p: 2
                    }}
                  >
                    <Button
                      color="primary"
                      variant="contained"
                      component={Link}
                      to={`/app/users`}
                    >
                      Cancel
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      type="submit"
                    >
                      {'Pay'}
                    </Button>
                  </Box>
                </Card>
              </form>
            </Box>
          </Container>
        </Box>
      </>
    }
  </>;
}

export default AddEditUser;
