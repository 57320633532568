// src/store.js
import { configureStore } from '@reduxjs/toolkit';
import globalConfigurationReducer from './globalConfigurationSlice';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';

const persistConfig = {
  key: 'globalConfig',
  storage, // This saves to localStorage by default
};

const persistedReducer = persistReducer(persistConfig, globalConfigurationReducer);

export const store = configureStore({
  reducer: {
    globalConfiguration: persistedReducer,
  },
});

export const persistor = persistStore(store);