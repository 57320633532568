import React, { useEffect, useState } from 'react'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Box, Container, Card, Typography, CardContent, Paper, Grid, Divider, List, ListItemButton, ListItemIcon, ListItemText, Chip, Button } from '@mui/material';
import { Helmet } from 'react-helmet';
import { useParams, useLocation } from "react-router-dom";
import { services } from 'src/components/services/services';
import { CardHeader } from '@mui/material';
import PhoneIphoneTwoToneIcon from '@mui/icons-material/PhoneIphoneTwoTone';
import EmailTwoToneIcon from '@mui/icons-material/EmailTwoTone';
import FmdGoodTwoToneIcon from '@mui/icons-material/FmdGoodTwoTone';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import globalConfig from '../utils/config';
import EditLocationAltTwoToneIcon from '@mui/icons-material/EditLocationAltTwoTone';
import { useNavigate } from 'react-router-dom';
import './style.css'
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { Modal, Image } from "antd"
import _ from 'lodash'
import axios from "axios"
import DetailsTwoToneIcon from '@mui/icons-material/DetailsTwoTone';
import EditIcon from '@mui/icons-material/Edit';
import BreadcrumbsComponent from 'src/components/Breadcrumbs';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { CustomerRules } from 'src/utils/validationsConfig';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: 'rgb(255, 255, 255)',
  color: 'rgb(97, 97, 97)',
  transition: 'none',
  backgroundImage: 'none',
  borderRadius: '8px',
  overflow: 'hidden',
  border: '1px solid rgb(227, 242, 253)',
  // transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  boxShadow: "none",
  '&:hover': {
    // border: '2px solid rgb(227, 242, 253)',
    // boxShadow: 'rgb(32 40 45 / 8%) 0px 2px 14px 0px'
  }
}))

const StyledCardHeader = styled(CardHeader)(({ theme }) => ({
  backgroundColor: '#EDE7F6',
  color: '#5D35B1',
  // fontsize:'23px'
}))

const TabelHeaderCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: '#EDE7F6',
  color: '#5D35B1'
}))

const MuiTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    borderBottom: "none"
  }
}));

const BoldTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    borderBottom: "none",
    fontWeight: '500'
  }
}));

function TabPanel(props) {
  const { children, value, index, rules, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const CustomerDetails = () => {
  const { search } = useLocation();
  let query = new URLSearchParams(search);
  let tabValue = query.get("tab") === 'loan' ? 6 : 0;
  let { id } = useParams();
  const [value, setValue] = useState(tabValue);
  const [details, setDetails] = useState({})
  const [PrevimageModal, setPrevImageModal] = useState(false)
  const [Previmage, setPrevImage] = useState('')

  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    if (!localStorage.getItem('token')) {
      navigate('/login')
    }
  }, [])
  useEffect(() => {
    services.getService(`customer/details/${id}`)
      .then((res) => {
        let data = res;
        setDetails({
          ...details, data: data, loan_details: []
        })
        //         services.getService(`loan/list?customerId=${id}`)
        //           .then(res => setDetails({
        //             ...details, data: data, loan_details: res.data
        //           }))
        //           .catch(err => {
        // if(!localStorage.getItem('token')){
        //  navigate('/login');
        // }
        // })
      })
      .catch(err => {
        if (!localStorage.getItem('token')) {
          navigate('/login');
        }
      })
  }, [])

  const {
    data,
    loan_details
  } = details;
  console.log('datadata', data);
  // console.log("data.guarantor_details.name", data.guarantor_details[0].name);
  // let proofs_data = [
  //   "aadhaar_proof_url",
  //   'pan_proof_url',
  //   'dirving_license_proof_url',
  //   'passport_proof_url',
  //   'voters_id_proof_url',
  //   'family_card_proof_url',
  //   'bank_book_proof_url',
  //   'others_proof_url',
  // ]

  const viewImage = (value, type) => {
    console.log('value,type', value, type);
    axios({
      method: 'get',
      url: `https://1sjtwuxmcl.execute-api.ap-south-1.amazonaws.com/qa/read-file`,
      data: { "fileKey": value },
      params: { "fileKey": value },
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }

    }).then((res) => {
      console.log('View presignedUrl', res);
      if (type === 'down') {
        fetch(_.get(res, 'data.presignedUrl'))
          .then(response => {
            response.arrayBuffer().then(function (buffer) {
              const url = window.URL.createObjectURL(new Blob([buffer]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", value); //or any other extension
              document.body.appendChild(link);
              link.click();
            });
          })
          .catch(err => {
            console.log(err);
          });
      } else {
        setPrevImage(_.get(res, 'data.presignedUrl'))
        setPrevImageModal(true)

      }

    })
  }

  const clickEmail = (email) => {
    // window.location.href = "mailto:abc@mail.com";
    window.open(`mailto:${email}`, '_blank')
    // email.href = "mailto:abc@mail.com";
    // email.click();
  }
  let breadCrumbsData = [{ name: 'Customers', path: '/app/customers' }, { name: 'Details', path: `/app/customers/details/${id}` }]
  // <Grid item xs={4}>
  // <Grid xs={12}>
  //   <Item style={{flexDirection:'row'}}><div style={{flexDirection:'row'}}>
  //     <div>Name :</div>
  //     <div>{family.name}</div>
  //     </div></Item>
  //     <Item><div>
  //     <div>Age :</div>
  //     <div>{family.age}</div>
  //     </div></Item>
  //     <Item><div>
  //     <div>Gender :</div>
  //     <div>{family?.gender?family?.gender:'--'}</div>
  //     </div></Item>
  //     <Item><div>
  //     <div>Address :</div>
  //     <div>{family?.address?family?.address:'--'}</div>
  //     </div></Item>
  //     <Item><div>
  //     <div>Occupation :</div>
  //     <div>{family?.occupation?family?.occupation:'--'}</div>
  //     </div></Item>
  //     <Item><div>
  //     <div>Contact No :</div>
  //     <div>{family?.contact_no?family?.contact_no:'--'}</div>
  //     </div></Item>

  //     <Item><div>
  //     <div>Relationship :</div>
  //     <div>{family?.relationship?family?.relationship:'--'}</div>
  //     </div></Item>


  //     </Grid>

  // </Grid>

  return (
    <>
      <Helmet>
        <title>Customer Details - Thangam Capital </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        {
          data && <Container style={{ maxWidth: 'none' }}>

            <Box>
              <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between', marginBottom: '13px' }}> <BreadcrumbsComponent data={breadCrumbsData} />
                <Box
                  sx={{
                  }}

                >
                  {/* <Chip label="Edit" size="small" component="a" href={`customers/${customer.id}`} clickable icon={<EditIcon />} color="secondary" /> */}
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => navigate(`/app/customers/${id}?type=detail`)}
                  // href={`customers/${id}`}
                  >
                    <EditIcon className='edit-customer' color='#fff' style={{ color: '#fff' }} /> Edit
                  </Button>
                </Box></div>
              <Card >
                <CardContent>
                  <Modal onCancel={() => { setPrevImage(''); setPrevImageModal(false) }} visible={PrevimageModal} footer={null}><Image src={Previmage} /></Modal>
                  <Box style={{ padding: '0px !important' }} sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs

                      value={value}
                      onChange={handleChange}
                      textColor="secondary"
                      indicatorColor="secondary"
                      variant="scrollable"
                      scrollButtons
                      allowScrollButtonsMobile
                      aria-label=" scrollable force secondary  tabs example"
                    >
                      <Tab label="Personal Details" style={{ color: '#9c27b0' }} {...a11yProps(0)} />
                      <Tab label="Address" {...a11yProps(1)} />
                      <Tab label="Bank & Income Details" {...a11yProps(2)} />
                      <Tab label="Guarantor Details" {...a11yProps(3)} />
                      <Tab label="Proofs" {...a11yProps(4)} />
                      <Tab label="Family Details" {...a11yProps(5)} />
                      <Tab label="Loans" {...a11yProps(6)} />
                    </Tabs>
                  </Box>
                  <TabPanel value={value} index={0}>
                    <Grid className='customer-personl' container spacing={2}>
                      <Grid item md={12} lg={12}>
                        <StyledCard >
                          <StyledCardHeader
                            avatar={
                              <AccountCircleIcon className='miui-icon-peronal' color='#fff' />
                            }

                            title={`${data.firstName} ${data.lastName}`}
                            className="header-personal-details"
                            subheader={data.mobileNumber} />
                          <Divider style={{ borderColor: 'rgb(227, 242, 253)' }} />
                          <CardContent>
                            <List component="nav" aria-label="main mailbox folders" >
                              <ListItemButton style={{
                                '&:hover': {
                                  backgroundColor: 'rgb(237, 231, 246)',
                                  color: '#9c27b0'
                                }
                              }}>
                                <ListItemIcon>
                                  <EmailTwoToneIcon style={{ color: '#9c27b0' }} />
                                </ListItemIcon>
                                <ListItemText primary={data.email} onClick={() => clickEmail(data.email)} />
                              </ListItemButton>
                              <Divider style={{ borderColor: 'rgb(227, 242, 253)' }} />
                              <ListItemButton>
                                <ListItemIcon>
                                  <PhoneIphoneTwoToneIcon style={{ color: '#9c27b0' }} />
                                </ListItemIcon>
                                <ListItemText primary={data.mobileNumber} />
                              </ListItemButton>
                              <Divider style={{ borderColor: 'rgb(227, 242, 253)' }} />
                              <ListItemButton>
                                <ListItemIcon>
                                  <FmdGoodTwoToneIcon style={{ color: '#9c27b0' }} />
                                </ListItemIcon>
                                <ListItemText primary={data?.addressDetails?.permenant?.address1} />
                              </ListItemButton>
                            </List>
                          </CardContent>
                        </StyledCard>
                      </Grid>
                      <Grid item md={12} lg={12}>
                        <StyledCard>
                          <StyledCardHeader title="Personal Details" />
                          <Divider style={{ borderColor: 'rgb(227, 242, 253)' }} />
                          <CardContent>
                            <TableContainer style={{ overflowX: 'auto' }}>
                              <Table size="small" aria-label="simple table">
                                <TableBody>
                                  <TableRow hover>
                                    <BoldTableCell>Father Name</BoldTableCell>
                                    <MuiTableCell>:</MuiTableCell>
                                    <MuiTableCell>{data.fathersName}</MuiTableCell>
                                  </TableRow>
                                  <TableRow hover>
                                    <BoldTableCell>Gender</BoldTableCell>
                                    <MuiTableCell>:</MuiTableCell>
                                    <MuiTableCell>{data.gender}</MuiTableCell>
                                  </TableRow>
                                  <TableRow hover>
                                    <BoldTableCell>Education</BoldTableCell>
                                    <MuiTableCell>:</MuiTableCell>
                                    <MuiTableCell>{data.education}</MuiTableCell>
                                  </TableRow>
                                  <TableRow hover>
                                    <BoldTableCell>Religion</BoldTableCell>
                                    <MuiTableCell>:</MuiTableCell>
                                    <MuiTableCell>{data.religion}</MuiTableCell>
                                  </TableRow>
                                  <TableRow hover>
                                    <BoldTableCell>Caste</BoldTableCell>
                                    <MuiTableCell>:</MuiTableCell>
                                    <MuiTableCell>{data.caste}</MuiTableCell>
                                  </TableRow>
                                  <TableRow hover>
                                    <BoldTableCell>Maritial Status</BoldTableCell>
                                    <MuiTableCell>:</MuiTableCell>
                                    <MuiTableCell>{data.maritialStatus}</MuiTableCell>
                                  </TableRow>
                                  <TableRow hover>
                                    <BoldTableCell>Aadhar Number</BoldTableCell>
                                    <MuiTableCell>:</MuiTableCell>
                                    <MuiTableCell>{data.aadharId}</MuiTableCell>
                                  </TableRow>
                                  <TableRow hover>
                                    <BoldTableCell>Physically Challenged</BoldTableCell>
                                    <MuiTableCell>:</MuiTableCell>
                                    <MuiTableCell>{data.physicallyChallenged === 1 ? "Yes" : "No"}</MuiTableCell>
                                  </TableRow>
                                  <TableRow hover>
                                    <BoldTableCell>Spouse Name</BoldTableCell>
                                    <MuiTableCell>:</MuiTableCell>
                                    <MuiTableCell>{data.spouseName}</MuiTableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </CardContent>
                        </StyledCard>
                      </Grid>
                    </Grid>
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <Grid className='customer-address' container spacing={2}>
                      <Grid item md={12} lg={12}>
                        <StyledCard>
                          <StyledCardHeader title="Permenant" />
                          <Divider style={{ borderColor: 'rgb(227, 242, 253)' }} />
                          <CardContent>
                            <TableContainer style={{ overflowX: 'auto' }}>
                              <Table size="small" aria-label="simple table">
                                <TableBody>
                                  <TableRow hover>
                                    <BoldTableCell>Residence Type</BoldTableCell>
                                    <MuiTableCell>:</MuiTableCell>
                                    <MuiTableCell>{data.residenceType}</MuiTableCell>
                                  </TableRow>
                                  <TableRow hover>
                                    <BoldTableCell>House Number</BoldTableCell>
                                    <MuiTableCell>:</MuiTableCell>
                                    <MuiTableCell>{data?.addressDetails?.permenant?.number}</MuiTableCell>
                                  </TableRow>
                                  <TableRow hover>
                                    <BoldTableCell>Address1</BoldTableCell>
                                    <MuiTableCell>:</MuiTableCell>
                                    <MuiTableCell>{data?.addressDetails?.permenant?.address1}</MuiTableCell>
                                  </TableRow>
                                  <TableRow hover>
                                    <BoldTableCell>Address2</BoldTableCell>
                                    <MuiTableCell>:</MuiTableCell>
                                    <MuiTableCell>{data?.addressDetails?.permenant?.address2}</MuiTableCell>
                                  </TableRow>
                                  <TableRow hover>
                                    <BoldTableCell>City/Village</BoldTableCell>
                                    <MuiTableCell>:</MuiTableCell>
                                    <MuiTableCell>{data?.addressDetails?.permenant?.city_village_town}</MuiTableCell>
                                  </TableRow>
                                  <TableRow hover>
                                    <BoldTableCell>District</BoldTableCell>
                                    <MuiTableCell>:</MuiTableCell>
                                    <MuiTableCell>{data?.addressDetails?.permenant?.district}</MuiTableCell>
                                  </TableRow>
                                  <TableRow hover>
                                    <BoldTableCell>State</BoldTableCell>
                                    <MuiTableCell>:</MuiTableCell>
                                    <MuiTableCell>{data?.addressDetails?.permenant?.state}</MuiTableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </CardContent>
                        </StyledCard>
                      </Grid>
                      <Grid item md={12} lg={12}>
                        <StyledCard>
                          <StyledCardHeader title="Present" />
                          <Divider style={{ borderColor: 'rgb(227, 242, 253)' }} />
                          <CardContent>
                            <TableContainer style={{ overflowX: 'auto' }}>
                              <Table size="small" aria-label="simple table">
                                <TableBody>
                                  <TableRow hover>
                                    <BoldTableCell>Residence Type</BoldTableCell>
                                    <MuiTableCell>:</MuiTableCell>
                                    <MuiTableCell>{data.residenceType}</MuiTableCell>
                                  </TableRow>
                                  <TableRow hover>
                                    <BoldTableCell>House Number</BoldTableCell>
                                    <MuiTableCell>:</MuiTableCell>
                                    <MuiTableCell>{data?.addressDetails?.permenant?.number}</MuiTableCell>
                                  </TableRow>
                                  <TableRow hover>
                                    <BoldTableCell>Address1</BoldTableCell>
                                    <MuiTableCell>:</MuiTableCell>
                                    <MuiTableCell>{data?.addressDetails?.permenant?.address1}</MuiTableCell>
                                  </TableRow>
                                  <TableRow hover>
                                    <BoldTableCell>Address2</BoldTableCell>
                                    <MuiTableCell>:</MuiTableCell>
                                    <MuiTableCell>{data?.addressDetails?.permenant?.address2}</MuiTableCell>
                                  </TableRow>
                                  <TableRow hover>
                                    <BoldTableCell>City/Village</BoldTableCell>
                                    <MuiTableCell>:</MuiTableCell>
                                    <MuiTableCell>{data?.addressDetails?.permenant?.city_village_town}</MuiTableCell>
                                  </TableRow>
                                  <TableRow hover>
                                    <BoldTableCell>District</BoldTableCell>
                                    <MuiTableCell>:</MuiTableCell>
                                    <MuiTableCell>{data?.addressDetails?.permenant?.district}</MuiTableCell>
                                  </TableRow>
                                  <TableRow hover>
                                    <BoldTableCell>State</BoldTableCell>
                                    <MuiTableCell>:</MuiTableCell>
                                    <MuiTableCell>{data?.addressDetails?.permenant?.state}</MuiTableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </CardContent>
                        </StyledCard>
                      </Grid>
                    </Grid>
                  </TabPanel>
                  <TabPanel value={value} index={2}>
                    <Grid container spacing={2}>
                      <Grid item md={12} lg={12}>
                        <StyledCard>
                          <StyledCardHeader title="Bank Details" />
                          <Divider style={{ borderColor: 'rgb(227, 242, 253)' }} />
                          <CardContent>
                            <TableContainer style={{ overflowX: 'auto' }}>
                              <Table size="small" aria-label="simple table">
                                <TableBody>
                                  <TableRow hover>
                                    <BoldTableCell>Name</BoldTableCell>
                                    <MuiTableCell>:</MuiTableCell>
                                    <MuiTableCell>{data?.bankDetails?.bankName}</MuiTableCell>
                                  </TableRow>
                                  <TableRow hover>
                                    <BoldTableCell>Branch</BoldTableCell>
                                    <MuiTableCell>:</MuiTableCell>
                                    <MuiTableCell>{data?.bankDetails?.nameOfAccount}</MuiTableCell>
                                  </TableRow>
                                  <TableRow hover>
                                    <BoldTableCell>IFSC code</BoldTableCell>
                                    <MuiTableCell>:</MuiTableCell>
                                    <MuiTableCell>{data?.bankDetails?.ifscCode}</MuiTableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </CardContent>
                        </StyledCard>
                      </Grid>
                      <Grid item md={12} lg={12}>
                        <StyledCard>
                          <StyledCardHeader title="Income Details" />
                          <Divider style={{ borderColor: 'rgb(227, 242, 253)' }} />
                          <CardContent>
                            <TableContainer style={{ overflowX: 'auto' }}>
                              <Table sx={{ minWidth: 580 }} size="small" aria-label="simple table">
                                <TableBody>
                                  <TableRow hover>
                                    <BoldTableCell>Occupation</BoldTableCell>
                                    <MuiTableCell>:</MuiTableCell>
                                    <MuiTableCell>{data?.incomeAndExpenses?.total_household_income}</MuiTableCell>
                                  </TableRow>
                                  <TableRow hover>
                                    <BoldTableCell>Net Income</BoldTableCell>
                                    <MuiTableCell>:</MuiTableCell>
                                    <MuiTableCell>{data?.incomeAndExpenses?.total_household_income_net}</MuiTableCell>
                                  </TableRow>
                                  <TableRow hover>
                                    <BoldTableCell>Expenses</BoldTableCell>
                                    <MuiTableCell>:</MuiTableCell>
                                    <MuiTableCell>{data?.incomeAndExpenses?.total_household_expense}</MuiTableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </CardContent>
                        </StyledCard>
                      </Grid>
                    </Grid>
                  </TabPanel>
                  <TabPanel value={value} index={3}>
                    <Grid container spacing={2}>
                      <Grid item md={12} lg={12}>
                        <StyledCard>
                          <StyledCardHeader title="Guarantor Details" />
                          <Divider style={{ borderColor: 'rgb(227, 242, 253)' }} />
                          <CardContent>
                            {/**/} <TableContainer style={{ overflowX: 'auto' }}>
                              <Table size="small" aria-label="simple table">
                                <TableBody>
                                  <TableRow hover>
                                    <BoldTableCell>Name</BoldTableCell>
                                    <MuiTableCell>:</MuiTableCell>
                                    <MuiTableCell>{data.guarantor_details[0].name}</MuiTableCell>
                                  </TableRow>
                                  <TableRow hover>
                                    <BoldTableCell>Age</BoldTableCell>
                                    <MuiTableCell>:</MuiTableCell>
                                    <MuiTableCell>{data.guarantor_details[0].age}</MuiTableCell>
                                  </TableRow>
                                  <TableRow hover>
                                    <BoldTableCell>Gender</BoldTableCell>
                                    <MuiTableCell>:</MuiTableCell>
                                    <MuiTableCell>{data.guarantor_details[0].gender}</MuiTableCell>
                                  </TableRow>
                                  <TableRow hover>
                                    <BoldTableCell>Contact No</BoldTableCell>
                                    <MuiTableCell>:</MuiTableCell>
                                    <MuiTableCell>{data.guarantor_details[0].contact_no}</MuiTableCell>
                                  </TableRow>
                                  <TableRow hover>
                                    <BoldTableCell>Relationship</BoldTableCell>
                                    <MuiTableCell>:</MuiTableCell>
                                    <MuiTableCell>{data.guarantor_details[0].relationship}</MuiTableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </CardContent>
                        </StyledCard>
                      </Grid>
                    </Grid>
                  </TabPanel>
                  <TabPanel value={value} index={4}>
                    <StyledCard>
                      <StyledCardHeader title="Proof Image Details" />
                      <Divider style={{ borderColor: 'rgb(227, 242, 253)' }} />
                      <CardContent>
                        <TableContainer style={{ overflowX: 'auto' }}>
                          <Table size="small" aria-label="simple table">
                            <TableBody>
                              <TableRow hover>
                                <BoldTableCell>Aadhaar Proof</BoldTableCell>
                                <MuiTableCell>:</MuiTableCell>
                                {console.log("data.documents", data.documents.addressProof[0])}
                                {_.get(data, 'aadhaar_proof_url') && <> <MuiTableCell onClick={() => viewImage(_.get(data, 'aadhaar_proof_url'), 'down')}><ArrowCircleDownIcon /></MuiTableCell>
                                  <MuiTableCell onClick={() => viewImage(_.get(data, 'aadhaar_proof_url'))}><RemoveRedEyeIcon /></MuiTableCell></>}
                              </TableRow>
                              <TableRow hover>
                                <BoldTableCell>Bank Book Proof</BoldTableCell>
                                <MuiTableCell>:</MuiTableCell>
                                {_.get(data, 'bank_book_proof_url') && <> <MuiTableCell onClick={() => viewImage(_.get(data, 'bank_book_proof_url'), 'down')}><ArrowCircleDownIcon /></MuiTableCell>
                                  <MuiTableCell onClick={() => viewImage(_.get(data, 'bank_book_proof_url'))}><RemoveRedEyeIcon /></MuiTableCell></>}
                              </TableRow>
                              <TableRow hover>
                                <BoldTableCell>Dirving License Proof</BoldTableCell>
                                <MuiTableCell>:</MuiTableCell>
                                {_.get(data, 'dirving_license_proof_url') && <> <MuiTableCell onClick={() => viewImage(_.get(data, 'dirving_license_proof_url'), 'down')}><ArrowCircleDownIcon /></MuiTableCell>
                                  <MuiTableCell onClick={() => viewImage(_.get(data, 'dirving_license_proof_url'))}><RemoveRedEyeIcon /></MuiTableCell></>}
                              </TableRow>
                              <TableRow hover>
                                <BoldTableCell>Family Card Proof</BoldTableCell>
                                <MuiTableCell>:</MuiTableCell>
                                {_.get(data, 'family_card_proof_url') && <><MuiTableCell onClick={() => viewImage(_.get(data, 'family_card_proof_url'), 'down')}><ArrowCircleDownIcon /></MuiTableCell>
                                  <MuiTableCell onClick={() => viewImage(_.get(data, 'family_card_proof_url'))}><RemoveRedEyeIcon /></MuiTableCell></>}
                              </TableRow>
                              <TableRow hover>
                                <BoldTableCell>Pan Proof</BoldTableCell>
                                <MuiTableCell>:</MuiTableCell>
                                {_.get(data, 'pan_proof_url') && <>   <MuiTableCell onClick={() => viewImage(_.get(data, 'pan_proof_url'), 'down')}><ArrowCircleDownIcon /></MuiTableCell>
                                  <MuiTableCell onClick={() => viewImage(_.get(data, 'pan_proof_url'))}><RemoveRedEyeIcon /></MuiTableCell></>}
                              </TableRow>
                              <TableRow hover>
                                <BoldTableCell>Passport Proof</BoldTableCell>
                                <MuiTableCell>:</MuiTableCell>
                                {_.get(data, 'passport_proof_url') && <>   <MuiTableCell onClick={() => viewImage(_.get(data, 'passport_proof_url'), 'down')}><ArrowCircleDownIcon /></MuiTableCell>
                                  <MuiTableCell onClick={() => viewImage(_.get(data, 'passport_proof_url'))}><RemoveRedEyeIcon /></MuiTableCell></>}
                              </TableRow>
                              <TableRow hover>
                                <BoldTableCell>Voters Id Proof</BoldTableCell>
                                <MuiTableCell>:</MuiTableCell>
                                {_.get(data, 'voters_id_proof_url') && <>  <MuiTableCell onClick={() => viewImage(_.get(data, 'voters_id_proof_url'), 'down')}><ArrowCircleDownIcon /></MuiTableCell>
                                  <MuiTableCell onClick={() => viewImage(_.get(data, 'voters_id_proof_url'))}><RemoveRedEyeIcon /></MuiTableCell></>}
                              </TableRow>
                              <TableRow hover>
                                <BoldTableCell>Others Proof</BoldTableCell>
                                <MuiTableCell>:</MuiTableCell>
                                {_.get(data, 'others_proof_url') && <>  <MuiTableCell onClick={() => viewImage(_.get(data, 'others_proof_url'), 'down')}><ArrowCircleDownIcon /></MuiTableCell>
                                  <MuiTableCell onClick={() => viewImage(_.get(data, 'others_proof_url'))}><RemoveRedEyeIcon /></MuiTableCell></>}
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </CardContent>
                    </StyledCard>
                  </TabPanel>
                  <TabPanel value={value} index={5}>
                    <StyledCard>
                      <StyledCardHeader title="Family Details" />
                      <Divider />
                      <CardContent>
                        <Grid container spacing={2}>
                          {
                            data && data.familyDetails && data.familyDetails.map((family, index) => {
                              return (
                                <>
                                  <Grid item md={6} lg={6} xs={6}>
                                    <TableContainer style={{ overflowX: 'auto' }}>
                                      <Table size="small" aria-label="simple table">

                                        <TableBody>
                                          <TableRow hover>
                                            <BoldTableCell>Name</BoldTableCell>
                                            <MuiTableCell>:</MuiTableCell>
                                            <MuiTableCell>{family.name}</MuiTableCell>
                                          </TableRow>
                                          <TableRow hover>
                                            <BoldTableCell>Age</BoldTableCell>
                                            <MuiTableCell>:</MuiTableCell>
                                            <MuiTableCell>{family.age}</MuiTableCell>
                                          </TableRow>
                                          <TableRow hover>
                                            <BoldTableCell>Gender</BoldTableCell>
                                            <MuiTableCell>:</MuiTableCell>
                                            <MuiTableCell>{family?.gender ? family?.gender : '--'}</MuiTableCell>
                                          </TableRow>
                                          <TableRow hover>
                                            <BoldTableCell>Address</BoldTableCell>
                                            <MuiTableCell>:</MuiTableCell>
                                            <MuiTableCell>{family?.address ? family?.address : '--'}</MuiTableCell>
                                          </TableRow>
                                          <TableRow hover>
                                            <BoldTableCell>Occupation</BoldTableCell>
                                            <MuiTableCell>:</MuiTableCell>
                                            <MuiTableCell>{family?.occupation ? family?.occupation : '--'}</MuiTableCell>
                                          </TableRow>
                                          <TableRow hover>
                                            <BoldTableCell rules={CustomerRules.contact_no}>Contact No</BoldTableCell>
                                            <MuiTableCell>:</MuiTableCell>
                                            <MuiTableCell>{family?.contact_no ? family?.contact_no : '--'}</MuiTableCell>
                                          </TableRow>
                                          <TableRow hover>
                                            <BoldTableCell>Relationship</BoldTableCell>
                                            <MuiTableCell>:</MuiTableCell>
                                            <MuiTableCell>{family.relationship}</MuiTableCell>
                                          </TableRow>
                                          {/* <TableRow hover>
                                            <BoldTableCell>Income</BoldTableCell>
                                            <MuiTableCell>:</MuiTableCell>
                                            <MuiTableCell>{family.income}</MuiTableCell>
                                          </TableRow>
                                          <TableRow hover>
                                            <BoldTableCell>Loan Company</BoldTableCell>
                                            <MuiTableCell>:</MuiTableCell>
                                            <MuiTableCell>{family.loan_company}</MuiTableCell>
                                          </TableRow> */}
                                        </TableBody>
                                      </Table>
                                    </TableContainer>
                                  </Grid>
                                  <Divider orientation="vertical" variant='middle' flexItem style={{ marginRight: "-1px" }} />
                                </>
                              )
                            })
                          }

                        </Grid>
                      </CardContent>
                    </StyledCard>
                  </TabPanel>
                  <TabPanel value={value} index={6}>
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 700 }} size="small" aria-label="customized table">
                        <TableHead>
                          <TableRow>
                            <TabelHeaderCell align="center">Status</TabelHeaderCell>
                            <TabelHeaderCell align="center">Loan Id</TabelHeaderCell>
                            <TabelHeaderCell align="center">Loan Amount</TabelHeaderCell>
                            <TabelHeaderCell align="center">Intrest Rate</TabelHeaderCell>
                            <TabelHeaderCell align="center">Disbursed On</TabelHeaderCell>
                            <TabelHeaderCell align="center">Tenure</TabelHeaderCell>
                            <TabelHeaderCell align="center">Expected Date</TabelHeaderCell>
                            <TabelHeaderCell align="center">Edit</TabelHeaderCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {loan_details && loan_details.map((row) => (
                            <StyledTableRow key={row.status}>

                              <StyledTableCell component="th" scope="row" align="center">{globalConfig.loanAttributes[row.status]}</StyledTableCell>
                              <StyledTableCell align="center">{row.id}</StyledTableCell>
                              <StyledTableCell align="center">{row.loan_amount}</StyledTableCell>
                              <StyledTableCell align="center">{row.interest_rate}</StyledTableCell>
                              <StyledTableCell align="center">{row.disbursed_on ? row.disbursed_on : '--'}</StyledTableCell>
                              <StyledTableCell align="center">{row.tenure}</StyledTableCell>
                              <StyledTableCell align="center">{row.expected_date}</StyledTableCell>
                              <StyledTableCell align="center">
                                <Chip label="Edit" size="small" component="a" href={`/app/customers/loan/add/${row.customerId}?loan_id=${row.id}`} clickable icon={<EditLocationAltTwoToneIcon />} color="secondary" />
                                <Chip label="View" style={{ marginLeft: '4%' }} size="small" component="a" href={`/app/loan/details/${row.id}`} clickable icon={<DetailsTwoToneIcon />} color="secondary" />
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </TabPanel>
                </CardContent>
              </Card>
            </Box>
          </Container>
        }
      </Box>
    </>
  );
}
