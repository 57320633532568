import React, { useState, useEffect } from 'react';
import { services } from 'src/components/services/services';
import {
  Box,
  Card,
  CardHeader,
  Divider,
  CardContent,
  Grid,
  IconButton,
  Button
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { ProofImageUpload } from '../shared/proff-image-upload';
// import { styled } from '@mui/material/styles';
import { DeleteFilled, DownCircleOutlined, EyeFilled } from '@ant-design/icons';
import PlusCircleIcon  from '@mui/icons-material/AddCircle'; 

import _ from 'lodash'
// import {notification,Spin} from 'antd'
import './style.css'
import axios from "axios"
import { FormInputText } from '../shared/FormInputText';
import { FormInputDropdown } from '../shared/FormInputDropdown';
import Icon from '@mui/material/Icon';
import { CustomerRules } from 'src/utils/validationsConfig';
import SnackBar from '../shared/SnackBar';

// let imageFileDetails ={
//   aadhaar: "",
//   pan: "",
//   driving_license: "",
//   passport: "",
//   voters_id: "",
//   family_card: "",
//   bank_book: "",
//   others: ""
// }
function FormRow(props) {
  const { control, name, label, rules, inputType, readonly } = props;
  return (
    <React.Fragment>
      <Grid item
        md={6}
        xs={10}
        lg={4}
      >
        <FormInputText
          name={name}
          control={control}
          label={label}
          rules={rules}
          inputType={inputType || "text"}
          readonly={readonly || false}
          helperText={props.helperText || null}
          setAddressInfo={props.setAddressInfo}
          addressInfo={props.addressInfo}
        />
      </Grid>
    </React.Fragment>
  );
}
const Proofs = ({
  handleBack,
  handleNext,
  activeStep,
  steps,
  setCustomerInfo,
  customerInfo,
  isNextActive
}) => {
  // let proofs_data = [
  //   "aadhaar",
  //   'pan',
  //   'dirving_license',
  //   'passport',
  //   'voters_id',
  //   'family_card',
  //   'bank_book',
  //   'others',
  // ]
  console.log('customerInfocustomerInfo', customerInfo);
  const [urlImage, seturlImage] = useState('')
  const [uploads, setUploads] = useState({}); // State to track multiple uploads
  //const [setImageValue, setsetImageValue] = useState(null); // Separate state for managing image value
  //const [setuploadvalue, setsetuploadvalue] = useState(false);

  const imageUploadNew = (value, name, id, type_dropdown) => {
    postService(value, name, id, type_dropdown)
  }

  const [uploadsFormView, setUploadsFormView] = useState({}); // State for loadFormView uploads
const [uploadsFormView1, setUploadsFormView1] = useState({}); // State for loadFormView1 uploads

// Separate image upload handler for each form
const imageUploadNewFormView = (value, name, id, type_dropdown) => {
  postService(value, name, id, type_dropdown);
  setUploadsFormView(prevState => ({
    ...prevState,
    [id]: { file: value, type_name: name, id: id }
  }));
};

const imageUploadNewFormView1 = (value, name, id, type_dropdown) => {
  postService(value, name, id, type_dropdown);
  setUploadsFormView1(prevState => ({
    ...prevState,
    [id]: { file: value, type_name: name, id: id }
  }));
};

// Update FormRowUpload for both forms to use the respective upload states
const FormRowUpload = ({ control, name, label, rules, id, type_dropdown, image_Details, readonly, formType }) => {
  const handleUploadChange = (values) => {
    if (formType === 'formView') {
      imageUploadNewFormView(values, name, id, type_dropdown);
    } else {
      imageUploadNewFormView1(values, name, id, type_dropdown);
    }
  };

  const currentUploads = formType === 'formView' ? uploadsFormView : uploadsFormView1;

  return (
    <Grid item md={10} xs={10} lg={10}>
      <ProofImageUpload
        imageChange={handleUploadChange}
        id={id}
        show_upload={image_Details}
        readonly={readonly || false}
        value={currentUploads[id] || null} // Use form-specific upload state
        newFieldTru={(va) => {
          handleUploadChange(va);
        }}
        name={name}
        label={label}
        control={control}
        rules={rules}
      />
    </Grid>
  );
};
/*
  const FormRowUpload = (props) => {
    const { control, name, label, rules, id, type_dropdown, image_Details, readonly } = props;

    return (
      <React.Fragment>
        <Grid item md={10} xs={10} lg={10}>
          <ProofImageUpload
            imageChange={(values) => {
              imageUploadNew(values, name);
            }}
            id={id}
            show_upload={image_Details}
            readonly={readonly || false}
            value={uploads[id] || null} // Ensure the correct upload value is used
            newFieldTru={(va) => {
              setsetuploadvalue(true);
              setUploads(prevState => ({
                ...prevState,
                [id]: { file: va, type_name: name, id: id }
              }));
              imageUploadNew(va, getValues('proof'), id, type_dropdown);
            }}
            name={name}
            label={label}
            control={control}
            rules={rules}
          />
        </Grid>
      </React.Fragment>
    );
  }
  */



  const viewImage = (value, name, type) => {
    console.log('value,name,type', value, name, type);
    axios({
      method: 'get',
      url: `http://thangamcapitaladmin-ui.s3-website.ap-south-1.amazonaws.com/qa/read-file`,
      data: { "fileKey": value },
      params: { "fileKey": value },
      headers: {
        // Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }

    }).then((res) => {
      console.log('View presignedUrl', res);
      if (type === 'down') {
        fetch(_.get(res, 'data.presignedUrl'))
          .then(response => {
            response.arrayBuffer().then(function (buffer) {
              const url = window.URL.createObjectURL(new Blob([buffer]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", value); //or any other extension
              document.body.appendChild(link);
              link.click();
            });
          })
          .catch(err => {
            console.log(err);
          });
      } else {
        // setPrevImage(_.get(res,'data.presignedUrl'))
        // setPrevImageModal(true)

      }

    })
  }

  let toMe = [

    { label: 'Aadhaar', value: "aadhaar" },
    { label: 'Pan', value: "pan" },
    { label: 'Driving License', value: "dirving_license" },
    { label: 'Passport', value: "passport" },
    { label: 'Voters Id', value: "voters_id" },
    { label: 'Family Card', value: "family_card" },
    { label: 'Bank Book', value: "bank_book" },
    { label: 'Others', value: "others" },
  ]

  const [profileSelectDetails, setProfileSelectDetails] = useState([

    { label: 'Aadhaar', value: "aadhaar" },
    { label: 'Pan', value: "pan" },
    { label: 'Driving License', value: "dirving_license" },
    { label: 'Passport', value: "passport" },
    { label: 'Voters Id', value: "voters_id" },
    { label: 'Family Card', value: "family_card" },
    { label: 'Bank Book', value: "bank_book" },
    { label: 'Others', value: "others" },
  ])
  const [profileSelectDetails1, setProfileSelectDetails1] = useState([

    { label: 'Aadhaar', value: "aadhaar" },
     { label: 'LPG Connection', value: "lpg_connection" },
    { label: 'Driving License', value: "dirving_license" },
    { label: 'Passport', value: "passport" },
    { label: 'Voters Id', value: "voters_id" },
    { label: 'Family Card', value: "family_card" },
    { label: 'Bank Book', value: "bank_book" },
    { label: 'Others', value: "others" },
  ])
 
  const initialErrorState = {
    severity: '',
    message: '',
    error: false,
    show: false
  }
  //const [setuploadvalue, setsetuploadvalue] = useState(false)
  const [newFieldAdded, setNewFieldAdded] = useState(false); 
  const [setuploadvalue, setsetuploadvalue] = useState(null)
  

  const [formList, setFormList] = useState(
    _.isArray(_.get(customerInfo, 'id_proof')) && _.size(_.get(customerInfo, 'id_proof')) > 0 ? [..._.get(customerInfo, 'id_proof')] : [
      { proof: "proof", name2: 'proof_unique_no', imageurl: '', upload: false, id: 1 }
    ]
  );
  
  const [addressProof, setAddressProof] = useState(
    _.isArray(_.get(customerInfo, 'address_proof')) && _.size(_.get(customerInfo, 'address_proof')) > 0 ? [..._.get(customerInfo, 'address_proof')] : [
      { proof_address: "proof", name2: 'proof_unique_no_address', imageurl: '', upload: false, id: 1 }
    ]
  );
  
    
  const [apiError, setApiError] = useState(initialErrorState);
  // const [open, setOpen] = useState(false);
  //const [setImageValue, setsetImageValue] = useState({})
  const [setImageValue, setsetImageValue] = useState(false);
  const defaultValues = {
    aadhaar: "",
    pan: "",
    lpg_connection:"",
    driving_license: "",
    passport: "",
    voters_id: "",
    family_card: "",
    bank_book: "",
    others: "",
    proof: "",
    proof_unique_no: "",
    proof_image: null,
    proof_address: "",
    proof_unique_no_address: "",
    proof_image_address: null

  };
  // let final_try ={}
  const { handleSubmit, control, reset, formState: { isValid }, getValues } = useForm({
    defaultValues: { ...defaultValues }
  });

  useEffect(() => {
    if (customerInfo) {

      
    }

  }, [])

   const postFiles = async (f, url) => {
  
    const formData = new FormData();
    formData.append('file', f);
    console.log('url',  url);
    axios({
      method: 'put',
      url: url,
      data: f,
      // params:{url:url}
    }).then(async (res) => {
       console.log('askfansjdbakbdknkfa',res);
      

    }).catch((err) => {

    })

}

  useEffect(() => {
    if (customerInfo) {
      let pick_details = _.pick(customerInfo, [
        "aadhaar",
        'pan',
        'dirving_license',
        'passport',
        'voters_id',
        'family_card',
        'bank_book',
        'others',])

      let a = [{ proof: "aadhaar", name2: 'proof_unique_no', imageurl: '', upload: _.get(pick_details, "aadhaar") ? true : false, id: 1 },
      { proof: "pan", name2: 'proof_unique_no', imageurl: '', upload: _.get(pick_details, "pan") ? true : false, id: 1 },
      { proof: "dirving_license", name2: 'proof_unique_no', imageurl: '', upload: _.get(pick_details, "dirving_license") ? true : false, id: 1 },
      { proof: "passport", name2: 'proof_unique_no', imageurl: '', upload: _.get(pick_details, "passport") ? true : false, id: 1 },
      { proof: "voters_id", name2: 'proof_unique_no', imageurl: '', upload: _.get(pick_details, "voters_id") ? true : false, id: 1 },
      { proof: "family_card", name2: 'proof_unique_no', imageurl: '', upload: _.get(pick_details, "family_card") ? true : false, id: 1 },
      { proof: "bank_book", name2: 'proof_unique_no', imageurl: '', upload: _.get(pick_details, "bank_book") ? true : false, id: 1 },
      { proof: "others", name2: 'proof_unique_no', imageurl: '', upload: _.get(pick_details, "others") ? true : false, id: 1 },


      ]
      let b = []
      _.map(a, (v, i) => {
        if (v.upload === true) {
          b.push(v)
        }

      })
      if (_.size(b) === 8) {
        // setbtnType('next')
      }
      // setFormList(_.size(b)===8?b:_.size(b)>1?[...b, {proof:"proof",name2:'proof_unique_no',imageurl:'',upload:false,id:1}]:b)
    }
  }, [])

  const postService = async (service, key, id, type_dropdown) => {
    let ext = _.split(_.get(service, 'name'), '.')[1]; 
    let customerId = _.get(customerInfo, 'aadharId');  
    let uniqueId = Date.now(); 
     let fileName = `/dev/${customerId}/${key}_${uniqueId}.${ext}`;
     console.log("fileName",fileName)
    // let data = { customerId, fileName };
  
    try {
      let response = await services.postService(`file/upload`, { documentType: key, documentExtension: ext, id: customerId });
      console.log("response",response)
      console.log("response.key",response.key)
      console.log("response.presignedUrl",response.presignedUrl)
     // Check if presignedUrl and key exist
     if (response.presignedUrl && response.key) {
      console.log('Triggering postFiles...');
     // postFiles(service, _.get(response, 'response.presignedUrl'), response.key, response.presignedUrl);
     await postFiles(service, response.presignedUrl)
  } else {
      console.error('Missing presignedUrl or key', response);
  }
  
    } catch (err) {
      let errors = err?.data?.results;
      if (errors) {
        setApiError({
          severity: "error",
          message: `Validation Error :: ${Object.values(errors).flat(2).join(" ")}`,
          error: true
        });
      }
      throw err;
    }
  };
  
  
  const onSubmit = async (data) => {
    console.log('addressProofaddressProof1 test', addressProof, formList);

    let address_a = [...addressProof]
    let address_a_filter = _.filter(address_a, (v, i) => v.upload === true
    )
    // let address_b = [...formList]
    let address_b_filter = _.filter(address_a, (v, i) => v.upload === true)
    
    setCustomerInfo({
      ...customerInfo,
      addres_proof: addressProof,
      id_proof: formList
    })

    console.log('addressProofaddressProof2 test', addressProof, formList);
    handleNext()

    

  }

 

  const deleteItem = (item, type) => {
    if (type === "address") {
      let updatedList = addressProof.filter(v => v.id !== item.id);
      if (updatedList.length === 0) {
        updatedList.push({ proof_address: "proof", name2: 'proof_unique_no', imageurl: '', upload: false, id: 1 });
      }
      setAddressProof(updatedList);
    } else {
      let updatedList = formList.filter(v => v.id !== item.id);
      if (updatedList.length === 0) {
        updatedList.push({ proof: "proof", name2: 'proof_unique_no_address', imageurl: '', upload: false, id: 1 });
      }
      setFormList(updatedList);
    }
  };

  
  
  
/*
  const deleteItem = (item, type) => {
    if (type === "address") {
      let a = [...addressProof]
      let b = []
      // let d =[]
      _.map(a, (v, i) => {
        if (_.get(v, 'proof_address') !== _.get(item, 'proof_address') && _.get(v, 'proof_address') !== "proof") {
          b.push({ ...v, value: _.get(v, 'proof_address') });
        }
      })

      // setbtnType('add')

      setAddressProof([...b, { proof_address: "proof", name2: 'proof_unique_no', imageurl: '', upload: false, id: 1 }])
      filderBox([...b], item, 'address')
    } else {
      let a = [...formList]
      let b = []
      // let d =[]
      _.map(a, (v, i) => {
        if (_.get(v, 'proof') !== _.get(item, 'proof') && _.get(v, 'proof') !== "proof") {
          b.push({ ...v, value: _.get(v, 'proof') });
        }
      })

      // setbtnType('add')

      setFormList([...b, { proof: "proof", name2: 'proof_unique_no', imageurl: '', upload: false, id: 1 }])
      filderBox([...b], item)
    }

  }
    */

  // const deleteItem = (index, type) => {
  //   if (type === 'id') {
  //     setFormList(formList.filter((_, i) => i !== index));
  //   } else {
  //     setAddressProof(addressProof.filter((_, i) => i !== index));
  //   }
  // };

  
  const filderBox = (value, item, type) => {
    if (type) {
      setProfileSelectDetails1(_.xorBy(value, toMe, 'value'))
    } else {
      setProfileSelectDetails(_.xorBy(value, toMe, 'value'))
    }

  }
  /*
  const handleAdd = (e, type) => {
    if (type) {


      let pick_detail = _.pick(getValues(), ['proof_address', 'proof_unique_no_address', 'proof_image_address'])
      console.log('pick_detail', pick_detail, getValues(), addressProof);
      let details = [...addressProof, { ...pick_detail, upload: true, url: urlImage }]
      let filder = _.filter(details, (v, i) => v.upload === true)
      setaddressProof([..._.uniqBy(filder, 'proof_address'), { proof_address: "proof", name2: 'proof_unique_no', imageurl: '', upload: false, id: 1 }])
      // e.preventDefault();
      reset()
      setsetuploadvalue(false)
      setsetImageValue({})
      let adc = [...profileSelectDetails1]
      let filter_value = _.filter(adc, (v, i) => v.value !== pick_detail?.proof_address)
      setProfileSelectDetails1(filter_value)
      console.log('addressProofaddressProof 3 test', filder);
    } else {
      let pick_detail = _.pick(getValues(), ['proof', 'proof_unique_no', 'proof_image'])
      let details = [...formList, { ...pick_detail, upload: true, url: urlImage }]
      let filder = _.filter(details, (v, i) => v.upload === true)
      setFormList([..._.uniqBy(filder, 'proof'), { proof: "proof", name2: 'proof_unique_no', imageurl: '', upload: false, id: 1 }])
      // e.preventDefault();
      reset()
      setsetuploadvalue(false)
      setsetImageValue({})
      let adc = [...profileSelectDetails]
      let filter_value = _.filter(adc, (v, i) => v.value !== pick_detail?.proof)
      setProfileSelectDetails(filter_value)
      console.log('addressProofaddressProof 4 test', filder);
    }


  }

  */
/*
  const handleAdd = (type) => {
    setNewFieldAdded(true); 
    const index = Date.now();
    if (type === 'id') {
      const newIdEntry = {
        id: index,
        proof: 'proof',
        proof_unique_no: '',
        proof_image: null,
        upload: false,
        name2: 'proof_unique_no'
      };
      setFormList([...formList, newIdEntry]);
    } else if (type === 'address') {
      const newAddressEntry = {
        id: index,
        proof_address: 'proof',
        proof_unique_no_address: '',
        proof_image_address: null,
        upload: false,
        name2: 'proof_address'
      };
      setAddressProof([...addressProof, newAddressEntry]);
    }
  };
  */
  const handleAdd = (type) => {
    const index = Date.now();  
    if (type === 'id') {
      const newIdEntry = {
        id: index,
        proof: 'proof',
        proof_unique_no: '',
        proof_image: null,
        upload: false,
        name2: 'proof_unique_no'
      };
      setFormList([...formList, newIdEntry]);  
    } else if (type === 'address') {
      const newAddressEntry = {
        id: index,
        proof_address: 'proof',
        proof_unique_no_address: '',
        proof_image_address: null,
        upload: false,
        name2: 'proof_unique_no_address'
      };
      setAddressProof([...addressProof, newAddressEntry]);  
    }
  };
  
  


  const handleInputChange = (index, field, value, type) => {
    const updateState = (prevState) => {
      const updatedList = [...prevState];
      updatedList[index][field] = value;
      return updatedList;
    };

    if (type === 'id') {
      setFormList(updateState(formList));
    } else {
      setAddressProof(updateState(addressProof));
    }
  };
  // const checkCondition =(a,b,c)=>{
  // // let a =getValues('proof')
  // // console.log('dsfmnsmd',getValues('proof'),getValues('proof_unique_no'),getValues('proof_image'));
  //   if(a && b &&c){
  //     return true;
  //   }else{
  //     return false
  //   }
  // }
  useEffect(() => {
    console.log('sd,mf,msdsfsdfd,', getValues('proof_image'));
  }, [getValues('proof_image')])

  const commanNameChange = (name) => {
    if (`${name}`.includes('_url')) {
      return _.startCase(_.split(name, '_url')[0])
    } else {
      return _.startCase(name)
    }

  }

  const loadFormView = (formList) => {
    console.log('proof_image:', getValues('proof_image'));
  
    return (
      <Grid container item spacing={1}>
        {_.map(formList, (v, i) => {
          console.log('formList:', formList);
  
          // If document upload is available
          if (_.get(v, 'upload') === true) {
            return (
              <Grid key={i} style={{ marginBottom: 34, marginTop: 10 }} container item spacing={1}>
                <Grid item md={6} xs={10} lg={3}>
                  <div>{commanNameChange(_.get(v, 'proof'))}</div>
                </Grid>
                <Grid item md={6} xs={10} lg={3}>
                  <div>{_.get(v, 'proof_unique_no')}</div>
                </Grid>
                <Grid item md={6} xs={10} lg={3}>
                  <div>{_.get(v, 'proof_image.name')}.png</div>
                </Grid>
                <Grid item md={6} xs={10} lg={3}>
                  <div style={{ marginLeft: "48%", fontSize: "23px", marginBottom: "-19px", flexDirection: 'row' }}>
                    <EyeFilled onClick={() => viewImage(_.get(customerInfo, _.get(v, 'proof')), _.get(v, 'proof'))} />
                    <DownCircleOutlined onClick={() => viewImage(_.get(customerInfo, _.get(v, 'proof')), _.get(v, 'proof'), 'down')} style={{ marginLeft: 23 }} />
                    <DeleteFilled onClick={() => deleteItem(v, 'id')} style={{ marginLeft: 23 }} />
                  </div>
                </Grid>
              </Grid>
            );
          } else {
            // If no document is uploaded yet, display form inputs
            return (
              <Grid key={i} container item spacing={1}>
                {/* Dropdown for document type */}
                <Grid item md={6} xs={10} lg={3}>
                  <FormInputDropdown
                    name={`proof_${i}`}  
                    control={control}
                    label="Documents"
                    options={profileSelectDetails}
                    rules={{ required: CustomerRules.commonValidation("Proof", i + 1) }}
                    readonly={isNextActive}
                  />
                </Grid>
  
                {/* Input for unique document number */}
                <FormRow
                  control={control}
                  readonly={getValues(`proof_${i}`) ? false : true}  
                  name={`proof_unique_no_${i}`}  
                  label="Documents Unique No"
                  rules={{ required: CustomerRules.commonValidation("Proof Unique No", i + 1) }}
                />
  
                {/* File upload for proof image */}
                <Grid item md={6} xs={10} lg={3}>
                  <FormRowUpload
                    name_title={getValues(`proof_${i}`)}
                    value_detail={formList[i]?.image_Details}
                    type_dropdown="id_proof"
                    name={`proof_image_${i}`}  
                    id={i + 1}
                    disabled={getValues(`proof_unique_no_${i}`) ? false : true}  
                    label="Aadhaar"
                    formType="formView"
                    control={control}
                  />
                </Grid>
  
                {/* Button to delete documents */}
                <Grid item md={6} xs={10} lg={2}>
                  {formList.length > 1 ? (  // Ensure delete only appears if there is more than 1 item
                    <DeleteFilled onClick={() => deleteItem(v, 'id')} style={{ marginLeft: 23,marginTop: 15}} />
                  ) : null}
                </Grid>
              </Grid>
            );
          }
        })}
  
        {/* Only one "add" button at the end */}
        <Grid item md={6} xs={10} lg={14}>
          <div className="family-card-add-button-wrapper">
            <Icon baseClassName="fas" className="fa-plus-circle" color="primary" onClick={() => handleAdd('id')} />
          </div>
        </Grid>
      </Grid>
    );
  };
  
  
//   const loadFormView = (formList) => {
//     console.log('proof_image:', getValues('proof_image'));
  
//     return (
//       <Grid container item spacing={1}>
//         {_.map(formList, (v, i) => {
//           console.log('formList:', formList);
  
//           // If document upload is available
//           if (_.get(v, 'upload') === true) {
//             return (
//               <Grid key={i} style={{ marginBottom: 34, marginTop: 10 }} container item spacing={1}>
//                 <Grid item md={6} xs={10} lg={3}>
//                   <div>{commanNameChange(_.get(v, 'proof'))}</div>
//                 </Grid>
//                 <Grid item md={6} xs={10} lg={3}>
//                   <div>{_.get(v, 'proof_unique_no')}</div>
//                 </Grid>
//                 <Grid item md={6} xs={10} lg={3}>
//                   <div>{_.get(v, 'proof_image.name')}.png</div>
//                 </Grid>
//                 <Grid item md={6} xs={10} lg={3}>
//                   <div style={{ marginLeft: "48%", fontSize: "23px", marginBottom: "-19px", flexDirection: 'row' }}>
//                     <EyeFilled onClick={() => viewImage(_.get(customerInfo, _.get(v, 'proof')), _.get(v, 'proof'))} />
//                     <DownCircleOutlined onClick={() => viewImage(_.get(customerInfo, _.get(v, 'proof')), _.get(v, 'proof'), 'down')} style={{ marginLeft: 23 }} />
//                     <DeleteFilled onClick={() => deleteItem(v)} style={{ marginLeft: 23 }} />
//                   </div>
//                 </Grid>
//               </Grid>
//             );
//           } else {
//             // If no document is uploaded yet, display form inputs
//             return (
//               <Grid key={i} container item spacing={1}>
//                 {/* Dropdown for document type */}
//                 <Grid item md={6} xs={10} lg={3}>
//                   <FormInputDropdown
//                     name={`proof_${i}`}  
//                     control={control}
//                     label="Documents"
//                     options={profileSelectDetails}
//                     rules={{ required: CustomerRules.commonValidation("Proof", i + 1) }}
//                     readonly={isNextActive}
//                   />
//                 </Grid>
  
//                 {/* Input for unique document number */}
//                 <FormRow
//                   control={control}
//                   readonly={getValues(`proof_${i}`) ? false : true}  
//                   name={`proof_unique_no_${i}`}  
//                   label="Documents Unique No"
//                   rules={{ required: CustomerRules.commonValidation("Proof Unique No", i + 1) }}
//                 />
  
//                 {/* File upload for proof image */}
//                 <Grid item md={6} xs={10} lg={3}>
//                   <FormRowUpload
//                     name_title={getValues(`proof_${i}`)}
//                     value_detail={formList[i]?.image_Details}
//                     type_dropdown="id_proof"
//                     name={`proof_image_${i}`}  
//                     id={i + 1}
//                     disabled={getValues(`proof_unique_no_${i}`) ? false : true}  
//                     label="Aadhaar"
//                     control={control}
//                   />
//                 </Grid>
  
//                 {/* Button to delete documents */}
//                 <Grid item md={6} xs={10} lg={2}>
//                 {i > 0 ? (
//     <DeleteFilled onClick={() => deleteItem(v)} style={{ marginLeft: 23 }} />
//   ) :
  
//   <div className="family-card-add-button-wrapper">
//   <Icon baseClassName="fas" className="fa-plus-circle" color="primary"  onClick={() => handleAdd('id')} />
// </div>
// }

                
                
//               </Grid>
             
//             </Grid>
//             );
//           }
//         })}
  
        
//       </Grid>
//     );
//   };
  
  
  
  // //dynamic set n numbers of loadview
  // const loadFormView = (list, type) => {
  //   return (
  //     <Grid container item spacing={1}>
  //       {list.map((v, i) => (
  //         <Grid key={i} container item spacing={1}>
  //           <Grid item md={6} xs={10} lg={3}>
  //             <FormInputDropdown
  //               name={type === 'id' ? "proof" : "proof_address"}
  //               control={control}
  //               label="Documents"
  //               options={profileSelectDetails}
  //               rules={{ required: CustomerRules.commonValidation("Proof") }}
  //               readonly={isNextActive}
  //             />
  //           </Grid>
  //           <FormRow
  //             control={control}
  //             readonly={!getValues('proof')}
  //             name={type === 'id' ? "proof_unique_no" : "proof_unique_no_address"}
  //             label="Documents Unique No"
  //             rules={{ required: CustomerRules.commonValidation("Proof Unique No") }}
  //           />
  //           <Grid item md={6} xs={10} lg={3}>
  //             <FormRowUpload
  //               name_title={getValues('proof')}
  //               value_detail={formList[i]?.image_Details}
  //               name={type === 'id' ? "proof_image" : "proof_image_address"}
  //               id='12'
  //               type_dropdown="id_proof"
  //               disabled={!getValues('proof_unique_no')}
  //               label="Aadhaar"
  //               control={control}
  //               readonly={isNextActive}
  //             />
  //           </Grid>
  //           {/* Show delete button for all but the first field when more than one field exists */}
  //           {list.length > 1 && (
  //             <Grid item md={6} xs={10} lg={2}>
  //               {i > 0 && (
  //                 <DeleteFilled onClick={() => deleteItem(v, type)} style={{ marginLeft: 23 }} />
  //               )}
  //             </Grid>
  //           )}
  //         </Grid>
  //       ))}
  //       <Grid item md={6} xs={10} lg={14}>
  //         <div className="family-card-add-button-wrapper">
  //           <Icon baseClassName="fas" className="fa-plus-circle" color="primary" onClick={() => handleAdd(type)} />
  //         </div>
  //       </Grid>
  //     </Grid>
  //   );
  // };
  
  
  
 

const loadFormView1 = (addressProof) => {
 

  return (
    <Grid container item spacing={1}>
      {_.map(_.reverse([...addressProof]), (v, i) => {
        return _.get(v, 'upload') === true ? (
          <Grid key={i} style={{ marginBottom: 34, marginTop: 10 }} container item spacing={1}>
            {/* Display Document Name */}
            <Grid item md={6} xs={10} lg={3}>
              <div>{commanNameChange(_.get(v, 'proof_address'))}</div>
            </Grid>
            {/* Display Unique Number */}
            <Grid item md={6} xs={10} lg={3}>
              <div>{_.get(v, 'proof_unique_no_address')}</div>
            </Grid>
            {/* Display Image Name */}
            <Grid item md={6} xs={10} lg={3}>
              <div>{_.get(v, 'proof_image_address.name')}</div>
            </Grid>
            {/* Icons for Viewing/Deleting */}
            <Grid item md={6} xs={10} lg={3}>
              <div style={{ marginLeft: "48%", fontSize: "23px", marginBottom: "-19px", flexDirection: 'row' }}>
                <EyeFilled onClick={() => viewImage(_.get(customerInfo, _.get(v, 'proof_address')), _.get(v, 'proof_address'))} />
                <DownCircleOutlined onClick={() => viewImage(_.get(customerInfo, _.get(v, 'proof_address')), _.get(v, 'proof_address'), 'down')} style={{ marginLeft: 23 }} />
                <DeleteFilled onClick={() => deleteItem(v, 'address')} style={{ marginLeft: 23 }} />
              </div>
            </Grid>
          </Grid>
        ) : (
          <Grid key={i} container item spacing={1}>
            {/* Document Type Dropdown */}
            <Grid item md={6} xs={10} lg={3}>
              <FormInputDropdown
                name={`proof_address_${i}`}
                control={control}
                label="Documents"
                options={profileSelectDetails1}
                rules={{ required: CustomerRules.commonValidation("Proof", i + 1) }}
              />
            </Grid>

            {/* Document Unique No */}
            <FormRow
              control={control}
              readonly={getValues(`proof_address_${i}`) ? false : true}
              name={`proof_unique_no_address_${i}`}
              label="Documents Unique No"
              rules={{ required: CustomerRules.commonValidation("Proof Unique No", i + 1) }}
            />

            {/* File Upload Field */}
            <Grid item md={6} xs={10} lg={3}>
              <FormRowUpload
                name_title={getValues(`proof_address_${i}`)}
                id={i + 1}
                value_detail={formList[i]?.image_Details}
                type_dropdown="address_proof"
                name={`proof_image_address_${i}`}
                disabled={getValues(`proof_unique_no_address_${i}`) ? false : true}
                label="Aadhaar"
                formType="formView1"
                control={control}
              />
            </Grid>

            
            <Grid item md={6} xs={10} lg={2}>
                  {addressProof.length > 1 ? (  // Ensure delete only appears if there is more than 1 item
                    <DeleteFilled onClick={() => deleteItem(v, 'address')} style={{ marginLeft: 23,marginTop: 15 }} />
                  ) : null}
                </Grid>
          </Grid>
        );
      })}
   <Grid item md={6} xs={10} lg={14} >
          <div className="family-card-add-button-wrapper">
            <Icon baseClassName="fas" className="fa-plus-circle" color="primary" onClick={() => handleAdd('address')} />
          </div>
        </Grid>
      </Grid>
  );
};

  

  return (<form onSubmit={handleSubmit(onSubmit)} >
    <Card>
      <CardHeader
        title="Address / Identity Proofs"
      />

      <Divider />
      <CardContent>
        {
          apiError.error && apiError.show === true && <div>
            <SnackBar open={apiError.show} id={_.get(apiError, 'id')} message={apiError.message} handleClose={() => setApiError(initialErrorState)} severity={apiError.severity} />
          </div>
        }
        <div >
          <div className='proof_list'>
            Id Documents
          </div>
          {/* {loadFormView(formList)} */}
          {loadFormView(formList, 'id')}
        </div>
        <div>
          <div className='proof_list'>
            Address Documents
          </div>
          {loadFormView1(addressProof, 'address')}
        </div>
      </CardContent>
      {/* <FormRowUpload name="pan" id="2" label="Pan" control={control} />
      <FormRowUpload name="dirving_license" id="3" label="Driving License" control={control} />
      <FormRowUpload name="passport" label="Passport" id="4" control={control} />
      <FormRowUpload name="voters_id" label="Voters ID" id="5" control={control} />
      <FormRowUpload name="family_card" label="Family Card" id="6" control={control} />
      <FormRowUpload name="bank_book" label="Bank Book" id="7" control={control} />
      <FormRowUpload name="others" label="Others" id="8" control={control} /> */}
      <Divider />
      <Box sx={{ display: 'flex', justifyContent: 'space-between', px: 2, pb: 2 }}>
        <Button
          variant="contained"
          sx={{ mt: 2, ml: 1 }}
          href="/app/customers"
          style={{ height: '36px' }}
        >
          Cancel
        </Button>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', px: 2, pb: 2 }}> {activeStep !== 0 && (
          <Button onClick={handleBack} sx={{ mt: 2, ml: 1 }}>
            Previous
          </Button>
        )}

          <Button
            variant="contained"
            sx={{ mt: 2, ml: 1 }}
            type={"submit"}
          >
            {activeStep === steps.length - 1 ? 'Finish' : 'Save & Next'}
          </Button>
        </Box>
      </Box>
    </Card>
  </form>
    // </Spin>
  );
};


export default Proofs;
