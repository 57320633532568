
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
       data: {},
};

const globalConfigurationSlice = createSlice({
  name: 'globalConfiguration',
  initialState,
  reducers: {
       setApiData: (state, action) => {
              console.log('asdkalsdsskdjkasadsas', action.payload);
              
              state.data = action.payload; // Save the API response in Redux state
            },
  },
});

export const { setApiData } = globalConfigurationSlice.actions;
export default globalConfigurationSlice.reducer;
