import { useState } from 'react';
// import PropTypes from 'prop-types';
// import moment from 'moment';
// import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
} from '@mui/material';
// import getInitials from '../../utils/getInitials';
// import { Link } from 'react-router-dom';
// import globalConfig from '../../utils/config';

import _ from 'lodash'

// import DeleteIcon from '@mui/icons-material/Delete';
// import EditIcon from '@mui/icons-material/Edit';
// import VisibilityIcon from '@mui/icons-material/Visibility';
// import MoneyIcon from '@mui/icons-material/Money';
// import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import DialogBox from '../shared/DialogBox';
import { services } from '../services/services';
// import AddCircleTwoToneIcon from '@mui/icons-material/AddCircleTwoTone';
// import DetailsTwoToneIcon from '@mui/icons-material/DetailsTwoTone';
// import { Helmet } from 'react-helmet';

const LoansListResults = ({ loans, onChangePage, pagination_details, type_loan = "loan", ...rest }) => {

  console.log('pagination_detailspagination_details', pagination_details);
  // const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [open, setOpen] = useState(false);
  const [customerId, setCustomerId] = useState(0);
  // const [openAlert, setOpenAlert] = useState(false);
  console.log('loansloans', loans);
  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    onChangePage(newPage)
  };
  // const handleDeleteCustomer = (customer_id) => {
  //   setOpen(true);
  //   setCustomerId(customer_id);
  // }
  const onConfirm = () => {
    setCustomerId(0)
    setOpen(false);
    services.putService(`customer/delete/${customerId}`)
      .then(res => {
        console.log(res);
        // setOpenAlert(true);
      })
      .catch(err => err);
  }

  return (
    <Card {...rest}>

      <Box>


        <TableContainer style={{ maxHeight: "550px", overflowX: 'auto' }}>
          <Table stickyHeader >
            <TableHead >
              <TableRow>
                <TableCell>
                  DATE
                </TableCell>
                <TableCell>
                  TO LOAN DISBURSED VIDE NEFT
                </TableCell>
                <TableCell>
                  PRINCIPAL AMOUNT
                </TableCell>
                {/* <TableCell>
                  Email
                </TableCell> */}
                <TableCell>
                  INTERSET
                </TableCell>
                <TableCell>
                  PENALTY
                </TableCell>
                <TableCell>
                  DEBIT (Rs)
                </TableCell>

                {/* <TableCell>
                  Expected Date
                </TableCell> */}
                <TableCell>
                  CREDIT (Rs)
                </TableCell>
                {/* <TableCell>
                  Interest Rate
                </TableCell> */}
                <TableCell>
                  BALANCE (Rs)
                </TableCell>

              </TableRow>
            </TableHead>
            <TableBody>
              {_.size(loans) > 0 ? loans.slice(0, limit).map((loan) => (
                <TableRow
                  hover
                  key={loan.id}
                >

                  <TableCell>
                    {loan.payment_date}
                  </TableCell>
                  <TableCell>
                    {loan.description}
                  </TableCell>
                  <TableCell>
                    {_.get(loan, 'disbursed_amount', 0)}
                  </TableCell>
                  <TableCell>
                    {_.get(loan, 'interest', 0)}
                  </TableCell>
                  <TableCell>
                    {_.get(loan, 'interest', 0)}
                  </TableCell>

                  <TableCell>
                    {_.get(loan, 'payment_type') === "debit" ? loan.amount : 0}
                  </TableCell>
                  <TableCell>
                    {_.get(loan, 'payment_type') === "credit" ? loan.amount : 0}
                  </TableCell>
                  <TableCell>
                    {_.get(loan, 'balance', 0)}
                  </TableCell>
                  {/* <TableCell>
                    <Stack spacing={1} >
                      <Chip label="Details" size="small" component="a" href={`loan/details/${loan.id}?type=${type_loan }`} clickable icon={<VisibilityIcon />} color="secondary" />
                      <Chip label="EMI" size="small" component="a" href={`emi/${loan.id}?type=${type_loan }`} clickable icon={<VisibilityIcon />} color="secondary" />
                    </Stack>
                  </TableCell> */}
                </TableRow>
              )) : <TableRow ><TableCell style={{ alignItems: 'center', padding: '70px', justifyContent: 'center', textAlign: 'center', fontSize: 18, fontWeight: 'bolder', marginBottom: '44%' }} colSpan={9}>No Loans</TableCell></TableRow>}
            </TableBody>
          </Table></TableContainer>
      </Box>
      {_.size(loans) > 10 && <TablePagination
        component="div"
        count={_.get(pagination_details, 'count', 10)}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />}
      <DialogBox open={open} setOpen={setOpen} onConfirm={onConfirm} text="Delete the Customer, Are you sure?" />
    </Card>
  );
};

export default LoansListResults;
